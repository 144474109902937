import {rollupScores} from "./score_rollup";
import {rollupAllScoStatuses} from "./status_rollup";
import {SSLAConfiguration} from "./config";

export type ScoFlatData = {
    status: string;
    normalizedScore: string;
    score: string;
    sessionTime: string;
    totalTime: string;
    sessionWallTime: number;
    totalWallTime: number;
    lastOpenedAt: number;
    lastClosedAt: number;
    data: {[dme:string]:string|number};
};
export type ScosFlatData = {
    status: string;
    score: string;
    sessionTime: string;
    totalTime: string;
    currentSco: string;
    sessionWallTime: number;
    totalWallTime: number;
    lastOpenedAt: number;
    lastClosedAt: number;
    scos: {[scoName:string]:ScoFlatData;}
}


export class ScoVar {
    protected _value:number|string = "";
    protected dirty:boolean = false;
    protected wasSet:boolean = false;

    constructor(v:string|number="") {
        this._value = v;
    }

    get():number|string {
        return this._value;
    }

    set(d:number|string) {
        if (d === undefined || d === null) {
            d = "";
        }
        if (typeof d !== "number") {
            d = String(d);
        }
        if (this._value !== d) {
            this.dirty = true;
        }
        this._value = d;
        this.wasSet = true;
    }

    isDirty():boolean {
        return this.dirty;
    }

    isSet():boolean {
        return this.wasSet;
    }
}


export abstract class ScoData {
    public start:boolean = false;
    public end:boolean = false;
    protected data:{[dme:string]:ScoVar;};
    protected dirty:boolean = false;
    protected wasSet:boolean = false;
    protected totalWallTimeAtStart:number = 0;
    protected currentWallTimeStart:number = 0;
    protected currentWallTimeEnd:number = 0;

    constructor() {
        this.start = false;
        this.end = false;
        this.data = {};
        this.fillDefaults();
        this.populateMaxes();
    }

    fillDefaults() {
    }

    abstract canImportKey(key:string):boolean;
    abstract getNormalizedScore():string;
    abstract getScore():string;
    abstract getSessionTime():string;
    abstract getStatus():string;
    abstract getTotalTime():string;
    abstract populateMaxes():void;

    getMaxIndex(prefix:string):number {
        // Prefix must end in a "." or else this method will not work correctly.
        let i:number,
            prefixLen:number = prefix.length,
            dmeSplit:string[],
            maxIndex = -1,
            dmes:string[] = this.keys(),
            n:number;
        for (i=0; i < dmes.length; i++) {
            if (dmes[i].substring(0, prefixLen) === prefix) {
                dmeSplit = dmes[i].substring(prefixLen).split(".");
                n = Number(dmeSplit[0]);
                if (!isNaN(n)) {
                    maxIndex = Math.max(n, maxIndex);
                }
            }
        }
        return maxIndex;
    }

    exportData():ScoFlatData {
        let flatData:{[dme:string]:string|number;} = {};
        let scoFlat:ScoFlatData = {
            status: this.getStatus(),
            normalizedScore: this.getNormalizedScore(),
            score: this.getScore(),
            sessionTime: this.getSessionTime(),
            totalTime: this.getTotalTime(),
            sessionWallTime: 0,
            totalWallTime: 0,
            lastOpenedAt: this.currentWallTimeStart,
            lastClosedAt: this.currentWallTimeEnd,
            data: flatData
        };

        // If we've ended the session, use the known session time.  Otherwise calculate from current time against
        // the last session start time, to give us as close to a current point as we can.
        // This differs from SCORM logic, where these values are generally only updated at the end of the SCO.
        if (this.currentWallTimeEnd) {
            scoFlat.sessionWallTime = this.currentWallTimeEnd - this.currentWallTimeStart;
        }
        else if (this.currentWallTimeStart) {
            scoFlat.sessionWallTime = new Date().getTime() - this.currentWallTimeStart;
        }
        scoFlat.totalWallTime = this.totalWallTimeAtStart + scoFlat.sessionWallTime;

        let k:string;
        for (k in this.data) {
            if (this.data.hasOwnProperty(k)) {
                flatData[k] = this.data[k].get();
            }
        }
        return scoFlat;
    };

    importData(unflattenData:ScoFlatData) {
        let k:string;
        for (k in unflattenData.data) {
            if (unflattenData.data.hasOwnProperty(k)) {
                if (this.canImportKey(k)) {
                    // Intentionally prevent dirty/isSet flag setting on import.
                    this.data[k] = new ScoVar(unflattenData.data[k]);
                }
            }
        }
        this.populateMaxes();
        this.totalWallTimeAtStart = 0;
        if (unflattenData.totalWallTime) {
            this.totalWallTimeAtStart = unflattenData.totalWallTime;
        }
    }

    get(dme:string):string|number {
        if (!this.data[dme]) {
            return "";
        }
        return this.data[dme].get();
    }

    set(dme:string, value:string) {
        if (!this.data[dme]) {
            this.data[dme] = new ScoVar();
        }
        this.data[dme].set(value);
        this.dirty = this.dirty || this.data[dme].isDirty();
        this.wasSet = true;
    }

    isDirty():boolean {
        return this.dirty;
    }

    isSet():boolean {
        return this.wasSet;
    }

    keys():string[] {
        return Object.keys(this.data);
    }

    startViewing():void {
        this.currentWallTimeStart = new Date().getTime();
        this.currentWallTimeEnd = 0;
    }

    stopViewing():void {
        this.currentWallTimeEnd = new Date().getTime();
    }
}


export abstract class AllScosData {
    public scos:{[scoName:string]:ScoData;} = {};
    public currentScoId:string = null;
    protected sessionTime:string = "";
    protected totalTime:string = "";
    protected currentWallTimeStart:number = 0;
    protected currentWallTimeEnd:number = 0;
    protected totalWallTimeAtStart:number = 0;
    protected status:string = "";
    protected score:string = "";

    abstract addTimes(one:string, two:string): string;
    abstract makeNewScoData():ScoData;

    exportData(config:SSLAConfiguration):ScosFlatData {
        let d:ScosFlatData = {
            status: "",
            score: "",
            sessionTime: "",
            totalTime: "",
            sessionWallTime: 0,
            totalWallTime: 0,
            lastOpenedAt: this.currentWallTimeStart,
            lastClosedAt: this.currentWallTimeEnd,
            currentSco: this.currentScoId,
            scos: {}
        };
        let k:string;

        // If we've ended the session, use the known session time.  Otherwise calculate from current time against
        // the last session start time, to give us as close to a current point as we can.
        // This differs from SCORM logic, where these values are generally only updated at the end of the SCO.
        if (this.currentWallTimeEnd) {
            d.sessionWallTime = this.currentWallTimeEnd - this.currentWallTimeStart;
        }
        else {
            d.sessionWallTime = new Date().getTime() - this.currentWallTimeStart;
        }
        d.totalWallTime = this.totalWallTimeAtStart + d.sessionWallTime;

        for (k in this.scos) {
            if (this.scos.hasOwnProperty(k)) {
                d.scos[k] = this.scos[k].exportData();
                d.sessionTime = this.addTimes(this.scos[k].getSessionTime(), d.sessionTime);
                d.totalTime = this.addTimes(this.scos[k].getTotalTime(), d.totalTime);
            }
        }
        d.status = this.status;
        d.score = this.score;
        return d;
    };

    importData(unflattenData:ScosFlatData) {
        let k:string;
        this.currentScoId = unflattenData.currentSco;
        for (k in unflattenData.scos) {
            if (unflattenData.scos.hasOwnProperty(k)) {
                this.scos[k] = this.makeNewScoData();
                this.scos[k].importData(unflattenData.scos[k]);
            }
        }
        this.totalWallTimeAtStart = 0;
        if (unflattenData.totalWallTime) {
            this.totalWallTimeAtStart = unflattenData.totalWallTime;
        }
        this.score = typeof unflattenData.score != "undefined" ? unflattenData.score : "";
        this.status = typeof unflattenData.status != "undefined" ? unflattenData.status : "";
        this.sessionTime = "";
        this.totalTime = "";
        if (unflattenData.totalTime) {
            this.totalTime = unflattenData.totalTime;
        }
    }

    setRollupScore(score:string):void {
        this.score = score;
    }

    setRollupStatus(status:string):void {
        this.status = status;
    }

    startViewing():void {
        this.currentWallTimeStart = new Date().getTime();
        this.currentWallTimeEnd = 0;
    }

    updateSessionViewing():void {
        this.currentWallTimeEnd = new Date().getTime();
    }
}
