import {AiccScript} from "./aiccscript_parser";
import {property} from "./xml";

declare namespace ParseXMLToObjectNamespace {
    interface ParseXMLToObject {
        http: any;
        ParseXML: any;
    }

    class ParseXML {
        parse(): any;
        parseString(data: string): any;
    }
}
declare const ParseXMLToObject: ParseXMLToObjectNamespace.ParseXMLToObject;


export class Resource {
    identifier: string;
    href: string;
}


export class ScoNavItem {
    children: ScoNavItem[] = [];
    dataFromLms: string = "";
    // TODO One day we should really validate these things aggressively, for people who want "strict" mode.
    // They're based on xsd:ID which is based on xsd:NCName but with colons removed.
    // Translated to normal regex: First character = `[_A-Za-z]` and every other character = `[-\._A-Za-z0-9]`
    // [_A-Za-z][-\._A-Za-z0-9]*
    identifier: string = "";
    identifierRef: string = "";
    isLeaf: boolean = false;
    isSelectable: boolean = true;
    isVisible: boolean = true;
    masteryScore: string = "";
    maxTimeAllowed: string = "";
    parameters: string = "";
    parent: ScoNavItem = null;
    prerequisites: string = "";
    timeLimitAction: string = "";
    title: string = "";
    aiccScriptParser: AiccScript = null;

    availableChildren(): ScoNavItem[] {
        let sn: ScoNavItem[] = [];
        for (let i: number = 0; i < this.children.length; i++) {
            // TODO: IMPORTANT -- Replace isSelectable with correct selection criteria.
            if (this.children[i].isSelectable) {
                sn.push(this.children[i]);
            }
        }
        return sn;
    }

    // Return a list from root to this item of SCOs as represented in the tree.
    getLineage(): ScoNavItem[] {
        let scos: ScoNavItem[] = [this];
        let tmpSco: ScoNavItem = this;
        while (tmpSco.parent) {
            scos.unshift(tmpSco.parent);
            tmpSco = tmpSco.parent;
        }
        return scos;
    }
}


export class ManifestVersionChecker {
    lastError: string = "";
    rawManifest: any;
    schemaVersionMain: string;
    schemaVersionSub: string;

    protected afterLoad(manifestStructure: any): boolean {
        if (!manifestStructure || !manifestStructure.manifest) {
            this.lastError = "Unable to load manifest.";
            return false;
        }
        this.rawManifest = manifestStructure.manifest;
        this.processSchemaVersion(this.rawManifest);
        if (this.schemaVersionMain !== "1.2" && this.schemaVersionMain !== "2004") {
            this.lastError = "Unsupported schemaversion: " + this.schemaVersionMain;
            return false;
        }

        return true;
    }

    loadScormManifestFromDom(manifestDom: any): boolean {
        let px = new ParseXMLToObject.ParseXML();
        let manifestStructure: any = px.parseDocument(manifestDom.documentElement);
        return this.afterLoad(manifestStructure);
    }

    loadScormManifestFromString(str: string): boolean {
        let px = new ParseXMLToObject.ParseXML();
        let manifestStructure: any = px.parseString(str);
        return this.afterLoad(manifestStructure);
    }

    protected processSchemaVersion(obj: any) {
        let schema: string = property(this.rawManifest, "schemaversion", true);
        if (schema == "1.2") {
            this.schemaVersionMain = "1.2";
        }
        else if (schema === "CAM 1.3") {
            this.schemaVersionMain = "2004";
            this.schemaVersionSub = "2";
        }
        else if (schema === "2004 3rd Edition") {
            this.schemaVersionMain = "2004";
            this.schemaVersionSub = "3";
        }
        else if (schema === "2004 4th Edition") {
            this.schemaVersionMain = "2004";
            this.schemaVersionSub = "4";
        }
        else {
            // If there's no metadata block for schemaversion, other fallbacks to detect type.
            if (property(this.rawManifest, "adlcp:scormtype", true)) {
                this.schemaVersionMain = "1.2";
            }
            else if (property(this.rawManifest, "adlcp:scormType", true)) {
                this.schemaVersionMain = "2004";
                this.schemaVersionSub = "3";
            }
        }
    }

}


export abstract class Manifest {
    // Pre-order traversal of all activities.
    public activityOrdered: ScoNavItem[] = [];
    public firstScoId: string;
    public lastError: string;
    public scosByIdentifiers: { [scoId: string]: ScoNavItem; };
    public scosByIdentifierRefs: { [scoId: string]: ScoNavItem; };
    public itemTree: ScoNavItem;
    // Only includes SCOs that can be directly reached by a user.
    public navigableScoPreOrderTraversal: ScoNavItem[];
    public rawManifest: any;
    public resources: Resource[];
    public resourcesByIdentifierRef: { [ref: string]: Resource; };
    public schemaVersionMain: string;
    public schemaVersionSub: string;
    public scoPreOrderTraversal: ScoNavItem[];

    buildResources(resources: any) {
        let i: number, res: any, buildResource: any;
        this.resources = [];
        this.resourcesByIdentifierRef = {};
        for (i = 0; i < resources.kids["resource"].length; i++) {
            res = resources.kids["resource"][i];
            buildResource = {};
            this.resources.push(buildResource);
            if (res.attrs["identifier"]) {
                buildResource.identifier = res.attrs["identifier"];
                this.resourcesByIdentifierRef[buildResource.identifier] = buildResource;
            }
            buildResource.href = res.attrs["href"];
        }
    }

    protected isSco(item: ScoNavItem) {
        return item.identifierRef != "";
    }

    abstract _buildScoTree(itemNode: any): ScoNavItem;

    buildScoTree(itemNode: any) {
        this.scosByIdentifiers = {};
        this.scosByIdentifierRefs = {};
        this.firstScoId = null;
        this.scoPreOrderTraversal = [];
        this.navigableScoPreOrderTraversal = [];
        this.itemTree = this._buildScoTree(itemNode);
        // TODO Is the root node allowed to be chosen/selected in any case ever?
        this.itemTree.isSelectable = false;
    }

    processManifest(): boolean {
        let orgLen: number = 0;
        if (this.schemaVersionMain !== "1.2" && this.schemaVersionMain !== "2004") {
            this.lastError = "Unsupported schemaversion: " + this.schemaVersionMain;
            return false;
        }

        if (this.rawManifest.tag != "manifest") {
            this.lastError = "Invalid imsmanifest.xml, must be started with <manifest> tag.  Instead found: " + this.rawManifest.tag;
            return false;
        }
        if (this.rawManifest.kids["organizations"] && this.rawManifest.kids["organizations"].length > 0) {
            if (this.rawManifest.kids["organizations"][0].kids["organization"]) {
                orgLen = this.rawManifest.kids["organizations"][0].kids["organization"].length;
                if (orgLen > 1) {
                    this.lastError = "imsmanifest.xml parser does not currently support more than 1 <organization>."
                    return false;
                }
                else if (orgLen == 1) {
                    // Build the resources first so we can do selectability tests on the organization items.
                    if (this.rawManifest.kids["resources"]) {
                        this.buildResources(this.rawManifest.kids["resources"][0]);
                    }
                    this.buildScoTree(this.rawManifest.kids["organizations"][0].kids["organization"][0]);
                }
                else {
                    this.lastError = "imsmanifest.xml parser only found 0 <organization>s.";
                    return false;
                }
            }
        }

        this.processTopLevel();
        return true;
    }

    processTopLevel(): void {
    }

    scoLaunchUrl(sco: ScoNavItem): string {
        let s: string = this.resourcesByIdentifierRef[sco.identifierRef].href;
        if (sco.parameters != null && sco.parameters != "") {
            // This algorithm is defined here:
            // While first char of parameters is in"?&"
            //   Clear first char of parameters
            // If first char of parameters is "#"
            //   If the URI contains "#"
            //     Discard parameters
            //   Else
            //     Append parameters to the URI
            //   Done processing URI
            // If the URI contains "?"
            //   Append "&" to the URI
            // Else
            //   Append "?" to the URI
            // Append parameters to the URI
            // Done processing the URI
            let removePrefixes = /^[?&]*(.*)$/g;
            let match = removePrefixes.exec(sco.parameters);
            if (match.length == 0) {
                return s;
            }
            let shorterParams = match[1];
            // This is a deviation from the algorithm.  The algorithm states if there's only a # at the first character.
            // We choose to do it if there's a # anywhere in the string.
            // There's argument that a better solution would be to remove just the fragment and preserve the query strings.
            // TODO Decide which technique described above is more right.
            //if (shorterParams.charAt(0) == "#") {
            if (shorterParams.indexOf("#") !== -1) {
                if (s.indexOf("#") !== -1) {
                    return s;
                }
                else {
                    return s + shorterParams;
                }
            }
            if (s.indexOf("?") !== -1) {
                s += "&";
            }
            else {
                s += "?";
            }
            s += shorterParams;
        }
        return s;
    }

}
