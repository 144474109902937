import {Manifest, ScoNavItem} from "../manifest";
import {ActivityObjective} from "./model";
import {property} from "../xml";


export class ControlMode {
    choice: boolean = true;
    choiceExit: boolean = true;
    flow: boolean = false;
    forwardOnly: boolean = false;
    useCurrentAttemptObjectiveInfo: boolean = true;
    useCurrentAttemptProgressInfo: boolean = true;
}

export class SequenceRuleCondition {
    referencedObjective: string;
    measureThreshold: number = 0;
    operator: string = "";
    condition: string = "always";
}

export class SequenceRule {
    conditions: SequenceRuleCondition[] = [];
    conditionCombination: string = "all";
    action: string;
}

export class RollupCondition {
    operator: string;
    condition: string;
}

export class RollupRule {
    childActivitySet: string = "";
    minimumCount: number = 0.0;
    minimumPercent: number = 0.0;
    conditions: RollupCondition[] = [];
    conditionCombination: string = "all";
    action: string = "satisfied";
}

export class Rollup {
    rollupObjectiveSatisfied: boolean = true
    rollupProgressCompletion: boolean = true;
    objectiveMeasureWeight: number = 1.0;
    rules: RollupRule[] = [];
}

export class ObjectiveMapInfo {
    targetObjectiveId: string;
    readSatisfiedStatus: boolean = true;
    readNormalizedMeasure: boolean = true;
    writeSatisfiedStatus: boolean = false;
    writeNormalizedMeasure: boolean = false;
}

export class Objective {
    objectiveId: string = "";
    satisfiedByMeasure: boolean = false;
    minNormalizedMeasure: number = 1.0;
    mapInfos: ObjectiveMapInfo[] = [];
}

export class RandomizationControls {
    randomizationTiming: string = "never";
    selectCount: number = 0;
    reorderChildren: boolean = false;
    selectionTiming: string = "never";
}

export class DeliveryControls {
    tracked: boolean = true;
    completionSetByContent: boolean = false;
    objectiveSetByContent: boolean = false;
}

export class ConstrainedChoiceConsiderations {
    preventActivation: boolean = false;
    constrainChoice: boolean = false;
}

export class RollupConsiderations {
    requiredForSatisfied: string = "always";
    requiredForNotSatisfied: string = "always";
    requiredForCompleted: string = "always";
    requiredForIncomplete: string = "always";
    measureSatisfactionIfActive: boolean = true;
}

export class Sequence {
    id: string = "";
    ref: string = "";
    controlMode: ControlMode;
    preConditionRules: SequenceRule[] = [];
    postConditionRules: SequenceRule[] = [];
    exitConditionRules: SequenceRule[] = [];
    rollup: Rollup = null;
    attemptLimit: number = -1;
    attemptAbsoluteDurationLimit: number = -1;
    // An activity must always have a primary objective.  If one was not
    // specified, this will provide correct defaults.
    primaryObjective: Objective = new Objective();
    objectives: Objective[] = [];
    randomizationControls: RandomizationControls;
    deliveryControls: DeliveryControls;
    constrainedChoiceConsiderations: ConstrainedChoiceConsiderations;
    rollupConsiderations: RollupConsiderations;
    // TODO: adlseq:objectives ?
}

class CompletionThreshold {
    completedByMeasure: boolean = false;
    minProgressMeasure: number = 1.0;
    progressWeight: number = 1.0;
}

export class ScoNavItem2004 extends ScoNavItem {
    sequence: Sequence = null;
    hideNavItems: { [element: string]: boolean; } = {};
    completionThreshold: CompletionThreshold = new CompletionThreshold();
    rollupObjective: ActivityObjective = new ActivityObjective();
    getKid(i: number): ScoNavItem2004 {
        return this.children[i] as ScoNavItem2004;
    }
}

export class Manifest2004 extends Manifest {
    sequencingCollection: Sequence[] = [];

    _buildScoTree(itemNode: any): ScoNavItem {
        let currentItem: ScoNavItem2004 = new ScoNavItem2004(),
            i: number;
        currentItem.identifier = property(itemNode, "identifier", false, true);
        if (currentItem.identifier) {
            this.scosByIdentifiers[currentItem.identifier] = currentItem;
        }
        currentItem.identifierRef = property(itemNode, "identifierref", false, true);
        if (currentItem.identifierRef) {
            this.scosByIdentifierRefs[currentItem.identifierRef] = currentItem;
        }
        currentItem.title = property(itemNode, "title", false, true);
        currentItem.isVisible = property(itemNode, "isvisible", false, true) === "false" ? false : true;
        currentItem.parameters = property(itemNode, "parameters", false, true);
        currentItem.dataFromLms = property(itemNode, "adlcp:datafromlms", false, true);
        currentItem.masteryScore = property(itemNode, "adlcp:masteryscore", false, true);
        currentItem.timeLimitAction = property(itemNode, "adlcp:timelimitaction", false, true);
        currentItem.maxTimeAllowed = property(itemNode, "adlcp:maxtimeallowed", false, true);
        currentItem.prerequisites = property(itemNode, "adlcp:prerequisites", false, true);
        if (itemNode.kids["adlcp:completionThreshold"] && itemNode.kids["adlcp:completionThreshold"].length > 0) {
            currentItem.completionThreshold = new CompletionThreshold();
            currentItem.completionThreshold.completedByMeasure = property(itemNode.kids["adlcp:completionThreshold"][0], "completedByMeasure", false, true) === "true" ? true : false;
            currentItem.completionThreshold.minProgressMeasure = Number(property(itemNode.kids["adlcp:completionThreshold"][0], "minProgressMeasure", false, true));
            if (!isNaN(currentItem.completionThreshold.minProgressMeasure)) {
                currentItem.completionThreshold.minProgressMeasure = 1.0;
            }
            currentItem.completionThreshold.minProgressMeasure = Number(property(itemNode.kids["adlcp:completionThreshold"][0], "progressWeight", false, true));
            if (!isNaN(currentItem.completionThreshold.progressWeight)) {
                currentItem.completionThreshold.progressWeight = 1.0;
            }
        }

        this.activityOrdered.push(currentItem);
        if (this.isSco(currentItem)) {
            this.scoPreOrderTraversal.push(currentItem);
            // Visibility is not inherited from parent nodes.
            // https://www.imsglobal.org/content/packaging/cpv1p1p3/imscp_bestv1p1p3.html#1518816
            if (currentItem.isVisible) {
                this.navigableScoPreOrderTraversal.push(currentItem);
            }
            if (!this.firstScoId) {
                this.firstScoId = currentItem.identifier;
            }
        }

        if (itemNode.kids["imsss:sequencing"] && itemNode.kids["imsss:sequencing"].length > 0) {
            currentItem.sequence = this.buildSequence(itemNode.kids["imsss:sequencing"][0]);
        }

        if (itemNode.kids["adlnav:presentation"] &&
            itemNode.kids["adlnav:presentation"].length > 0 &&
            itemNode.kids["adlnav:presentation"][0].kids["adlnav:navigationInterface"] &&
            itemNode.kids["adlnav:presentation"][0].kids["adlnav:navigationInterface"].length > 0 &&
            itemNode.kids["adlnav:presentation"][0].kids["adlnav:navigationInterface"][0].kids["adlnav:hideLMSUI"] &&
            itemNode.kids["adlnav:presentation"][0].kids["adlnav:navigationInterface"][0].kids["adlnav:hideLMSUI"].length > 0
        ) {
            //currentItem.sequence = this.buildSequence(itemNode.kids["imsss:sequencing"][0]);
            let navTmp: any = itemNode.kids["adlnav:presentation"][0].kids["adlnav:navigationInterface"][0].kids["adlnav:hideLMSUI"];
            for (i = 0; i < navTmp.length; i++) {
                currentItem.hideNavItems[navTmp[i].text] = true;
            }
        }

        if (itemNode.kids["item"]) {
            for (i = 0; i < itemNode.kids["item"].length; i++) {
                let c: ScoNavItem = this._buildScoTree(itemNode.kids["item"][i]);
                c.parent = currentItem;
                currentItem.children.push(c);
            }
        }
        else {
            currentItem.isLeaf = true;
        }
        return currentItem;
    }

    easySubProp(obj: any, prop: string): string {
        if (obj && obj.length > 0) {
            if (obj[0].attrs[prop]) {
                return obj[0].attrs[prop];
            }
        }
        return null;
    }

    easySubPropBool(obj: any, prop: string): boolean {
        var v: string = this.easySubProp(obj, prop);
        if (v === null) {
            return null;
        }
        return v.toLowerCase() === "true" ? true : false;
    }

    easySubPropFloat(obj: any, prop: string): number {
        var v: string = this.easySubProp(obj, prop);
        if (v === null) {
            return null;
        }
        return parseFloat(v);
    }

    buildObjective(objXml: any): Objective {
        let obj: Objective = new Objective();
        obj.objectiveId = property(objXml, "objectiveID", false, true);
        obj.satisfiedByMeasure = property(objXml, "satisfiedByMeasure", false, true) === "true" ? true : false;
        // TODO: <imsss:minNormalizedMeasure is allowed?
        let minNorm: string = property(objXml, "minNormalizedMeasure", false, true);
        if (minNorm !== null && minNorm !== undefined) {
            obj.minNormalizedMeasure = parseFloat(minNorm);
        }

        if (objXml.kids["imsss:mapInfo"] && objXml.kids["imsss:mapInfo"].length > 0) {
            obj.mapInfos = [];
            for (let i: number = 0; i < objXml.kids["imsss:mapInfo"].length; i++) {
                let mapXml: any = objXml.kids["imsss:mapInfo"][i];
                let mapInfo: ObjectiveMapInfo = new ObjectiveMapInfo();
                mapInfo.targetObjectiveId = property(mapXml, "targetObjectiveID", false, true);
                mapInfo.readSatisfiedStatus = property(mapXml, "readSatisfiedStatus", false, true) === "false" ? false : true;
                mapInfo.writeSatisfiedStatus = property(mapXml, "writeSatisfiedStatus", false, true) === "true" ? true : false;
                mapInfo.readNormalizedMeasure = property(mapXml, "readNormalizedMeasure", false, true) === "false" ? false : true;
                mapInfo.writeNormalizedMeasure = property(mapXml, "writeNormalizedMeasure", false, true) === "true" ? true : false;
                obj.mapInfos.push(mapInfo);
            }
        }
        return obj;
    }

    buildSequenceRule(ruleXml: any): SequenceRule {
        let rule: SequenceRule = new SequenceRule();
        let s: string;
        let tmp3: any;

        if (ruleXml.kids["imsss:ruleAction"] && ruleXml.kids["imsss:ruleAction"].length > 0) {
            s = this.easySubProp(ruleXml.kids["imsss:ruleAction"], "action");
            if (s !== null) {
                rule.action = s;
            }
        }

        if (ruleXml.kids["imsss:ruleConditions"] && ruleXml.kids["imsss:ruleConditions"].length > 0) {
            rule.conditions = [];
            s = this.easySubProp(ruleXml.kids["imsss:ruleConditions"], "conditionCombination");
            if (s !== null) {
                rule.conditionCombination = s;
            }

            if (ruleXml.kids["imsss:ruleConditions"][0].kids["imsss:ruleCondition"] && ruleXml.kids["imsss:ruleConditions"][0].kids["imsss:ruleCondition"].length > 0) {
                for (let j: number = 0; j < ruleXml.kids["imsss:ruleConditions"][0].kids["imsss:ruleCondition"].length; j++) {
                    let rc: SequenceRuleCondition = new SequenceRuleCondition();
                    tmp3 = ruleXml.kids["imsss:ruleConditions"][0].kids["imsss:ruleCondition"][j];
                    s = property(tmp3, "referencedObjective", false, true);
                    if (s !== null) {
                        rc.referencedObjective = s;
                    }
                    s = property(tmp3, "measureThreshold", false, true);
                    if (s !== null) {
                        rc.measureThreshold = parseFloat(s);
                    }
                    s = property(tmp3, "operator", false, true);
                    if (s !== null) {
                        rc.operator = s;
                    }
                    s = property(tmp3, "condition", false, true);
                    if (s !== null) {
                        rc.condition = s;
                    }
                    rule.conditions.push(rc);
                }
            }
        }
        return rule;
    }

    buildSequence(seqXml: any): Sequence {
        let seq: Sequence = new Sequence();
        let tmp: any;
        let tmp2: any;
        let tmp3: any;
        let b: boolean;
        let s: string;
        let n: number;
        let k: number;

        seq.id = property(seqXml, "id", false, true);
        seq.ref = property(seqXml, "IDRef", false, true);

        // Delivery controls.
        seq.deliveryControls = new DeliveryControls();
        tmp = seqXml.kids["imsss:deliveryControls"];
        b = this.easySubPropBool(tmp, "completionSetByContent");
        if (b !== null) {
            seq.deliveryControls.completionSetByContent = b;
        }
        b = this.easySubPropBool(tmp, "objectiveSetByContent");
        if (b !== null) {
            seq.deliveryControls.objectiveSetByContent = b;
        }
        b = this.easySubPropBool(tmp, "tracked");
        if (b !== null) {
            seq.deliveryControls.tracked = b;
        }

        // Control Mode.
        seq.controlMode = new ControlMode();
        tmp = seqXml.kids["imsss:controlMode"];
        b = this.easySubPropBool(tmp, "choice");
        if (b !== null) {
            seq.controlMode.choice = b;
        }
        b = this.easySubPropBool(tmp, "choiceExit");
        if (b !== null) {
            seq.controlMode.choiceExit = b;
        }
        b = this.easySubPropBool(tmp, "flow");
        if (b !== null) {
            seq.controlMode.flow = b;
        }
        b = this.easySubPropBool(tmp, "forwardOnly");
        if (b !== null) {
            seq.controlMode.forwardOnly = b;
        }
        b = this.easySubPropBool(tmp, "useCurrentAttemptObjectiveInfo");
        if (b !== null) {
            seq.controlMode.useCurrentAttemptObjectiveInfo = b;
        }
        b = this.easySubPropBool(tmp, "useCurrentAttemptProgressInfo");
        if (b !== null) {
            seq.controlMode.useCurrentAttemptProgressInfo = b;
        }

        // Limit Conditions
        tmp = seqXml.kids["imsss:limitConditions"];
        n = this.easySubPropFloat(tmp, "attemptLimit");
        if (n !== null) {
            seq.attemptLimit = n;
        }
        n = this.easySubPropFloat(tmp, "attemptAbsoluteDurationLimit");
        if (n !== null) {
            seq.attemptAbsoluteDurationLimit = n;
        }

        // Randomization Controls
        seq.randomizationControls = new RandomizationControls();
        tmp = seqXml.kids["imsss:randomizationControls"];
        s = this.easySubProp(tmp, "randomizationTiming");
        if (s !== null) {
            seq.randomizationControls.randomizationTiming = s;
        }
        s = this.easySubProp(tmp, "selectionTiming");
        if (s !== null) {
            seq.randomizationControls.selectionTiming = s;
        }
        n = this.easySubPropFloat(tmp, "selectCount");
        if (n !== null) {
            seq.randomizationControls.selectCount = n;
        }
        b = this.easySubPropBool(tmp, "reorderChildren");
        if (b !== null) {
            seq.randomizationControls.reorderChildren = b;
        }

        // Constrained Choice Considerations
        seq.constrainedChoiceConsiderations = new ConstrainedChoiceConsiderations();
        tmp = seqXml.kids["adlseq:constrainedChoiceConsideration"];
        b = this.easySubPropBool(tmp, "preventActivation");
        if (b !== null) {
            seq.constrainedChoiceConsiderations.preventActivation = b;
        }
        b = this.easySubPropBool(tmp, "constrainChoice");
        if (b !== null) {
            seq.constrainedChoiceConsiderations.constrainChoice = b;
        }

        // Rollup Considerations
        seq.rollupConsiderations = new RollupConsiderations();
        tmp = seqXml.kids["adlseq:rollupConsideration"];
        b = this.easySubPropBool(tmp, "measureSatisfactionIfActive");
        if (b !== null) {
            seq.rollupConsiderations.measureSatisfactionIfActive = b;
        }
        s = this.easySubProp(tmp, "requiredForSatisfied");
        if (s !== null) {
            seq.rollupConsiderations.requiredForSatisfied = s;
        }
        s = this.easySubProp(tmp, "requiredForNotSatisfied");
        if (s !== null) {
            seq.rollupConsiderations.requiredForNotSatisfied = s;
        }
        s = this.easySubProp(tmp, "requiredForCompleted");
        if (s !== null) {
            seq.rollupConsiderations.requiredForCompleted = s;
        }
        s = this.easySubProp(tmp, "requiredForIncomplete");
        if (s !== null) {
            seq.rollupConsiderations.requiredForIncomplete = s;
        }

        // Rollup Rules
        seq.rollup = new Rollup();
        tmp = seqXml.kids["imsss:rollupRules"];
        b = this.easySubPropBool(tmp, "rollupObjectiveSatisfied");
        if (b !== null) {
            seq.rollup.rollupObjectiveSatisfied = b;
        }
        b = this.easySubPropBool(tmp, "rollupProgressCompletion");
        if (b !== null) {
            seq.rollup.rollupProgressCompletion = b;
        }
        n = this.easySubPropFloat(tmp, "objectiveMeasureWeight");
        if (n !== null) {
            seq.rollup.objectiveMeasureWeight = n;
        }

        if (tmp && tmp.length > 0) {
            if (tmp[0].kids["imsss:rollupRule"]) {
                seq.rollup.rules = [];
                for (let i: number = 0; i < tmp[0].kids["imsss:rollupRule"].length; i++) {
                    let rr: RollupRule = new RollupRule();
                    seq.rollup.rules.push(rr);
                    tmp2 = tmp[0].kids["imsss:rollupRule"][i];
                    rr.childActivitySet = property(tmp2, "childActivitySet");
                    s = property(tmp2, "minimumCount");
                    if (s !== null) {
                        rr.minimumCount = parseFloat(s);
                    }
                    s = property(tmp2, "minimumPercent");
                    if (s !== null) {
                        rr.minimumPercent = parseFloat(s);
                    }

                    if (tmp2.kids["imsss:rollupAction"] && tmp2.kids["imsss:rollupAction"].length > 0) {
                        s = this.easySubProp(tmp2.kids["imsss:rollupAction"], "action");
                        if (s !== null) {
                            rr.action = s;
                        }
                    }

                    if (tmp2.kids["imsss:rollupConditions"] && tmp2.kids["imsss:rollupConditions"].length > 0) {
                        rr.conditions = [];
                        s = this.easySubProp(tmp2.kids["imsss:rollupConditions"], "conditionCombination");
                        if (s !== null) {
                            rr.conditionCombination = s;
                        }

                        if (tmp2.kids["imsss:rollupConditions"][0].kids["imsss:rollupCondition"] && tmp2.kids["imsss:rollupConditions"][0].kids["imsss:rollupCondition"].length > 0) {
                            for (let j: number = 0; j < tmp2.kids["imsss:rollupConditions"][0].kids["imsss:rollupCondition"].length; j++) {
                                let rc: RollupCondition = new RollupCondition();
                                rr.conditions.push(rc);
                                tmp3 = tmp2.kids["imsss:rollupConditions"][0].kids["imsss:rollupCondition"][j];
                                s = property(tmp3, "operator");
                                if (s !== null) {
                                    rc.operator = s;
                                }
                                rc.condition = property(tmp3, "condition");
                                s = property(tmp3, "condition");
                                if (s !== null) {
                                    rc.condition = s;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (seqXml.kids["imsss:sequencingRules"] && seqXml.kids["imsss:sequencingRules"].length > 0) {
            if (seqXml.kids["imsss:sequencingRules"][0].kids["imsss:preConditionRule"] &&
                seqXml.kids["imsss:sequencingRules"][0].kids["imsss:preConditionRule"].length > 0) {
                for (k = 0; k < seqXml.kids["imsss:sequencingRules"][0].kids["imsss:preConditionRule"].length; k++) {
                    seq.preConditionRules.push(this.buildSequenceRule(seqXml.kids["imsss:sequencingRules"][0].kids["imsss:preConditionRule"][k]));
                }
            }
            if (seqXml.kids["imsss:sequencingRules"][0].kids["imsss:postConditionRule"] &&
                seqXml.kids["imsss:sequencingRules"][0].kids["imsss:postConditionRule"].length > 0) {
                for (k = 0; k < seqXml.kids["imsss:sequencingRules"][0].kids["imsss:postConditionRule"].length; k++) {
                    seq.postConditionRules.push(this.buildSequenceRule(seqXml.kids["imsss:sequencingRules"][0].kids["imsss:postConditionRule"][k]));
                }
            }
            if (seqXml.kids["imsss:sequencingRules"][0].kids["imsss:exitConditionRule"] &&
                seqXml.kids["imsss:sequencingRules"][0].kids["imsss:exitConditionRule"].length > 0) {
                for (k = 0; k < seqXml.kids["imsss:sequencingRules"][0].kids["imsss:exitConditionRule"].length; k++) {
                    seq.exitConditionRules.push(this.buildSequenceRule(seqXml.kids["imsss:sequencingRules"][0].kids["imsss:exitConditionRule"][k]));
                }
            }
        }

        if (seqXml.kids["imsss:objectives"] && seqXml.kids["imsss:objectives"].length > 0) {
            seq.primaryObjective = this.buildObjective(seqXml.kids["imsss:objectives"][0].kids["imsss:primaryObjective"][0]);
            if (seqXml.kids["imsss:objectives"][0].kids["imsss:objective"] && seqXml.kids["imsss:objectives"][0].kids["imsss:objective"].length > 0) {
                seq.objectives = [];
                for (let i: number = 0; i < seqXml.kids["imsss:objectives"][0].kids["imsss:objective"].length; i++) {
                    seq.objectives.push(this.buildObjective(seqXml.kids["imsss:objectives"][0].kids["imsss:objective"][i]));
                }
            }
        }

        return seq;
    }

    buildSequences(seqsXml: any): Sequence[] {
        let seqs: Sequence[] = [];
        let i: number;
        console.log("seqsXml", seqsXml);
        if (seqsXml.kids["imsss:sequencing"]) {
            for (i = 0; i < seqsXml.kids["imsss:sequencing"].length; i++) {
                seqs.push(this.buildSequence(seqsXml.kids["imsss:sequencing"][i]));
            }
        }
        return seqs;
    }

    processTopLevel(): void {
        let seqColl: any = this.rawManifest.kids['imsss:sequencingCollection'];
        if (seqColl) {
            for (let i: number = 0; i < seqColl.length; i++) {
                this.sequencingCollection = this.buildSequences(seqColl[i]);
            }
        }
    }

}
