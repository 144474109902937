import {Signal} from "signals";

export class SSLAApiEvents {
    public preInitialize:Signal = new Signal();
    public postInitialize:Signal = new Signal();
    public preFinish:Signal = new Signal();
    public postFinish:Signal = new Signal();
    public preCommit:Signal = new Signal();
    public postCommit:Signal = new Signal();
    public preGetValue:Signal = new Signal();
    public postGetValue:Signal = new Signal();
    public preSetValue:Signal = new Signal();
    public postSetValue:Signal = new Signal();
    public preGetLastError:Signal = new Signal();
    public postGetLastError:Signal = new Signal();
    public preGetErrorString:Signal = new Signal();
    public postGetErrorString:Signal = new Signal();
    public preGetDiagnostic:Signal = new Signal();
    public postGetDiagnostic:Signal = new Signal();
    public scoreChange:Signal = new Signal();
    public statusChange:Signal = new Signal();
    public commitPreSave: Signal = new Signal();
}
