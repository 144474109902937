import {SSLAApiEvents} from "./events";


class Statement {
    public sco: string;
    public fn: string;
    public args: string[];
    public ret: string;
    public error: string;
    public startTime: number;
    public endTime: number;

    constructor(sco:string, fn:string, args?:string[], ret?:string, error?:string) {
        this.sco = sco;
        this.fn = fn;
        this.args = args;
        this.ret = ret;
        this.error = error;
        this.startTime = new Date().getTime();
    }

    end():void {
        this.endTime = new Date().getTime();
    }
}


export class ScormLog {
    protected currentStatement:Statement = null;
    protected statements:Statement[];
    protected currentSco:string;
    protected events:SSLAApiEvents;

    constructor(events:SSLAApiEvents) {
        this.events = events;
        this.statements = [];
        this.registerEvents();
    }

    add(fn:string, args:string[], ret:string, error:string):void {
        this.start(fn, args);
        this.finish(ret, error)
    }

    protected pre(cmd:string, args:string[]):void {
        this.start(cmd, args);
    }

    protected post(cmd:string, args:string[], ret:string, err:string):void {
        this.finish(ret, err);
    }

    registerEvents():void {
        this.events.preInitialize.add(this.pre, this);
        this.events.postInitialize.add(this.post, this);
        this.events.preFinish.add(this.pre, this);
        this.events.postFinish.add(this.post, this);
        this.events.preCommit.add(this.pre, this);
        this.events.postCommit.add(this.post, this);
        this.events.preGetValue.add(this.pre, this);
        this.events.postGetValue.add(this.post, this);
        this.events.preSetValue.add(this.pre, this);
        this.events.postSetValue.add(this.post, this);
        this.events.preGetLastError.add(this.pre, this);
        this.events.postGetLastError.add(this.post, this);
        this.events.preGetErrorString.add(this.pre, this);
        this.events.postGetErrorString.add(this.post, this);
        this.events.preGetDiagnostic.add(this.pre, this);
        this.events.postGetDiagnostic.add(this.post, this);
    }

    setSco(sco:string) {
        this.currentSco = sco;
    }

    start(fn:string, args:string[]) {
        this.currentStatement = new Statement(this.currentSco, fn, args);
        this.statements.push(this.currentStatement);
    }

    finish(ret:string, error:string) {
        this.currentStatement.end();
        this.currentStatement = null;
    }
}
