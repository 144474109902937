import {ScoNavItem, Manifest, ManifestVersionChecker} from "../manifest";
export {UrlVarService} from "../url";
import {ScormPlugin, ScormPluginBase} from "../scorm/plugin";
import {Scorm12Plugin} from "../12/plugin";
import {Scorm2004Plugin} from "../2004/plugin";
import {Scorm2004SimplePlugin} from "../2004simple/plugin";
import {SSLAApiEvents} from "../events";
import {SSLAPlugin} from "../plugin";
import {ScormLog} from "../scormlog";
import * as Path from "../path";

declare var reqwest: any;


export class ScormAllVersionsPlugin extends SSLAPlugin implements ScormPlugin {
    assetCached: boolean = false;
    events: SSLAApiEvents;
    mvc: ManifestVersionChecker;
    scormLog: ScormLog;
    scormPlugin: ScormPluginBase;
    sequencing2004Mode: string = "simple";

    constructor() {
        super();

        this.events = new SSLAApiEvents();
        this.events.statusChange.add(this.updateManifestSelectability, this);
        this.events.statusChange.add(this.updateRollupStatus, this);
        this.events.scoreChange.add(this.updateRollupScore, this);
        this.scormLog = new ScormLog(this.events);
    }

    checkManifestVersion() {
        this.mvc = new ManifestVersionChecker();
        let res:any;
        if (this.config.scormManifestUseRawXml()) {
            res = this.mvc.loadScormManifestFromString(this.config.scormManifestRawXml());
        }
        else {
            res = this.mvc.loadScormManifestFromDom(this.contents);
        }

        if (res === false) {
            return null;
        }

        if (this.mvc.schemaVersionMain == "1.2") {
        }
        else if (this.mvc.schemaVersionMain == "2004") {
        }
        else {
            return null;
        }
    }

    createManifestClass(): Manifest {
        // NOOP
        return null;
    }

    initializeApi(scoNav: ScoNavItem): void {
        this.scormPlugin.initializeApi(scoNav);
    }

    endPreviousSco(): void {
        this.scormPlugin.endPreviousSco();
    }

    finish(): void {
        this.scormPlugin.endPreviousSco();
    }

    initializeScosData(): void {
        this.scormPlugin.initializeScosData();
    }

    initializeSequencing(): void {
        this.scormPlugin.initializeSequencing();
    }

    navigate(type: string): void {
        this.scormPlugin.navigate(type);
    }

    navigateByIdentifier(id: string): void {
        this.scormPlugin.loadScoByIdentifier(id);
    }

    loadSco(scoNav: ScoNavItem, scoIndex: number): void {
        this.scormPlugin.loadSco(scoNav, scoIndex);
    }

    loadScoByIndex(scoIndex: number): void {
        this.scormPlugin.loadScoByIndex(scoIndex);
    }

    loadScoByIdentifier(scoId: string): void {
        this.scormPlugin.loadScoByIdentifier(scoId);
    }

    performLayoutUpdate(): void {
        this.scormPlugin.performLayoutUpdate();
    }

    setupNavigationType(): void {
        this.scormPlugin.setupNavigationType();
    }

    standardLayoutUpdater(): void {
        this.scormPlugin.standardLayoutUpdater();
    }

    updateManifestSelectability(): void {
        this.scormPlugin.updateManifestSelectability();
    }

    updateRollupScore(): void {
        this.scormPlugin.updateRollupScore();
    }

    updateRollupStatus(): void {
        this.scormPlugin.updateRollupStatus();
    }

    // TODO This one is only for bindable behavior, and each plugin will have bound it internally.  Probably shouldn't be part of the interface.
    onceLoaded(): void {
        this.scormPlugin.onceLoaded();
    }

    loadAfterMainAsset() {
        this.checkManifestVersion();
        if (!this.mvc) {
            alert("The manifest (imsmanifest.xml) was not found or is unavailable.  Please check your settings and try again.");
            return;
        }

        if (this.mvc.schemaVersionMain === "1.2") {
            this.scormPlugin = new Scorm12Plugin();
        }
        else if (this.mvc.schemaVersionMain === "2004") {
            if (this.sequencing2004Mode == "simple") {
                this.scormPlugin = new Scorm2004SimplePlugin();
            }
            else {
                this.scormPlugin = new Scorm2004Plugin();
            }
        }
        this.scormPlugin.config = this.config;
        this.scormPlugin.ctd = this.ctd;
        this.scormPlugin.events = this.events;
        this.scormPlugin.mvc = this.mvc;
        this.scormPlugin.start();
    }

    start(): void {
        if (!this.assetCached) {
            this.getMainAsset(this.loadAfterMainAsset.bind(this));
        }
        else {
            this.loadAfterMainAsset();
        }
    }

    getMainAsset(callbackOnDecision: Function) {
        let me: ScormAllVersionsPlugin = this;
        reqwest({
            url: Path.join([me.config.courseDirectory(), me.config.scormManifestFilename()])
            , type: 'xml'
        })
            .then(function (resp: any) {
                me.assetCached = true;
                me.contents = resp;
                me.ctd.callbackAttempted = true;
                callbackOnDecision();
            })
            .always(function (resp: any) {
                me.checked = true;
            });
    }

}

