import {ScoNavItem, Manifest, ManifestVersionChecker} from "../manifest";
import * as Path from "../path";
import * as Sco from "../sco";
import * as Storage from "../storage";
import {TableOfContents} from "../toc";
import {SSLAApiEvents} from "../events";
import {ScormLog} from "../scormlog";
import {SSLAConfiguration} from "../config";
import {AiccScript} from "../aiccscript_parser";
import {openSco} from "../open";
import {DomRender} from "../render";
import {SSLAPlugin} from "../plugin";
import {rollupScores} from "../score_rollup";
import {rollupAllScoStatuses} from "../status_rollup";

export {UrlVarService} from "../url";


export interface ScormPlugin {
    createManifestClass(): Manifest;
    endPreviousSco(): void;
    initializeApi(scoNav: ScoNavItem): void;
    initializeScosData(): void;
    initializeSequencing(): void;
    navigate(type:string): void;

    loadSco(scoNav: ScoNavItem, scoIndex: number): void;
    loadScoByIndex(scoIndex: number): void;
    loadScoByIdentifier(scoId: string): void;
    performLayoutUpdate(): void;
    setupNavigationType(): void;
    standardLayoutUpdater(): void;
    updateManifestSelectability(): void;
    updateRollupScore(): void;
    onceLoaded(): void;
    start(): void
}


export abstract class ScormPluginBase extends SSLAPlugin implements ScormPlugin {
    public events: SSLAApiEvents = null;
    public config: SSLAConfiguration = null;
    public manifest: Manifest;
    public mvc: ManifestVersionChecker;
    public currentScoIndex: number = -1;
    public currentSco: ScoNavItem = null;
    public storage: Storage.StorageInterface;
    public scormLog: ScormLog;
    public scosData: Sco.AllScosData = null;
    public toc: TableOfContents = null;

    abstract createManifestClass(): Manifest;
    abstract endPreviousSco(): void;
    abstract initializeApi(scoNav: ScoNavItem): void;
    abstract initializeScosData(): void;
    abstract initializeSequencing(): void;
    abstract navigate(type:string): void;

    finish(): void {
        this.endPreviousSco();
    }

    loadSco(scoNav: ScoNavItem, scoIndex: number): void {
        if (!this.config.reenterActivity() && scoIndex == this.currentScoIndex) {
            return;
        }
        this.endPreviousSco();
        this.updateManifestSelectability();
        this.initializeApi(scoNav);
        this.currentScoIndex = scoIndex;
        this.currentSco = scoNav;
        this.toc.currentScoId = this.currentSco.identifier;
        this.scosData.currentScoId = this.currentSco.identifier;
        this.scosData.scos[this.currentSco.identifier].startViewing();
        let launchUrl: string = this.manifest.scoLaunchUrl(scoNav);
        openSco(Path.join([this.config.courseDirectory(), launchUrl]), this.config, this.manifest.navigableScoPreOrderTraversal.length <= 1);
        (<HTMLButtonElement>document.getElementById("prev")).disabled = (this.currentScoIndex === 0) ? true : false;
        (<HTMLButtonElement>document.getElementById("next")).disabled = (this.manifest.navigableScoPreOrderTraversal.length <= this.currentScoIndex + 1) ? true : false;
        // Set empty status array for updating the navigation
        var statusArray: string[] = [];
        this.events.statusChange.dispatch(statusArray);
    }

    loadScoByIndex(scoIndex: number): void {
        if (scoIndex < 0 || this.manifest.navigableScoPreOrderTraversal.length <= scoIndex) {
            return;
        }
        let scoNav: ScoNavItem = this.manifest.navigableScoPreOrderTraversal[scoIndex];
        if (!scoNav.isSelectable) {
            return;
        }
        this.loadSco(scoNav, scoIndex);
    }

    loadScoByIdentifier(scoId: string): void {
        let scoNav: ScoNavItem = this.manifest.scosByIdentifiers[scoId],
            scoIndex: number = -1,
            i: number;
        for (i = 0; i < this.manifest.navigableScoPreOrderTraversal.length; i++) {
            if (this.manifest.navigableScoPreOrderTraversal[i].identifier == scoNav.identifier) {
                scoIndex = i;
                break;
            }
        }
        if (scoIndex === -1) {
            alert("SCO not found.")
        }
        this.loadSco(scoNav, scoIndex);
    }

    protected clearManifestAiccScriptParsers(): void {
        let scos: ScoNavItem[] = this.manifest.navigableScoPreOrderTraversal;
        for (let i: number = 0; i < scos.length; i++) {
            let scoNav: ScoNavItem = scos[i];
            scoNav.aiccScriptParser = null;
        }
    }

    navigateByIdentifier(id: string): void {
        this.loadScoByIdentifier(id);
    }

    performLayoutUpdate(): void {
        if (this.config.layoutUpdater() != null) {
            this.config.layoutUpdater()(this);
        }
        else {
            this.standardLayoutUpdater();
        }
    }

    setupNavigationType(): void {
        let navigationType: string = this.config.navigationType();
        let scos: ScoNavItem[] = this.manifest.navigableScoPreOrderTraversal;
        if (navigationType == "") {
            // Do nothing, it's valid and doesn't override the manifest's behavior.
        }
        else {
            // TODO: This should probably be a service/factory so it's less intrusive to add.
            this.clearManifestAiccScriptParsers();
            if (navigationType == "lockstep") {
                for (let i: number = 1; i < scos.length; i++) {
                    let scoNav: ScoNavItem = scos[i];
                    let asp: AiccScript = new AiccScript();
                    asp.parse(scos[i - 1].identifier);
                    scoNav.aiccScriptParser = asp;
                }
            }
            else if (navigationType == "examaftercontent") {
                let scoNav: ScoNavItem = scos[scos.length - 1];
                let idents: string[] = [];
                for (let i: number = 0; i < scos.length - 1; i++) {
                    idents.push(scos[i].identifier);
                }
                let asp: AiccScript = new AiccScript();
                asp.parse(idents.join("&"));
                scoNav.aiccScriptParser = asp;
            }
            else if (navigationType == "onlyonce") {
                for (let i: number = 0; i < scos.length; i++) {
                    let scoNav: ScoNavItem = scos[i];
                    let asp: AiccScript = new AiccScript();
                    asp.parse(scoNav.identifier + "=n");
                    scoNav.aiccScriptParser = asp;
                }
            }
            else {
                alert("Unrecognized nagivationType: " + navigationType);
            }
        }
    }

    // TODO This probably belongs in an external class.
    standardLayoutUpdater(): void {
        let body: HTMLElement = document.body;
        DomRender.removeClass(body, "hide-menu");
        DomRender.removeClass(body, "hide-toc");
        if (this.manifest.navigableScoPreOrderTraversal.length == 1) {
            if (this.config.singleScoView() == "HIDE_ALL" || this.config.singleScoView() == "HIDE_NAV_BUTTONS") {
                DomRender.addClass(body, "hide-menu");
            }
            if (this.config.singleScoView() == "HIDE_ALL" || this.config.singleScoView() == "HIDE_TREE") {
                DomRender.addClass(body, "hide-toc");
            }
        }
        else {
            if (this.config.multiScoView() == "HIDE_ALL" || this.config.multiScoView() == "HIDE_NAV_BUTTONS") {
                DomRender.addClass(body, "hide-menu");
            }
            if (this.config.multiScoView() == "HIDE_ALL" || this.config.multiScoView() == "HIDE_TREE") {
                DomRender.addClass(body, "hide-toc");
            }
        }
    }

    updateManifestSelectability(): void {
        for (let i: number = 0; i < this.manifest.navigableScoPreOrderTraversal.length; i++) {
            let scoNav: ScoNavItem = this.manifest.navigableScoPreOrderTraversal[i];
            if (scoNav.aiccScriptParser != null) {
                scoNav.isSelectable = scoNav.aiccScriptParser.evaluate(null, this.scosData);
            }
            // TODO Move this to a sequencing class.
            else if (this.manifest.resourcesByIdentifierRef[scoNav.identifierRef]) {
                scoNav.isSelectable = true;
            }
            this.toc.updateSelectability(scoNav);
        }
    }

    updateRollupScore(): void {
        const rollupScore: string = rollupScores(this.scosData, this.config, this.manifest);
        this.scosData.setRollupScore(rollupScore);
    }

    updateRollupStatus(): void {
        const rollupStatus: string = rollupAllScoStatuses(this.scosData, this.config, this.manifest);
        this.scosData.setRollupStatus(rollupStatus);
    }

    onceLoaded(): void {
        let i: string;
        this.scosData.startViewing();
        for (i in this.scosData.scos) {
            var statusArray: string[] = [this.scosData.scos[i].getStatus()];
            this.toc.updateActivityStatus(i, statusArray);
        }
        this.updateManifestSelectability();
        this.initializeSequencing();
        (<any>window).DATA = this.scosData;
    }

    start(): void {
        let p = document.getElementById("frame-here");
        let manifestParsed: boolean;
        let tocHtml: string;
        p.innerHTML = '<iframe id="jca_course_player" name="jca_course_player" frameborder="0" width="100%" height="100%" src="blank.htm" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>';

        this.manifest = this.createManifestClass();
        if (!this.manifest) {
            alert("The manifest (imsmanifest.xml) was not found or is unavailable.  Please check your settings and try again.");
            return;
        }
        manifestParsed = this.manifest.processManifest();
        if (!manifestParsed) {
            alert("The manifest did not parse correctly.  Reason: " + this.manifest.lastError);
            return;
        }
        this.setupNavigationType();
        this.initializeScosData();

        this.toc = new TableOfContents(this.config, this.events);
        tocHtml = this.toc.render(this.manifest.resourcesByIdentifierRef, this.manifest.itemTree);
        document.getElementById("toc").innerHTML = tocHtml;

        // We have all the pieces we need to control the layout of the screen.
        this.performLayoutUpdate();

        if (this.config.storageAdapter()) {
            this.storage = new (this.config.storageAdapter())(this.config, this.scosData);
        }
        else {
            this.storage = new Storage.AllInOneStorage(this.config, this.scosData);
        }
        this.storage.load({callback: this.onceLoaded.bind(this)});
    }
}
