import {Scorm2004DataModel} from "./dm2004";
import * as Sco from "../sco";
import ScoVar = Sco.ScoVar;
import {SSLAApiEvents} from "../events";
import {SSLAConfiguration} from "../config";
import {ActivityObjective} from "./model";
import * as mathier from "../mathier";

const API_FALSE = "false";
const API_TRUE = "true";

interface TimeStampMap {
    [key: string]: number;
}


export class Sco2004Data extends Sco.ScoData {
    interactionCount: number = 0;
    objectiveCount: number = 0;
    interactionObjectiveCount: { [interactionIndex: string]: number; } = {};
    correctResponsesCount: { [interactionIndex: string]: number; } = {};

    fillDefaults() {
        this.data["cmi.completion_status"] = new ScoVar("not attempted");
        this.data["cmi.success_status"] = new ScoVar("unknown");
        this.data["cmi.suspend_data"] = new ScoVar("");
        this.data["cmi.location"] = new ScoVar("");
        this.data["cmi.session_time"] = new ScoVar("PT0S");
        this.data["cmi.total_time"] = new ScoVar("PT0S");
        // This is to initialize the data model element for the first attempt only, after the first attempt it will no longer be ab-initio
        this.data["cmi.entry"] = new ScoVar("ab-initio");
        this.data["cmi.score.scaled"] = new ScoVar("");
        this.data["cmi.score.raw"] = new ScoVar("");
        this.data["cmi.score.min"] = new ScoVar("");
        this.data["cmi.score.max"] = new ScoVar("");
        this.data["cmi.learner_preference.audio_level"] = new ScoVar("0");
        this.data["cmi.learner_preference.language"] = new ScoVar("");
        this.data["cmi.learner_preference.delivery_speed"] = new ScoVar("0");
        this.data["cmi.learner_preference.audio_captioning"] = new ScoVar("0");

        this.data["cmi.learner_name"] = new ScoVar("");
        this.data["cmi.learner_id"] = new ScoVar("");
        this.data["cmi.launch_data"] = new ScoVar("");
        this.data["cmi.max_time_allowed"] = new ScoVar("");
        this.data["cmi.time_limit_action"] = new ScoVar("");
        this.data["cmi.credit"] = new ScoVar("credit");
        this.data["cmi.mode"] = new ScoVar("normal");
    }

    canImportKey(key:string): boolean {
        if (key == "cmi.session_time") {
            return false;
        }
        return true;
    }

    getNormalizedScore(): string {
        // TODO We probably should offer configuration/generalization for this.
        let rawS: string = <string>this.data["cmi.score.raw"].get();
        if (rawS === "") {
            let scaledS: string = <string>this.data["cmi.score.scaled"].get();
            if (scaledS !== "") {
                return "" + (Number(scaledS) * 100.);
            }
            return "";
        }

        let minS:string = <string>this.data["cmi.score.min"].get();
        let maxS:string = <string>this.data["cmi.score.max"].get();

        let min:number = 0;
        let max:number = 100;
        let raw, scaled:number;

        if (rawS === "") {
            return "";
        }
        raw = Number(rawS);

        if (minS != "") {
            min = Number(minS);
        }
        if (maxS != "") {
            max = Number(maxS);
        }
        return "" + mathier.round((raw / (max - min)) * 100., 3);
    }

    getScore(): string {
        // TODO: Control logic in config?
        let score: string = <string>this.data["cmi.score.raw"].get();
        if (score === "") {
            score = <string>this.data["cmi.score.scaled"].get();
        }
        return score;
    }

    getSessionTime(): string {
        return <string>this.data["cmi.session_time"].get();
    }

    getStatus(): string {
        // TODO: Control logic in config?
        let success: string = <string>this.data["cmi.success_status"].get();
        let complete: string = <string>this.data["cmi.completion_status"].get();
        if (complete === "incomplete") {
            return complete;
        }
        if (success === "passed" || success === "failed") {
            return success;
        }
        return complete;
    }

    getTotalTime(): string {
        return <string>this.data["cmi.total_time"].get();
    }

    populateMaxes(): void {
        let i: number,
            dme: string;
        this.interactionCount = this.getMaxIndex("cmi.interactions.") + 1;
        this.updateInteractionCountDme();
        this.objectiveCount = this.getMaxIndex("cmi.objectives.") + 1;
        this.updateObjectiveCountDme();
        for (i = 0; i < this.interactionCount; i++) {
            dme = "cmi.interactions." + i + ".objectives.";
            this.interactionObjectiveCount[i] = this.getMaxIndex(dme) + 1;
            this.updateInteractionObjectiveCountDme(i);
        }
        for (i = 0; i < this.interactionCount; i++) {
            dme = "cmi.interactions." + i + ".correct_responses.";
            this.correctResponsesCount[i] = this.getMaxIndex(dme) + 1;
            this.updateCorrectResponsesCountDme(i);
        }
    }

    set(dme: string, value: string) {
        super.set(dme, value);
        let dmeParts: string[] = dme.split(".");
        if (dmeParts[1] === "interactions") {
            this.interactionCount = Math.max(Number(dmeParts[2]) + 1, this.interactionCount);
            this.updateInteractionCountDme();
            if (dmeParts[3] === "objectives") {
                this.interactionObjectiveCount[dmeParts[2]] = Math.max(Number(dmeParts[4]) + 1, this.interactionObjectiveCount[dmeParts[2]]);
                this.updateInteractionObjectiveCountDme(Number(dmeParts[2]));
            }
            else {
                // Make sure that the counts are always updated if a new item has been added.
                this.updateInteractionObjectiveCountDme(Number(dmeParts[2]));
            }

            if (dmeParts[3] === "correct_responses") {
                this.correctResponsesCount[dmeParts[2]] = Math.max(Number(dmeParts[4]) + 1, this.correctResponsesCount[dmeParts[2]]);
                this.updateCorrectResponsesCountDme(Number(dmeParts[2]));
            }
            else {
                // Make sure that the counts are always updated if a new item has been added.
                this.updateCorrectResponsesCountDme(Number(dmeParts[2]));
            }
        }
        else if (dmeParts[1] === "objectives") {
            this.objectiveCount = Math.max(Number(dmeParts[2]) + 1, this.objectiveCount);
            this.updateObjectiveCountDme();
        }
    }

    protected updateInteractionCountDme() {
        this.data["cmi.interactions._count"] = new ScoVar(this.interactionCount);
    }

    protected updateObjectiveCountDme() {
        this.data["cmi.objectives._count"] = new ScoVar(this.objectiveCount);
    }

    protected updateInteractionObjectiveCountDme(index: number) {
        if (!this.interactionObjectiveCount[index]) {
            this.interactionObjectiveCount[index] = 0;
        }
        this.data["cmi.interactions." + index + ".objectives._count"] = new ScoVar(this.interactionObjectiveCount[index]);
    }

    protected updateCorrectResponsesCountDme(index: number) {
        if (!this.correctResponsesCount[index]) {
            this.correctResponsesCount[index] = 0;
        }
        this.data["cmi.interactions." + index + ".correct_responses._count"] = new ScoVar(this.correctResponsesCount[index]);
    }
}


export class AllScos2004Data extends Sco.AllScosData {
    public globalObjectives: { [objName: string]: ActivityObjective; } = {};

    addTimes(one:string, two:string): string {
        return Scorm2004TimeManager.addTime(one, two);
    }

    makeNewScoData(): Sco.ScoData {
        return new Sco2004Data();
    }
}


class Scorm2004TimeManager {
    public static dm:Scorm2004DataModel = new Scorm2004DataModel();

    public static addTime(one:string, two:string):string {
        if (!one) {
            return "";
        }
        if (!two) {
            //Skip aaallll of this if the total time is currently 0
            return one;
        }
        // If the SCO was somehow initialized with invalid data, zero it out.
        if (!this.dm.validateTimespan(two)) {
            two = "PT0S";
        }
        var splitSession = one.split("P");
        var splitTotal = two.split("P");
        var hasTimeBlock = one.indexOf("T") > -1 || two.indexOf("T") > -1;
        var sessionTime = this.parseTimestamp(splitSession[1]);
        var totalTime = this.parseTimestamp(splitTotal[1]);
        var timeString: string = "P";
        var calendarValues: string[] = ["yearVal", "monthVal", "dayVal"];
        var timeValues: string[] = ["hourVal", "minVal"];

        for (var i: number = 0; i < calendarValues.length; i++) {
            timeString += this.addValuesToTimeString(calendarValues[i], sessionTime, totalTime);
        }

        if (hasTimeBlock) {
            timeString += "T";

            for (var i: number = 0; i < timeValues.length; i++) {
                timeString += this.addValuesToTimeString(timeValues[i], sessionTime, totalTime);
            }

            if (sessionTime["secVal"] || totalTime["secVal"]) {
                if (sessionTime["secVal"] && totalTime["secVal"]) {
                    //Only adding the seconds with roll over because that's all we actually have to do
                    var seconds: number = sessionTime["secVal"] + totalTime["secVal"];
                    var decimalVal = seconds % 1;

                    if (decimalVal !== 0 && decimalVal > 0.99) {
                        seconds = (seconds - decimalVal) + (Math.round(decimalVal * 100) / 100);
                    }
                    totalTime["secVal"] = seconds;
                }

                if (!totalTime["secVal"]) {
                    totalTime["secVal"] = sessionTime["secVal"];
                }

                timeString += totalTime["secVal"].toString() + "S";
            }
        }

        if (timeString == "P" || timeString == "PT") {
            // Change too short invalid formats to smallest possible valid time.
            timeString = "PT0S";
        }
        return timeString;
    }

    private static addValuesToTimeString(arrayValue: string, sessionTime: TimeStampMap, totalTime: TimeStampMap) {
        var timeString = "";

        if (sessionTime[arrayValue] || totalTime[arrayValue]) {

            if (sessionTime[arrayValue] && totalTime[arrayValue]) {
                totalTime[arrayValue] = sessionTime[arrayValue] + totalTime[arrayValue];
            }

            if (!totalTime[arrayValue]) {
                totalTime[arrayValue] = sessionTime[arrayValue];
            }

            //The first letter of the timeString is needed to fit the specification (i.e. "year" is "Y"
            var firstChar = arrayValue.charAt(0).toUpperCase();

            timeString = totalTime[arrayValue].toString() + firstChar;
        }

        return timeString;
    }

    private static parseTimestamp(str: string) {
        var timeIndex = str.indexOf("T");
        var timeValues: TimeStampMap = {};
        var date = str;
        var timeBlock = "";

        if (timeIndex > -1 ) {
            date = str.substring(0, timeIndex);
            timeBlock = str.substring(timeIndex + 1, str.length);
        }

        var yearMatch = date.match(/(\d+Y|Y)/);
        var monthMatch = date.match(/(\d+M|M)/);
        var dayMatch = date.match(/(\d+D|D)/);

        var hourMatch = timeBlock.match(/(\d+H|H)/);
        var minuteMatch = timeBlock.match(/(\d+M|M)/);
        var secondMatch = timeBlock.match(/(\d+([.]\d{1,2})?S|S)/);

        if (yearMatch != null) {
            timeValues["yearVal"] = Number(yearMatch[0].replace("Y", ""));
        }

        if (monthMatch != null) {
            timeValues["monthVal"] = Number(monthMatch[0].replace("M", ""));
        }

        if (dayMatch != null) {
            timeValues["dayVal"] = Number(dayMatch[0].replace("D", ""));
        }

        if (hourMatch != null) {
            timeValues["hourVal"] = Number(hourMatch[0].replace("H", ""));
        }

        if (minuteMatch != null) {
            timeValues["minVal"] = Number(minuteMatch[0].replace("M", ""));
        }

        if (secondMatch != null) {
            timeValues["secVal"] = Number(secondMatch[0].replace("S", ""));
        }

        return timeValues;
    }
}

export class Scorm2004Api {
    protected config: SSLAConfiguration;
    protected dataModel: Scorm2004DataModel;
    public sco: Sco2004Data;
    public scoName: string;
    protected error: string = "0";
    protected events: SSLAApiEvents = null;
    protected saveCallbacks: any[] = [];

    constructor(scoName: string, scoData: Sco2004Data, events: SSLAApiEvents, config: SSLAConfiguration) {
        this.scoName = scoName;
        this.sco = scoData;
        this.dataModel = new Scorm2004DataModel();
        this.events = events;
        this.config = config;

        // Initialize data model properties.
        this.sco.start = false;
        this.sco.end = false;
    }

    private storeTime(session_time: string) {
        if (!session_time) {
            return;
        }
        let timeString:string = Scorm2004TimeManager.addTime(session_time, <string>this.sco.get("cmi.total_time"));
        this.sco.set("cmi.total_time", timeString);
    }

    public addSaveCallback(callbackFn: any, scope: any) {
        this.saveCallbacks.push([callbackFn, scope]);
    }

    protected callSave(eventType: string, becauseFinish: boolean) {
        var i: number;
        for (i = 0; i < this.saveCallbacks.length; i++) {
            this.saveCallbacks[i][0].call(this.saveCallbacks[i][1], "", {
                eventType: eventType,
                scoName: this.scoName,
                sco: this.sco,
                forceSync: becauseFinish
            });
        }
    }

    protected initialize(empty: string): string {
        if (empty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        // Don't call initialize after finish.
        if (this.sco.end) {
            this.error = "104";
            return API_FALSE;
        }
        // Don't call initialize twice.
        if (this.sco.start) {
            this.error = "103";
            return API_FALSE;
        }

        this.sco.start = true;
        this.error = "0";

        if (this.config.startIncomplete() && this.sco.get("cmi.completion_status") == "not attempted") {
            this.sco.set("cmi.completion_status", "incomplete");
        }

        // TODO: Support config.setInitializeToLMS.
        // TODO: Offer immediate save?
        return API_TRUE;
    }

    protected finish(requiredEmpty: string): string {
        if (requiredEmpty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        if (!this.sco.start) {
            this.error = "112";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "113";
            return API_FALSE;
        }

        if (<string>this.sco.get("cmi.completion_status") == "not attempted") {
            if (this.config.statusAllowCompletionOnUnsetFinish()) {
                this.sco.set("cmi.completion_status", "completed");
                this.events.statusChange.dispatch(["completed", this.sco.get("cmi.success_status")]);
            }
        }

        // TODO Support cmi.scaled_passing_score / <imsss:minNormalizedMeasure>

        let sessionTime: string = <string>this.sco.get("cmi.session_time");
        this.storeTime(sessionTime);

        // Update entry status based on current exit.
        if (this.sco.get("cmi.exit") === "suspend") {
            this.sco.set("cmi.entry", "resume");
        }
        else {
            this.sco.set("cmi.entry", "");
        }

        // TODO: Support config.setFinishToLMS.
        this.sco.end = true;
        this.error = "0";
        this.callSave("finish", true);
        return API_TRUE;
    }

    protected getValue(dme: string): string | number {
        if (!this.sco.start) {
            this.error = "122";
            return "";
        }
        if (this.sco.end) {
            this.error = "123";
            return "";
        }
        if (dme === undefined || dme === null) {
            this.error = "301";
            return API_FALSE;
        }
        dme = String(dme);

        // Ensure the element is in the SCORM data model.
        let dm = this.dataModel.matchElement(dme);
        if (!dm) {
            this.error = "401";
            return "";
        }
        // Ensure the element is not write-only.
        if (dm.d === "W") {
            this.error = "405";
            return "";
        }

        this.error = "0";
        if (dm.t === "Fixed") {
            return dm.v;
        }
        return this.sco.get(dme);
    }

    protected setValue(dme: string, value: string): string {
        let actuallySet: boolean = true;

        if (!this.sco.start) {
            this.error = "132";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "133";
            return API_FALSE;
        }
        if (dme === undefined || dme === null) {
            this.error = "351";
            return API_FALSE;
        }
        dme = String(dme);
        if (value === undefined || value === null) {
            value = "";
        }
        value = String(value);

        // Ensure the element is in the SCORM data model.
        let dm = this.dataModel.matchElement(dme);
        if (!dm) {
            this.error = "401";
            return API_FALSE;
        }
        // Ensure the element is not read-only.
        if (dm.d === "R") {
            this.error = "404";
            return API_FALSE;
        }

        let validResult: boolean;
        // Coerce into a string.
        let v: string = "" + value;
        if (dm.t === "CMIDecimal") {
            validResult = this.dataModel.validateDecimal(v, dm.min, dm.max);
        }
        else if (dm.t === "CMIFeedback") {
            validResult = this.dataModel.validateFeedback(v);
        }
        else if (dm.t === "CMIIdentifier") {
            validResult = this.dataModel.validateIdentifier(v, this.config.looseIdentifiers());
        }
        else if (dm.t === "CMIInteger") {
            validResult = this.dataModel.validateIntegerUnsigned(v, dm.min, dm.max);
        }
        else if (dm.t === "CMISInteger") {
            validResult = this.dataModel.validateIntegerSigned(v, dm.min, dm.max);
        }
        else if (dm.t === "OneOf") {
            validResult = this.dataModel.validateOneOf(dm.c, v);
        }
        else if (dm.t === "CMIResult") {
            validResult = this.dataModel.validateResult(v);
        }
        else if (dm.t === "CMIString") {
            validResult = this.dataModel.validateString(v, dm.l);
        }
        else if (dm.t === "CMITime2004") {
            validResult = this.dataModel.validateTime(v);
        }
        else if (dm.t === "CMITimespan") {
            validResult = this.dataModel.validateTimespan(v);
        }
        else {
            this.error = "351";
            return API_FALSE;
        }
        if (!validResult) {
            // TODO: Distinguish between 406 and 407.
            this.error = "406";
            return API_FALSE;
        }

        // Check the array types to make sure they don't overflow bounds.
        let dmeParts: string[] = dme.split(".");
        if (dmeParts[1] === "interactions") {
            if (Number(dmeParts[2]) > this.sco.interactionCount) {
                this.error = "408";
                return API_FALSE;
            }
            if (dmeParts[3] === "objectives") {
                if (Number(dmeParts[4]) > this.sco.interactionObjectiveCount[dmeParts[2]]) {
                    this.error = "408";
                    return API_FALSE;
                }
            }
            if (dmeParts[3] === "correct_responses") {
                if (Number(dmeParts[4]) > this.sco.correctResponsesCount[dmeParts[2]]) {
                    this.error = "408";
                    return API_FALSE;
                }
            }
        }
        if (dmeParts[1] === "objectives") {
            if (Number(dmeParts[2]) > this.sco.objectiveCount) {
                this.error = "408";
                return API_FALSE;
            }
        }

        // Custom score handling logic.
        if (dme == "cmi.score.raw") {
            let rawScore:string = <string>this.sco.get("cmi.score.raw");
            let rawCompletionStatus:string = <string>this.sco.get("cmi.completion_status");
            let rawSuccessStatus:string = <string>this.sco.get("cmi.success_status");

            if (
                rawScore !== "" &&
                (
                    (rawCompletionStatus == "completed" && !this.config.scoreAllowChangeAfterCompleted()) ||
                    (rawSuccessStatus == "passed" && !this.config.scoreAllowChangeAfterPassed()) ||
                    (rawSuccessStatus == "failed" && !this.config.scoreAllowChangeAfterFailed())
                )
            ) {
                // Don't set the score if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
            else if (!this.config.scoreAllowReduce()) {
                if (rawScore !== "" && Number(rawScore) > Number(value)) {
                    // Don't set the score if it would be reduced.
                    actuallySet = false;
                }
            }
        }
        else if (dme == "cmi.score.scaled") {
            let rawScore:string = <string>this.sco.get("cmi.score.scaled");
            let rawCompletionStatus:string = <string>this.sco.get("cmi.completion_status");
            let rawSuccessStatus:string = <string>this.sco.get("cmi.success_status");

            if (
                rawScore !== "" &&
                (
                    (rawCompletionStatus == "completed" && !this.config.scoreAllowChangeAfterCompleted()) ||
                    (rawSuccessStatus == "passed" && !this.config.scoreAllowChangeAfterPassed()) ||
                    (rawSuccessStatus == "failed" && !this.config.scoreAllowChangeAfterFailed())
                )
            ) {
                // Don't set the score if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
            else if (!this.config.scoreAllowReduce()) {
                if (rawScore !== "" && Number(rawScore) > Number(value)) {
                    // Don't set the score if it would be reduced.
                    actuallySet = false;
                }
            }
        }

        if (dme == "cmi.success_status") {
            let rawStatus:string = <string>this.sco.get("cmi.success_status");
            if (
                (rawStatus == "passed" && !this.config.statusAllowChangeAfterPassed()) ||
                (rawStatus == "failed" && !this.config.statusAllowChangeAfterFailed())
            ) {
                // Don't set the status if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
        }
        else if (dme == "cmi.completion_status") {
            let rawStatus:string = <string>this.sco.get("cmi.completion_status");
            if (
                (rawStatus == "completed" && !this.config.statusAllowChangeAfterCompleted())
            ) {
                // Don't set the status if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
        }

        // TODO: All the rest of the set validation.

        this.error = "0";
        if (actuallySet) {
            this.sco.set(dme, v);

            if (dme === "cmi.completion_status") {
                let rawStatus:string = <string>this.sco.get("cmi.success_status");
                this.events.statusChange.dispatch([v, rawStatus]);
            }
            if ( dme === "cmi.success_status") {
                let rawStatus:string = <string>this.sco.get("cmi.completion_status");
                this.events.statusChange.dispatch([v, rawStatus]);
            }
            if (dme == "cmi.core.raw" || dme == "cmi.core.min" || dme == "cmi.core.max" || dme == "cmi.core.scaled") {
                this.events.scoreChange.dispatch();
            }

            if (this.config.saveDataOnSetValue()) {
                this.callSave("setValue", false);
            }
        }
        return API_TRUE;
    }

    protected commit(requiredEmpty: string): string {
        if (requiredEmpty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        if (!this.sco.start) {
            this.error = "142";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "143";
            return API_FALSE;
        }

        this.error = "0";
        if (this.config.saveDataOnCommit()) {
            this.callSave("commit", false);
        }
        return API_TRUE;
    }

    protected getLastError() {
        return this.error;
    }

    protected getErrorString(errorCode: string): string {
        let errors: { [error: string]: string; } = {
            "0": "No error",
            "101": "General Exception",
            "102": "General Initialization Failure",
            "103": "Already Initialized",
            "104": "Content Instance Terminated",
            "111": "General Termination Failure",
            "112": "Termination Before Initialization",
            "113": "Termination After Termination",
            "122": "Retrieve Data Before Initialization",
            "123": "Retrieve Data After Termination",
            "132": "Store Data Before Initialization",
            "133": "Store Data After Termination",
            "142": "Commit Before Initialization",
            "143": "Commit After Termination",
            "201": "General Argument Error",
            "301": "General Get Failure",
            "351": "General Set Failure",
            "391": "General Commit Failure",
            "401": "Undefined Data Model Element",
            "402": "Unimplemented Data Model Element",
            "403": "Data Model Element Value Not Initialized",
            "404": "Data Model Element Is Read Only",
            "405": "Data Model Element Is Write Only",
            "406": "Data Model Element Type Mismatch",
            "407": "Data Model Element Value Out Of Range",
            "408": "Data Model Dependency Not Established"
        };
        if (errors[errorCode]) {
            return errors[errorCode];
        }
        return "";
    }

    protected getDiagnostic(errorCode: string): string {
        return this.error;
    }

    public Initialize(empty: string): string {
        let ret: string;
        this.events.preInitialize.dispatch("Initialize", [empty]);
        ret = this.initialize(empty);
        this.events.postInitialize.dispatch("Initialize", [empty], ret, this.error);
        return ret;
    }

    public Terminate(empty: string): string {
        let ret: string;
        this.events.preFinish.dispatch("Terminate", [empty]);
        ret = this.finish(empty);
        this.events.postFinish.dispatch("Terminate", [empty], ret, this.error);
        return ret;
    }

    public GetValue(dme: string): string | number {
        let ret: string | number;
        this.events.preGetValue.dispatch("GetValue", [dme]);
        ret = this.getValue(dme);
        this.events.postGetValue.dispatch("GetValue", [dme], ret, this.error);
        return ret;
    }

    public SetValue(dme: string, value: string): string {
        let ret: string;
        this.events.preSetValue.dispatch("SetValue", [dme, value]);
        ret = this.setValue(dme, value);
        this.events.postSetValue.dispatch("SetValue", [dme, value], ret, this.error);
        return ret;
    }

    public Commit(requiredEmpty: string): string {
        let ret: string;
        this.events.preCommit.dispatch("Commit", [requiredEmpty]);
        ret = this.commit(requiredEmpty);
        this.events.postCommit.dispatch("Commit", [requiredEmpty], ret, this.error);
        return ret;
    }

    public GetLastError(): string {
        let ret: string;
        this.events.preGetLastError.dispatch("GetLastError", []);
        ret = this.getLastError();
        this.events.postGetLastError.dispatch("GetLastError", [], ret, this.error);
        return ret;
    }

    public GetErrorString(errorCode: string): string {
        let ret: string;
        this.events.preGetErrorString.dispatch("GetErrorString", [errorCode]);
        ret = this.getErrorString(errorCode);
        this.events.postGetLastError.dispatch("GetErrorString", [errorCode], ret, this.error);
        return ret;
    }

    public GetDiagnostic(errorCode: string): string {
        let ret: string;
        this.events.preGetDiagnostic.dispatch("GetDiagnostic", [errorCode]);
        ret = this.getDiagnostic(errorCode);
        this.events.postGetDiagnostic.dispatch("GetDiagnostic", [errorCode], ret, this.error);
        return ret;
    }
}
