import * as Scorm12Api from "./api12";
import {ScoNavItem, Manifest, ManifestVersionChecker} from "../manifest";
export {UrlVarService} from "../url";
import {ScormPluginBase} from "../scorm/plugin";
import {Manifest12} from "./manifest12";
import {NavActions, Request, RequestCategory, SequencingProcess12} from "./sn";
import * as Path from "../path";

declare var reqwest: any;


export class Scorm12Plugin extends ScormPluginBase {
    public API: Scorm12Api.Scorm12Api = null;
    public sequencing: SequencingProcess12 = null;

    createManifestClass(): Manifest {
        let man: Manifest;
        if (this.mvc.lastError != "") {
            return null;
        }

        if (this.mvc.schemaVersionMain == "1.2") {
            man = new Manifest12();
        }
        else {
            return null;
        }
        man.schemaVersionMain = this.mvc.schemaVersionMain;
        man.schemaVersionSub = this.mvc.schemaVersionSub;
        man.rawManifest = this.mvc.rawManifest;
        return man;
    }

    initializeApi(scoNav: ScoNavItem): void {
        if (this.manifest.schemaVersionMain === "1.2") {
            let i = this.scosData.scos[scoNav.identifier];
            i.set("cmi.core.student_id", this.config.studentId());
            i.set("cmi.core.student_name", this.config.studentName());
            i.set("cmi.launch_data", scoNav.dataFromLms);
            i.set("cmi.student_data.mastery_score", scoNav.masteryScore);
            i.set("cmi.student_data.max_time_allowed", scoNav.maxTimeAllowed);
            i.set("cmi.student_data.time_limit_action", scoNav.timeLimitAction);
            i.set("cmi.core.credit", this.config.getCredit());
            i.set("cmi.core.mode", this.config.getMode());

            this.API = new Scorm12Api.Scorm12Api(scoNav.identifier, (<Scorm12Api.Sco12Data>this.scosData.scos[scoNav.identifier]), this.events, this.config);
            this.API.addSaveCallback(this.storage.save, this.storage);
            (<any>window).API = this.API;
        }
        else {
            alert("Manifest schema version unrecognized: " + this.manifest.schemaVersionMain);
        }
    }

    endPreviousSco(): void {
        if (this.currentSco) {
            this.scosData.scos[this.currentSco.identifier].stopViewing();
            this.API.LMSFinish("");
        }
    }

    initializeScosData(): void {
        let i: number;
        // Now that we know the version, build the data structure that uses it.
        this.scosData = new Scorm12Api.AllScos12Data();
        for (i = 0; i < this.manifest.scoPreOrderTraversal.length; i++) {
            this.scosData.scos[this.manifest.scoPreOrderTraversal[i].identifier] = new Scorm12Api.Sco12Data();
        }
    }

    initializeSequencing(): void {
        this.sequencing = new SequencingProcess12(this.manifest as Manifest12, this.scosData);

        // When attempting to return to last SCO on launch,
        // verify that the current SCO ID still exists in the version of the course that's trying to be loaded.
        // If it doesn't, we must fall through to a normal default launch.
        if (this.config.returnToLastActivityOnLaunch() && this.scosData.currentScoId && this.manifest.scosByIdentifiers[this.scosData.currentScoId]) {
            this.loadScoByIdentifier(this.scosData.currentScoId);
        }
        else if (this.config.autoLaunchFirstSco()) {
            this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Start)));
        }
    }

    getMainAsset(callbackOnDecision: Function) {
        let me: Scorm12Plugin = this;
        reqwest({
            url: Path.join([this.config.courseDirectory(), this.config.scormManifestFilename()])
            , type: 'xml'
        })
            .then(function (resp: any) {
                me.contents = resp;
                me.ctd.callbackAttempted = true;
                callbackOnDecision();
            })
            .always(function (resp: any) {
                me.checked = true;
            });
    }

    navigate(type: string): void {
        if (type === "next") {
            this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Continue)));
        }
        else if (type === "previous") {
            this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Previous)));
        }
        else if (type === "repeat") {
            this.loadScoByIndex(this.currentScoIndex);
        }
    }
}
