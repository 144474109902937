import {ScormBaseDataModel} from "../dm";

export class Scorm12DataModel extends ScormBaseDataModel {
    // Element definition: {d: read/write, t: data type, v: hardcoded value}
    protected statusChoices:any = ["passed", "completed", "failed", "incomplete", "browsed"];
    protected objStatusChoices:any = ["not attempted", "passed", "completed", "failed", "incomplete", "browsed"];
    protected validElements:any = {
        "cmi.comments_from_lms": {d: "R", t: "CMIString", l: 4096},
        "cmi.comments": {d: "RW", t: "CMIString", l: 4096},
        "cmi.core._children": {d: "R", t: "Fixed", v: "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time"},
        "cmi.core.credit": {d: "R", t: "OneOf", c: ["credit", "no-credit"]},
        "cmi.core.entry": {d: "R", t: "OneOf", c: ["", "ab-initio", "resume"]},
        "cmi.core.exit": {d: "W", t: "OneOf", c: ["", "time-out", "logout", "suspend"]},
        "cmi.core.lesson_location": {d: "RW", t: "CMIString", l: 255},
        "cmi.core.lesson_mode": {d: "R", t: "OneOf", c: ["browse", "normal", "review"]},
        "cmi.core.lesson_status": {d: "RW", t: "OneOf", c: this.statusChoices},
        "cmi.core.score._children": {d: "R", t: "Fixed", v: "raw,min,max"},
        "cmi.core.score.max": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.core.score.min": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.core.score.raw": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.core.session_time": {d: "W", t: "CMITimespan"},
        "cmi.core.student_id": {d: "R", t: "CMIString", l: 255},
        "cmi.core.student_name": {d: "R", t: "CMIString", l: 255},
        "cmi.core.total_time": {d: "R", t: "CMITimespan"},
        "cmi.launch_data": {d: "R", t: "CMIString", l: 4096},
        "cmi.student_data._children": {d: "R", t: "Fixed", v: "mastery_score,max_time_allowed,time_limit_action"},
        "cmi.student_data.mastery_score": {d: "R", t: "Score"},
        "cmi.student_data.max_time_allowed": {d: "R", t: "CMITimespan"},
        "cmi.student_data.time_limit_action": {d: "R", t: "OneOf", c: ["exit,message", "exit,no message", "continue,message", "continue,no message"]},
        "cmi.student_preference._children": {d: "R", t: "Fixed", v: "audio,language,speed,text"},
        "cmi.student_preference.audio": {d: "RW", t: "CMISInteger", min: -1, max: 100},
        "cmi.student_preference.language": {d: "RW", t: "CMIString", l: 255},
        "cmi.student_preference.speed": {d: "RW", t: "CMISInteger", min: -100, max: 100},
        "cmi.student_preference.text": {d: "RW", t: "CMISInteger", min: -1, max: 1},
        "cmi.suspend_data": {d: "RW", t: "CMIString", l: 4096},
        "cmi.interactions._children": {d: "R", t: "Fixed", v: "id,objectives,time,type,correct_responses,weighting,student_response,result,latency"},
        "cmi.interactions._count": {d: "R", t: "CMIInteger"},
        "cmi.objectives._children": {d: "R", t: "Fixed", v: "id,score,status"},
        "cmi.objectives._count": {d: "R", t: "CMIInteger"}
    };
    protected validRegexElements:any = {
        "cmi\\.interactions.\\d+\\.id": {d: "W", t: "CMIIdentifier"},
        "cmi\\.interactions.\\d+\\.time": {d: "W", t: "CMITime"},
        "cmi\\.interactions.\\d+\\.type": {d: "W", t: "OneOf", c: ["true-false", "choice", "fill-in", "matching", "performance", "sequencing", "likert", "numeric"]},
        "cmi\\.interactions.\\d+\\.weighting": {d: "W", t: "CMIDecimal"},
        "cmi\\.interactions.\\d+\\.student_response": {d: "W", t: "CMIFeedback"},
        "cmi\\.interactions.\\d+\\.result": {d: "W", t: "CMIResult"},
        "cmi\\.interactions.\\d+\\.latency": {d: "W", t: "CMITimespan"},
        "cmi\\.interactions.\\d+\\.objectives\\._count": {d: "R", t: "CMIInteger"},
        "cmi\\.interactions.\\d+\\.objectives\\._children": {d: "R", t: "Fixed", v: "id"},
        "cmi\\.interactions.\\d+\\.objectives.\\d+\\.id": {d: "W", t: "CMIIdentifier"},
        "cmi\\.interactions.\\d+\\.correct_responses\\._count": {d: "R", t: "CMIInteger"},
        "cmi\\.interactions.\\d+\\.correct_responses\\._children": {d: "R", t: "Fixed", v: "pattern"},
        "cmi\\.interactions.\\d+\\.correct_responses.\\d+\\.pattern": {d: "W", t: "CMIFeedback"},
        "cmi\\.objectives.\\d+\\.id": {d: "RW", t: "CMIIdentifier"},
        "cmi\\.objectives.\\d+\\.score\\._children": {d: "R", t: "Fixed", v: "raw,min,max"},
        "cmi\\.objectives.\\d+\\.score\\.raw": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.score\\.max": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.score\\.min": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.status": {d: "RW", t: "OneOf", c: this.objStatusChoices}
    };

    validateIdentifier(value:string, useLooseValidation:boolean=false):boolean {
        if (useLooseValidation) {
            let res = value.match(/^.+$/);
            if (res && res.length) {
                return true;
            }
        }
        else {
            let res = value.match(/^[0-9a-zA-Z_-]{1,255}$/);
            if (res && res.length) {
                return true;
            }
        }
        return false;
    }

    validateResult(value:string):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).
        let res = value.match(/^correct$|^wrong$|^unanticipated$|^neutral$|^([0-9]{0,3})?(\.[0-9]{1,2})?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }

    validateTime(value:string):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).
        let res = value.match(/^([0-2]{1}[0-9]{1}):([0-5]{1}[0-9]{1}):([0-5]{1}[0-9]{1})(\.[0-9]{1,2})?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }

    validateTimespan(value:string):boolean {
        let res = value.match(/^([0-9]{2,4}):([0-9]{2}):([0-9]{2})(\.[0-9]{1,2})?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }
}
