import {SSLAConfiguration} from "./config";

// We do this to handle frame access across lots of browsers.  There's got to be a more standards-compliant way.
declare var jca_course_player:any;
let openConfig:SSLAConfiguration;
let isSingleActivity:boolean;
let openUrl:string;

function openPage(location:string, windowName:string, settings:string) {
    var popUp:any;
    popUp = window.open(location, windowName, settings);
    if (popUp == null || typeof(popUp) == 'undefined') {
        //alert('Please disable your pop-up blocker and click the link again.');
    }
    else {
        popUp.focus();

        // Watch for when the popup closes.
        var pollTimer = window.setInterval(function() {
            let behavior:string;
            let behaviorCustomFn:any;
            if (popUp.closed !== false) { // !== is required for compatibility with Opera
                window.clearInterval(pollTimer);
                //someFunctionToCallWhenPopUpCloses();

                // Single SCO or multi SCO?
                if (isSingleActivity) {
                    // Single SCO
                    behavior = openConfig.closePopupSingleScoBehavior();
                    behaviorCustomFn = openConfig.closePopupSingleScoCustomFunction();
                }
                else {
                    // Multi SCO
                    behavior = openConfig.closePopupMultiScoBehavior();
                    behaviorCustomFn = openConfig.closePopupMultiScoCustomFunction();
                }

                if (behavior == "") {
                    // Do nothing.
                }
                else if (behavior == "exit") {
                    // TODO Something less abusive of global variables.
                    (<any>window).ssla.ssla.exit();
                }
                else if (behavior == "message") {
                    alert("TODO message();");
                }
                else if (behavior == "custom") {
                    if (!behaviorCustomFn) {
                        console.log("No exit custom behavior function defined in config.");
                    }
                    else {
                        behaviorCustomFn();
                    }
                }
                else {
                    console.log("Unrecognized exit behavior:", behavior);
                }

            }
        }, 200);

    }
    return popUp;
};

function tryPopup(path:string, popupSettings:string) {
    return openPage(path, "sco_win", popupSettings);
}

function makePopup() {
    return tryPopup(openUrl, openConfig.popupWindowParams());
}

export function popupManually() {
    updateUserText();
    return makePopup();
}

function updateCenterContent(s:string) {
    var handle = setInterval(function() {
        try {
            // TODO: Improve the frame handling on a global element name.
            jca_course_player.document.getElementById("content").innerHTML = s;
            clearInterval(handle);
        }
        catch(e) { }
    }, 10);
}

function updateUserText() {
    updateCenterContent(openConfig.popupMainContentMessageAfterOpen());
}

export function openSco(url:string, config:SSLAConfiguration, isSingle:boolean) {
    let win:any;
    openConfig = config;
    isSingleActivity = isSingle;
    openUrl = url;
    if (openConfig.openContentIn() == "popup") {
        win = makePopup();
        if (!win) {
            updateCenterContent(openConfig.popupMainContentMessageFailed());
        }
        else {
            updateUserText();
        }
    }
    else {
        // TODO: Improve the frame handling on a global element name.
        jca_course_player.document.location.replace(openUrl);
    }
}
