import {Manifest2004, ScoNavItem2004} from "../2004/manifest2004";
import {AllScos2004Data} from "../2004/api2004";

export const NavActions = {
    Start: "start",
    Continue: "continue",
    Previous: "previous",
    Choice: "choice"
};

export enum RequestCategory {
    Navigation = 1,
    Sequencing,
    Termination,
    Delivery
}

export class Request {
    // cat: Request category: Navigation, Sequencing, Termination, etc.
    // act: Specific action: Start (sequencing request), Exit (termination request), etc.
    constructor(public cat: RequestCategory, public act: string,
                public target: string = null, public exception: string = null) {
    }
}

export class SequencingProcess2004Simple {
    protected manifest: Manifest2004;
    protected scosData: AllScos2004Data;
    protected activeScoId: string = null;

    constructor(manifest: Manifest2004, scosData: AllScos2004Data) {
        this.manifest = manifest;
        this.scosData = scosData;
    }

    do(navigationRequest: Request): string {
        let nextSco: string = this._do(navigationRequest);
        if (nextSco) {
            this.activeScoId = nextSco;
        }
        return nextSco;
    }

    protected _do(navigationRequest: Request): string {
        if (navigationRequest.cat != RequestCategory.Navigation) {
            return "";
        }

        let scoIdx: number = this.getNavigableScoIndex(this.activeScoId);
        if (navigationRequest.act == NavActions.Start) {
            if (scoIdx != -1) {
                return "";
            }
            return this.manifest.navigableScoPreOrderTraversal[0].identifier;
        }
        else if (navigationRequest.act == NavActions.Continue) {
            if (this.manifest.navigableScoPreOrderTraversal.length > scoIdx + 1) {
                return this.manifest.navigableScoPreOrderTraversal[scoIdx + 1].identifier;
            }
        }
        else if (navigationRequest.act == NavActions.Previous) {
            if (scoIdx <= 0) {
                return "";
            }
            return this.manifest.navigableScoPreOrderTraversal[scoIdx - 1].identifier;
        }
        else if (navigationRequest.act == NavActions.Choice) {
            scoIdx = this.getNavigableScoIndex(navigationRequest.target);
            if (scoIdx == -1) {
                return "";
            }
            return this.manifest.navigableScoPreOrderTraversal[scoIdx].identifier;
        }
        return "";
    }

    protected getNavigableScoIndex(identifier: string): number {
        for (let i: number = 0; i < this.manifest.navigableScoPreOrderTraversal.length; i++) {
            if (this.manifest.navigableScoPreOrderTraversal[i].identifier == identifier) {
                return i;
            }
        }
        return -1;
    }

}
