import {SSLAConfiguration} from "./config";
import {Manifest} from "./manifest";
import {AllScosData} from "./sco";
import * as mathier from "./mathier";


type ScoWeighting = {[key: string]: number};


export function rollupScores(allScos:AllScosData, config:SSLAConfiguration, manifest:Manifest):string {
    if (config.scoreRollupCustomFunction()) {
        return config.scoreRollupCustomFunction()(allScos, config);
    }

    if (config.scoreRollupAction() == "last") {
        let scoWeights: ScoWeighting = {};
        scoWeights[manifest.navigableScoPreOrderTraversal[manifest.navigableScoPreOrderTraversal.length - 1].identifier] = 1.0;
        return rollupScoreByScoWeightedSelection(allScos, config, manifest, scoWeights);
    }

    if (config.scoreRollupAction() == "first") {
        let scoWeights: ScoWeighting = {};
        scoWeights[manifest.navigableScoPreOrderTraversal[0].identifier] = 1.0;
        return rollupScoreByScoWeightedSelection(allScos, config, manifest, scoWeights);
    }

    if (config.scoreRollupAction() == "all_equal" || config.scoreRollupAction() == "") {
        return rollupScoreBasic(allScos, config, manifest);
    }

    alert("Unknown score rollup type: " + config.scoreRollupAction());
    return "";
}

function rollupScoreBasic(allScos:AllScosData, config:SSLAConfiguration, manifest:Manifest):string {
    let rollupScore: number = 0;
    let tempScore: string = "";
    let contributingScos: number = 0;
    let k:string;
    for (k in allScos.scos) {
        if (allScos.scos.hasOwnProperty(k)) {
            tempScore = allScos.scos[k].getScore();
            if (tempScore !== "") {
                rollupScore += Number(tempScore);
                contributingScos += 1;
            }
            else if (config.scoreRollupIncludeScosWithNoScore()) {
                contributingScos += 1;
            }
        }
    }

    if (contributingScos == 0) {
        return "";
    }
    return "" + mathier.round(rollupScore / contributingScos, 3);
}

function rollupScoreByScoWeightedSelection(allScos:AllScosData, config:SSLAConfiguration, manifest:Manifest,
                                           includedScos:ScoWeighting) {
    let rollupScore: number = 0;
    let tempScore: string = "";
    let contributingScos: number = 0;
    let k:string;
    for (k in allScos.scos) {
        if (allScos.scos.hasOwnProperty(k)) {
            if (includedScos.hasOwnProperty(k)) {
                tempScore = allScos.scos[k].getScore();
                if (tempScore !== "") {
                    rollupScore += Number(tempScore) * includedScos[k];
                    contributingScos += 1;
                }
            }
        }
    }

    if (contributingScos == 0) {
        return "";
    }
    return "" + mathier.round(rollupScore / contributingScos, 3);
}