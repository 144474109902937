export function property(obj: any, propertyToFind: string, goDeep: boolean = false,
                         ignoreCase: boolean = false): string {
    let i: number, v: string;

    // Check attributes first.
    for (let prop in obj.attrs) {
        if ((!ignoreCase && prop === propertyToFind) ||
            (ignoreCase && prop.toLowerCase() === propertyToFind.toLowerCase())) {
            return obj.attrs[prop];
        }
    }

    // Check direct descendants next.
    for (let kid in obj.kids) {
        if ((!ignoreCase && kid === propertyToFind) ||
            (ignoreCase && kid.toLowerCase() === propertyToFind.toLowerCase())) {
            if (obj.kids[kid].length > 0) {
                v = "";
                for (i = 0; i < obj.kids[kid].length; i++) {
                    v += obj.kids[kid][i].text;
                }
                return v;
            }
        }
    }

    // Check deeper descendants last.
    if (goDeep) {
        for (let kid in obj.kids) {
            for (i = 0; i < obj.kids[kid].length; i++) {
                v = property(obj.kids[kid][i], propertyToFind, goDeep, ignoreCase);
                if (v !== null && v !== "") {
                    return v;
                }
            }
        }
    }
    return "";
}
