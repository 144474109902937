export function join(parts:string[], sep:string="/") {
    let i:number = 0;
    let j:string = "";
    var separator = sep || '/';
    var replace   = new RegExp(separator+'{1,}', 'g');
    // Don't use .join because empty parts should be ignored.
    for (i=0; i < parts.length; i++) {
        if (parts[i] === "") {
            continue;
        }
        if (j !== "") {
            j += sep;
        }
        j += parts[i];
    }
    return j.replace(replace, separator);
}
