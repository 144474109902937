import {SSLAConfiguration} from "./config";
import * as Sco from "./sco";


declare let reqwest: any;


export interface StorageInterface {
    load(options: any): void;
    save(data: any, options: any): void;
}


export class AllInOneStorage implements StorageInterface {
    public config: SSLAConfiguration;
    public cachedData: any = null;
    public allScos: Sco.AllScosData;

    constructor(config: SSLAConfiguration, allScos: Sco.AllScosData) {
        this.config = config;
        this.allScos = allScos;
    }

    load(options: any) {
        let me = this;
        if (!this.cachedData) {
            reqwest({
                url: this.config.getDataUrl(),
                method: this.config.getDataAjaxMethod(),
                headers: this.config.getDataHeaders(),
                type: "json",
                async: true,
                crossOrigin: this.config.storageAllowCORS(),
                withCredentials: this.config.storageUseWithCredentials(),
                data: {
                    // TODO: Stop using global functions here.
                    "activityId": this.config.courseId(),
                    "studentId": this.config.studentId(),
                    "timestamp": new Date().getTime()
                },
                success: function(resp: any) {
                    if (typeof (resp) == "undefined" || !resp ||
                        "responseText" in resp ||
                        "responseXML" in resp) {
                        resp = {};
                    }
                    me.cachedData = resp;
                    me.allScos.importData(me.cachedData);
                    options.callback(true);
                },
                error: function(request: any, status: any, error: any) {
                    console.log("error in get ajax" + request + "," + status + "," + error);
                    options.callback(false);
                }
            });
        }
        else {
            // The data is already loaded, use the cache.
            me.allScos.importData(me.cachedData);
            options.callback(true);
        }
    }

    save(data: any, options: any) {
        let async: boolean = true;
        if (!options) {
            options = {};
        }
        // TODO: Restore this.config.forceSynchronousAjaxOnEnd
        if (options.forceSync) {
            async = false;
        }
        if (!options.eventType) {
            options.eventType = "save";
        }
        if (!options.scoName) {
            options.scoName = "";
        }

        reqwest({
            url: this.config.setDataUrl(),
            method: this.config.setDataAjaxMethod(),
            headers: this.config.setDataHeaders(),
            async: async,
            crossOrigin: this.config.storageAllowCORS(),
            withCredentials: this.config.storageUseWithCredentials(),
            data: {
                "action": options.eventType,
                "currentSco": options.scoName,
                "data": JSON.stringify(this.allScos.exportData(this.config)),
                "activityId": this.config.courseId(),
                "studentId": this.config.studentId(),
                "timestamp": new Date().getTime()
            },
            success: function(msg: any) {
            },
            error: function(msg: any) {
                console.log("There was an error saving your data: " + msg);
            }
        });
    }
}
