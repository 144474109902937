import {Manifest2004, ScoNavItem2004, SequenceRule, Objective, RollupRule, RollupCondition} from "./manifest2004";
import {AllScos2004Data, Sco2004Data} from "./api2004";
import {SSLAApiEvents} from "../events";
import {ActivityObjective} from "./model";

export enum RequestCategory {
    Navigation = 1,
    Sequencing,
    Termination,
    Delivery
};

enum Direction {
    None = 1,
    Forward,
    Backward
};

export var NavActions = {
    Start: "start",
    Continue: "continue",
    Exit: "exit"
};

var SeqActions = {
    Start: "start",
    Continue: "continue",
    Retry: "retry",
    Exit: "exit"
};

var TermActions = {
    Exit: "exit",
    Abandon: "abandon",
    ExitAll: "exitAll",
    ExitParent: "exitParent"
};

var RuleActions = {
    // Pre
    Skip: "skip",
    Disabled: "disabled",
    HiddenFromChoice: "hiddenFromChoice",
    StopForwardTraversal: "stopForwardTraversal",

    // Post
    ExitParent: "exitParent",
    ExitAll: "exitAll",
    Retry: "retry",
    RetryAll: "retryAll",
    Continue: "continue",
    Previous: "previous",

    // Exit
    Exit: "exit"
};

var RollupActions = {
    Satisfied: "satisfied",
    NotSatisfied: "notSatisfied",
    Completed: "completed",
    Incomplete: "incomplete"
};

export class Request {
    // cat: Request category: Navigation, Sequencing, Termination, etc.
    // act: Specific action: Start (sequencing request), Exit (termination request), etc.
    constructor(public cat: RequestCategory, public act: string,
        public target: string = null, public exception: string = null) {
    }
}

class RequestFlow {
    constructor(
        public status: string,
        public navReq: Request,
        public seqReq: Request,
        public termReq: Request,
        public deliveryReq: Request = null,
        public target: string = null,
        public endSequencingSession: boolean = false,
        public exception: string = null) {
    }
}

class TraversalReturn {
    constructor(
        public activity: ScoNavItem2004,
        public direction: Direction,
        public endSequencingSession: boolean = false,
        public exception: string = null
    ) {
    }
}

class FlowReturn {
    constructor(
        public activity: ScoNavItem2004,
        public deliverable: boolean,
        public endSequencingSession: boolean = false,
        public exception: string = null
    ) {
    }
}

export class DebugTree {
    root: DbgMethod
}

export type DbgEntry = DbgMethod | Dbg;

export class DbgMethod {
    public when: Date = new Date();
    public children: DbgEntry[] = [];
    public key: string = "";
    public desc: string = "";
    public arguments: string[] = [];
    public returns: string[] = [];
    constructor(key: string, desc: string) {
        this.key = key;
        this.desc = desc;
    }
    addArg(arg: string) {
        if (arg === null) {
            this.arguments.push("null");
        }
        this.arguments.push(arg);
    }
    addRet(ret: string) {
        if (ret === null) {
            this.returns.push("null");
        }
        this.returns.push(ret);
    }
}

class Dbg {
    when: Date = new Date();
    constructor(public key: string, public msg: string) {
    }
}

export class SequencingProcess2004 {
    protected manifest: Manifest2004;
    protected scosData: AllScos2004Data;
    protected events: SSLAApiEvents;
    protected currentActivityId: string = null;
    protected currentActivityIsActive: boolean = false;

    constructor(manifest: Manifest2004, scosData: AllScos2004Data, events: SSLAApiEvents) {
        this.manifest = manifest;
        this.scosData = scosData;
        this.events = events;
        this.events.commitPreSave.add(this.updateObjectives, this);
    }

    protected getActivity(actId: string): ScoNavItem2004 {
        return this.manifest.scosByIdentifiers[actId] as ScoNavItem2004;
    }

    updateObjectives(): void {
        // In the current SCO, any local objectives that have been updated
        // should be propagated to global objectives.
        // TODO: Is it possible that this could cause other objectives to need
        // updating, and should be looped?

        let activity: ScoNavItem2004 = this.getActivity(this.currentActivityId);
        let data = this.scosData.scos[this.currentActivityId].exportData();
        let keys: string[] = Object.keys(data.data);
        // TODO: Actual type for this.
        let tmpGlobalObj: ActivityObjective;
        let i: number = 0;

        if (activity.sequence.primaryObjective) {
            let seqObj = activity.sequence.primaryObjective;
            let objKeyPrefix: string = "";
            if (seqObj.mapInfos.length > 0) {
                // Get the matching objective.
                for (i = 0; i < keys.length; i++) {
                    if (data.data[keys[i]] == seqObj.objectiveId) {
                        // Found the local objective data.
                        objKeyPrefix = "cmi.objectives." + keys[i].split(".")[2] + ".";
                        break;
                    }
                }

                if (objKeyPrefix != "") {
                    for (let mapIdx: number = 0; mapIdx < seqObj.mapInfos.length; mapIdx++) {
                        let map = seqObj.mapInfos[mapIdx];
                        if (map.targetObjectiveId) {
                            tmpGlobalObj = new ActivityObjective();
                            // TODO: SCORM2004-3rd_ImpactSummary.pdf, 1.4-17: Mapping of Primary Objective Status from cmi.objectives and/or cmi.success_status/cmi.score.scaled Updates
                            // We're currently only doing the success_status part of the spec.
                            if (map.writeSatisfiedStatus) {
                                tmpGlobalObj.successStatus = this.scosData.scos[activity.identifier].get("cmi.success_status") as string;
                            }
                            if (map.writeNormalizedMeasure) {
                                tmpGlobalObj.scaled = this.scosData.scos[activity.identifier].get("cmi.score.scaled") as string;
                            }
                            this.scosData.globalObjectives[map.targetObjectiveId] = tmpGlobalObj;
                            // TODO: We don't yet support adlseq:objectives and all their additional options.  Is that even allowed on primary objectives?
                        }
                    }
                }
            }
        }

        if (activity.sequence.objectives) {
            for (i = 0; i < activity.sequence.objectives.length; i++) {
                let seqObj = activity.sequence.objectives[i];
                let objKeyPrefix: string = "";
                if (seqObj.mapInfos.length > 0) {
                    // Get the matching objective.
                    for (let i: number = 0; i < keys.length; i++) {
                        if (data.data[keys[i]] == seqObj.objectiveId) {
                            // Found the local objective data.
                            objKeyPrefix = "cmi.objectives." + keys[i].split(".")[2] + ".";
                            break;
                        }
                    }

                    if (objKeyPrefix != "") {
                        for (let mapIdx: number = 0; mapIdx < seqObj.mapInfos.length; mapIdx++) {
                            let map = seqObj.mapInfos[mapIdx];
                            if (map.targetObjectiveId) {
                                tmpGlobalObj = new ActivityObjective();
                                if (map.writeSatisfiedStatus) {
                                    tmpGlobalObj.successStatus = this.scosData.scos[activity.identifier].get(objKeyPrefix + "success_status") as string;
                                }
                                if (map.writeNormalizedMeasure) {
                                    tmpGlobalObj.scaled = this.scosData.scos[activity.identifier].get(objKeyPrefix + "scaled") as string;
                                }
                                this.scosData.globalObjectives[map.targetObjectiveId] = tmpGlobalObj;
                                // TODO: We don't yet support adlseq:objectives and all their additional options.  Is that even allowed on primary objectives?
                            }
                        }
                    }
                }

            }
        }
    }

    protected currentActivity(): ScoNavItem2004 {
        if (!this.currentActivityId) {
            return null;
        }
        return this.manifest.scosByIdentifiers[this.currentActivityId] as ScoNavItem2004;
    }

    do(navigationRequest: Request): string {
        let dbg = new DebugTree();
        dbg.root = new DbgMethod("OP.1", "Overall Sequencing Processing");
        let res = this.op_1(dbg.root, navigationRequest);
        //dbg.write();
        console.log(dbg);
        return res;
    }

    // Overall Sequencing Process [OP.1]
    op_1(dbg: DbgMethod, navigationRequest: Request): string {

        let flow: RequestFlow = this.nb_2_1(navigationRequest);
        console.log("flow", flow);

        if (flow.termReq) {
            // 1.3.1
            let termFlow: RequestFlow = this.tb_2_3(flow.termReq);
            console.log("termFlow", termFlow);
            if (termFlow.status == "not_valid") {
                // 1.3.2.1
                console.log("Termination flow not valid: " + termFlow.exception);
                // 1.3.2.2
                return
            }
            // 1.3.3
            if (termFlow.seqReq) {
                // 1.3.3.1
                flow.seqReq = termFlow.seqReq;
            }
        }

        if (flow.seqReq) {
            flow = this.sb_2_12(flow.seqReq);
            console.log("flow2", flow);
            if (flow.status == "not_valid") {
                console.log("Sequencing not valid.");
                //alert("Sequencing not valid.");
                return "";
            }
            if (flow.endSequencingSession) {
                return "";
            }
            if (!flow.deliveryReq) {
                return "";
            }
        }

        if (flow.deliveryReq) {
            let flowCheck: RequestFlow = this.db_1_1(flow.deliveryReq);
            console.log("flow3", flowCheck);
            if (flow.status == "not_valid") {
                console.log("Delivery not valid.");
                //alert("Delivery not valid.");
                return "";
            }

            // TODO: db_2();
            //this.db_2();
            return flow.deliveryReq.act;
        }

        return "";
    }

    // Navigation Request Process [NB.2.1]
    nb_2_1(navigationRequest: Request): RequestFlow {
        if (navigationRequest.act == NavActions.Start) {
            if (!this.currentActivityId) {
                return new RequestFlow("valid", null,
                    new Request(RequestCategory.Sequencing, SeqActions.Start), null);
            }
            else {
                return new RequestFlow("not_valid", null,
                    null, null, null, "NB.2.1-1");
            }
        }

        // TODO 2

        if (navigationRequest.act == NavActions.Continue) {
            if (!this.currentActivityId) {
                return new RequestFlow("not_valid", null,
                    null, null, null, "NB.2.1-2");
            }

            if (this.manifest.itemTree.identifier != this.currentActivityId &&
                (this.manifest.scosByIdentifiers[this.currentActivityId].parent as ScoNavItem2004).sequence.controlMode.flow) {
                if (this.currentActivityIsActive) {
                    return new RequestFlow("valid", new Request(RequestCategory.Termination, TermActions.Exit),
                        new Request(RequestCategory.Sequencing, SeqActions.Continue), null);
                }
                else {
                    return new RequestFlow("valid", null,
                        new Request(RequestCategory.Sequencing, SeqActions.Continue), null);
                }
            }
        }

        // TODO 4 - 7

        // 8
        if (navigationRequest.act == NavActions.Exit) {
            // 8.1
            if (this.currentActivityId) {
                // 8.1.1
                if (this.currentActivityIsActive) {
                    // 8.1.1.1
                    return new RequestFlow("valid", null,
                        new Request(RequestCategory.Sequencing, SeqActions.Exit),
                        new Request(RequestCategory.Termination, TermActions.Exit));
                }
                // 8.1.2
                else {
                    // 8.1.2.1
                    return new RequestFlow("not_valid", null,
                        null, null, null, "NB.2.1-12");
                }
            }
            // 8.2
            else {
                // 8.2.1
                return new RequestFlow("not_valid", null,
                    null, null, null, "NB.2.1-2");
            }
        }

        // TODO 9 - 14
    }

    // Sequencing Exit Action Rules Subprocess [TB.2.1]
    tb_2_1(activity: ScoNavItem2004): void {
        // 1
        let scos: ScoNavItem2004[] = activity.getLineage() as ScoNavItem2004[];
        // 2
        let exitTarget: ScoNavItem2004 = null;
        // 3
        // "from the root of the activity tree to the parent of the Current Activity, inclusive"
        for (let i: number = 0; i < scos.length - 1; i++) {
            // 3.1
            let res: string = this.up_2(scos[i], [SeqActions.Exit]);
            // 3.2
            if (res !== null) {
                // 3.2.1
                exitTarget = scos[i];
                // 3.2.2
                break;
            }
        }
        // 4
        if (exitTarget) {
            // 4.1
            this.up_3(activity);
            // 4.2
            this.up_4(activity);
            // 4.3
            this.currentActivityId = exitTarget.identifier;
        }
    }

    // Sequencing Post Condition Rules Subprocess [TB.2.2]
    tb_2_2(activity: ScoNavItem2004): RequestFlow {
        console.log("tb_2_2");
        // 1
        if (this.scosData.scos[activity.identifier].get("cmi.exit") == "suspend") {
            // 1.1
            return new RequestFlow("", null, null, null);
        }
        // 2
        let actionRes: string = this.up_2(activity, [
            RuleActions.ExitParent,
            RuleActions.ExitAll,
            RuleActions.Retry,
            RuleActions.RetryAll,
            RuleActions.Continue,
            RuleActions.Previous
        ]);
        // 3
        if (actionRes) {
            // 3.1
            if (actionRes == RuleActions.Retry || actionRes == RuleActions.Continue || actionRes == RuleActions.Previous) {
                // 3.1.1
                // TODO: There's a type mismatch with actionRes, we should make sure it's a SeqActions.
                return new RequestFlow("", null, new Request(RequestCategory.Sequencing, actionRes), null);
            }
            // 3.2
            else if (actionRes == RuleActions.ExitParent || actionRes == RuleActions.ExitAll) {
                // 3.2.1
                // TODO: There's a type mismatch with actionRes, we should make sure it's a SeqActions.
                return new RequestFlow("", null, null, new Request(RequestCategory.Termination, actionRes));
            }
            // 3.3
            else if (actionRes == RuleActions.RetryAll) {
                // 3.3.1
                // TODO: There's a type mismatch with actionRes, we should make sure it's a SeqActions.
                return new RequestFlow("", null, new Request(RequestCategory.Sequencing, SeqActions.Retry), new Request(RequestCategory.Termination, TermActions.ExitAll));
            }
        }
        // 4
        return new RequestFlow("", null, null, null);
    }

    // Termination Request Process [TB.2.3]
    tb_2_3(termRequest: Request): RequestFlow {
        console.log("tb_2_3");
        let dontExit: boolean = false;
        let processedExit: boolean = false;
        let rf: RequestFlow = null;

        // 1
        if (!this.currentActivityId) {
            // 1.1
            return new RequestFlow("not valid", null, null, null, null, null, null, "TB.2.3-1");
        }

        // 2
        if (!this.currentActivityIsActive &&
            (termRequest.act == TermActions.Exit || termRequest.act == TermActions.Abandon)) {
            // 2.1
            return new RequestFlow("not valid", null, null, null, null, null, null, "TB.2.3-2");
        }

        let currentActivity: ScoNavItem2004 = this.currentActivity();

        if (termRequest.act == TermActions.Exit) {
            this.up_4(currentActivity);
            this.tb_2_1(currentActivity);
            dontExit = false;
            while (!processedExit) {
                processedExit = false;
                rf = this.tb_2_2(currentActivity);
                if (rf.termReq && rf.termReq.act == TermActions.ExitAll) {
                    termRequest.act = TermActions.ExitAll;
                    // Fall through to the exit all code.
                    dontExit = true;
                    break;
                }

                if (rf.termReq && rf.termReq.act == TermActions.ExitParent) {
                    if (this.manifest.scosByIdentifiers[this.currentActivityId].parent) {
                        this.currentActivityId = currentActivity.parent.identifier;
                        processedExit = true;
                    }
                    else {
                        return new RequestFlow("not valid", null, null, null, null, null, null, "TB.2.3-4");
                    }
                }
                else {
                    if (!currentActivity.parent &&
                        rf.seqReq.act != SeqActions.Retry) {
                        return new RequestFlow("valid", null, new Request(RequestCategory.Sequencing, SeqActions.Exit), null);
                    }
                }
                return new RequestFlow("valid", null, rf.seqReq, null);
            }
        }

        if (termRequest.act == TermActions.ExitAll) {
            if (this.currentActivityIsActive) {
                this.up_4(currentActivity);
            }
            this.up_3(currentActivity);
            this.currentActivityId = this.manifest.itemTree.identifier;
            this.up_4(this.getActivity(this.currentActivityId));
            return new RequestFlow("valid", null, new Request(RequestCategory.Sequencing, SeqActions.Exit), null);
        }

        // TODO: Steps 5-8

        return null;
        // TODO
    }

    // Measure Rollup Process [RB.1.1 a]
    rb_1_1a(activity: ScoNavItem2004): void {
        console.log("rb_1_1a", activity.identifier);
        let childObjective: Objective = null;
        let tmpSco: ScoNavItem2004 = null;

        // 1
        let totalMeasure: number = 0.0;
        // 2
        let validData: boolean = false;
        // 3
        let countedMeasures: number = 0.0;
        // 4 - 5.1.2
        let targetObjective: Objective = activity.sequence.primaryObjective;
        // 6
        if (targetObjective) {
            // 6.1
            for (let i: number = 0; i < activity.children.length; i++) {
                tmpSco = activity.getKid(i);
                childObjective = null;
                // 6.1.1
                if (tmpSco.sequence.deliveryControls.tracked) {
                    // 6.1.1.1 - 6.1.1.2.1.2
                    childObjective = tmpSco.sequence.primaryObjective;
                }

                // 6.1.1.3
                if (childObjective) {
                    // 6.1.1.3.1
                    countedMeasures += tmpSco.sequence.rollup.objectiveMeasureWeight;
                    // 6.1.1.3.2
                    let scaled = this.scosData.scos[tmpSco.identifier].get("cmi.objectives.0.score.scaled");
                    if (scaled) {
                        // 6.1.1.3.2.1
                        totalMeasure += tmpSco.sequence.rollup.objectiveMeasureWeight * parseFloat(scaled as string);
                    }
                    // 6.1.1.3.2.2
                    validData = true;
                }
                // 6.1.1.4
                else {
                    // 6.1.1.4.1
                    return;
                }
            }

            // 6.2
            if (!validData) {
                // 6.2.1
                this.scosData.scos[activity.identifier].set("cmi.objectives.0.score.scaled", "");
            }
            // 6.3
            else {
                // 6.3.1
                if (countedMeasures > 0.0) {
                    // 6.3.1.1 - 6.3.1.2
                    this.scosData.scos[activity.identifier].set(
                        "cmi.objectives.0.score.scaled",
                        String(totalMeasure / countedMeasures)
                    );
                }
                // 6.3.2
                else {
                    // 6.3.2.1
                    this.scosData.scos[activity.identifier].set("cmi.objectives.0.score.scaled", "");
                }
            }
        }
    }

    // Completion Measure Rollup Process [RB.1.1 b]
    rb_1_1b(activity: ScoNavItem2004): void {
        console.log("rb_1_1b", activity.identifier);
        let tmpSco: ScoNavItem2004 = null;
        // 1
        let totalMeasure: number = 0.0;
        // 2
        let validData: boolean = false;
        // 3
        let countedMeasures: number = 0.0;

        // 4
        for (let i: number = 0; i < activity.children.length; i++) {
            tmpSco = activity.getKid(i);
            // 4.1
            if (tmpSco.sequence.deliveryControls.tracked) {
                // 4.1.1
                if (tmpSco.completionThreshold && tmpSco.completionThreshold.progressWeight) {
                    countedMeasures += tmpSco.completionThreshold.progressWeight;
                    // 4.1.2
                    let progressMeasure = this.scosData.scos[tmpSco.identifier].get("cmi.progress_measure");
                    if (progressMeasure) {
                        // 4.1.2.1
                        totalMeasure += parseFloat(progressMeasure as string) * tmpSco.completionThreshold.progressWeight;
                        // 4.1.2.2
                        validData = true;
                    }
                }
            }
        }

        // 5 - 5.2
        if (validData) {
            // 5.2.1
            if (countedMeasures > 0.0) {
                // 5.2.1.1 - 5.2.1.2
                this.scosData.scos[activity.identifier].set("cmi.progress_measure", String(totalMeasure / countedMeasures));
            }
            else {
                // 5.2.2.1
                this.scosData.scos[activity.identifier].set("cmi.progress_measure", "");
            }
        }
    }

    protected setRollupAndLocalObjectiveSuccess(obj: Objective, act: ScoNavItem2004, val: string): void {
        console.log("writing success_status = ", val);
        act.rollupObjective.successStatus = val;
        // Empty objective ID means there's no local objective to write this to.
        if (obj.objectiveId != "") {
            this.scosData.scos[act.identifier].set("cmi.objectives.0.success_status", val);
        }
        // TODO: Is it wrong to update the actual cmi.success_status?  This function
        // is only called on the primary objective, so it should be speaking
        // for the SCO as a whole.  Adding it in until I understand why it's wrong
        // to do so.
        this.scosData.scos[act.identifier].set("cmi.success_status", val);
    }

    // Objective Rollup Using Measure Process [RB.1.2 a]
    rb_1_2a(activity: ScoNavItem2004): void {
        console.log("rb_1_2a", activity.identifier);
        // 1 - 2.1.2
        let targetObjective: Objective = activity.sequence.primaryObjective;
        //let scaled = this.scosData.scos[activity.identifier].get("cmi.objectives.0.score.scaled");
        // TODO This should select from the objective scaled score OR the activity scaled score,
        // based on the precedence rules in table 4.5.4a in the SN book.
        let scaled = this.scosData.scos[activity.identifier].get("cmi.score.scaled");

        // 3
        // An activity always has a primary objective, even if it's implicit.
        // See https://www.imsglobal.org/simplesequencing/ssv1p0/imsss_bestv1p0.html
        // under Glossary - objectives:
        // "Each activity must have at least one primary objective".
        // An implicit primary objective just won't have a local or global
        // objective that it reads from/writes to.  There still has to be a
        // data model objective on the activity itself, to store state.
        if (targetObjective) {
            // 3.1
            console.log(targetObjective.satisfiedByMeasure);
            if (targetObjective.satisfiedByMeasure) {
                // 3.1.1
                if (!scaled) {
                    // 3.1.1.1
                    this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "unknown");
                }
                // 3.1.2
                else {
                    // 3.1.2.1
                    if (!this.currentActivityIsActive || (
                        activity.identifier == this.currentActivityId &&
                        activity.sequence.rollupConsiderations.measureSatisfactionIfActive)) {
                        // 3.1.2.1.1
                        if (scaled >= activity.sequence.primaryObjective.minNormalizedMeasure) {
                            // 3.1.2.1.1.1 - 3.1.2.1.1.2
                            this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "passed");
                        }
                        else {
                            // 3.1.2.1.2.1 - 3.1.2.1.2.2
                            this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "failed");
                        }
                    }
                    // 3.1.2.2
                    else {
                        // 3.1.2.2.1
                        this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "unknown");
                    }
                }
            }
        }
    }

    // Objective Rollup Using Rules Process [RB.1.2 b]
    rb_1_2b(activity: ScoNavItem2004): void {
        console.log("rb_1_2b", activity.identifier);
        // 1
        // TODO: The rule reads:
        // "If the activity does not include Rollup Rules with the Not Satisfied
        // rollup action And the activity does not include Rollup Rules with the
        // Satisfied rollup action Then"
        // But rule 4.1 and 4.3 seem to assume both not satisfied and satisfied
        // rules exist.  What if only one existed before this check?  Should
        // the other one be created?  Is this "AND" not a logical and?
        let rollupActionFound: boolean = false;
        for (let i: number = 0; i < activity.sequence.rollup.rules.length; i++) {
            let action: string = activity.sequence.rollup.rules[i].action;
            if (action == RollupActions.Satisfied || action == RollupActions.NotSatisfied) {
                rollupActionFound = true;
                break;
            }
        }
        if (!rollupActionFound) {
            console.log("making default rollup actions for satisfied")
            // 1.1
            let rr = new RollupRule();
            let rc = new RollupCondition();
            rr.action = RollupActions.Satisfied;
            // TODO: Const this.
            rr.childActivitySet = "all";
            rc.condition = "satisfied";
            rr.conditions.push(rc);
            activity.sequence.rollup.rules.push(rr);

            // 1.2
            rr = new RollupRule();
            rc = new RollupCondition();
            rr.action = RollupActions.NotSatisfied;
            // TODO: Const this.
            rr.childActivitySet = "all";
            rc.condition = "objectiveStatusKnown";
            rr.conditions.push(rc);
            activity.sequence.rollup.rules.push(rr);
        }

        // 2 - 3.1.2
        let targetObjective: Objective = activity.sequence.primaryObjective;
        // 4
        // An activity always has a primary objective, even if it's implicit.
        // See rb_1_2a for details.
        if (targetObjective) {
            // 4.1
            let rollupCheck: boolean = this.rb_1_4(activity, RollupActions.NotSatisfied);
            // 4.2
            if (rollupCheck) {
                // 4.2.1 - 4.2.2
                this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "failed");
            }
            // 4.3
            rollupCheck = this.rb_1_4(activity, RollupActions.Satisfied);
            // 4.4
            if (rollupCheck) {
                // 4.4.1 - 4.4.2
                this.setRollupAndLocalObjectiveSuccess(targetObjective, activity, "passed");
            }
        }
    }

    // Activity Progress Rollup Using Measure Process [RB.1.3 a]
    rb_1_3a(activity: ScoNavItem2004): void {
        console.log("rb_1_3a", activity.identifier);
        // TODO: Should this be explicitly overwritten right here?
        // Attempt Progress Status and Attempt Completion Status supposedly map to this.
        // 1 - 2
        this.scosData.scos[activity.identifier].set("cmi.completion_status", "incomplete");
        // 3
        if (activity.completionThreshold.completedByMeasure) {
            // 3.1
            let progressMeasure: string = this.scosData.scos[activity.identifier].get("cmi.progress_measure") as string;
            if (!progressMeasure) {
                // 3.1.1
                // TODO: Should this be unknown or incomplete?
                this.scosData.scos[activity.identifier].set("cmi.completion_status", "incomplete");
            }
            // 3.2
            else {
                // 3.2.1
                if (parseFloat(progressMeasure) > activity.completionThreshold.minProgressMeasure) {
                    // 3.2.1.1 - 3.2.1.2
                    this.scosData.scos[activity.identifier].set("cmi.completion_status", "completed");
                }
                else {
                    // 3.2.2.1 - 3.2.2.2
                    this.scosData.scos[activity.identifier].set("cmi.completion_status", "incomplete");
                }
            }
        }
        // 4
        else {
            // 4.1
            // TODO: Do we overwrite this, or just leave it alone?
            this.scosData.scos[activity.identifier].set("cmi.completion_status", "unknown");
        }
    }

    // Activity Progress Rollup Using Rules Process [RB.1.3 b]
    rb_1_3b(activity: ScoNavItem2004): void {
        console.log("rb_1_3b", activity.identifier);
        // 1
        // TODO: The same issue as rb_1_2b.
        let rollupActionFound: boolean = false;
        for (let i: number = 0; i < activity.sequence.rollup.rules.length; i++) {
            let action: string = activity.sequence.rollup.rules[i].action;
            if (action == RollupActions.Completed || action == RollupActions.Incomplete) {
                rollupActionFound = true;
                break;
            }
        }
        if (!rollupActionFound) {
            // 1.1
            let rr = new RollupRule();
            let rc = new RollupCondition();
            rr.action = RollupActions.Completed;
            // TODO: Const this.
            rr.childActivitySet = "all";
            rc.condition = "completed";
            rr.conditions.push(rc);
            activity.sequence.rollup.rules.push(rr);

            // 1.2
            rr = new RollupRule();
            rc = new RollupCondition();
            rr.action = RollupActions.Incomplete;
            // TODO: Const this.
            rr.childActivitySet = "all";
            rc.condition = "activityProgressKnown";
            rr.conditions.push(rc);
            activity.sequence.rollup.rules.push(rr);
        }

        // 2
        let rollupCheck: boolean = this.rb_1_4(activity, RollupActions.Incomplete);
        // 3
        if (rollupCheck) {
            // 3.1 - 3.2
            this.scosData.scos[activity.identifier].set("cmi.completion_status", "incomplete");
        }
        // 4
        rollupCheck = this.rb_1_4(activity, RollupActions.Completed);
        // 5
        if (rollupCheck) {
            // 5.1 - 5.2
            this.scosData.scos[activity.identifier].set("cmi.completion_status", "completed");
        }
    }

    // Rollup Rule Check Subprocess [RB.1.4]
    rb_1_4(activity: ScoNavItem2004, rollupAction: string): boolean {
        console.log("rb_1_4", activity.identifier, rollupAction);
        // 1 - 1.1
        let rollupActionFound: boolean = false;
        let rules: RollupRule[] = [];
        let rule: RollupRule = null;
        let i: number = 0, j: number = 0;
        let rollupAnswers: boolean[];
        for (i = 0; i < activity.sequence.rollup.rules.length; i++) {
            if (activity.sequence.rollup.rules[i].action == rollupAction) {
                rules.push(activity.sequence.rollup.rules[i]);
            }
        }
        console.log("rb_1_4 rules", rules)
        if (rules.length > 0) {
            // 1.2
            for (i = 0; i < rules.length; i++) {
                rule = rules[i];
                // 1.2.1
                rollupAnswers = [];
                // 1.2.2
                for (j = 0; j < activity.children.length; j++) {
                    let child: ScoNavItem2004 = activity.getKid(j);
                    // 1.2.2.1
                    console.log("child tracked?", child.identifier, child.sequence.deliveryControls.tracked);
                    if (child.sequence.deliveryControls.tracked) {
                        console.log("attempting rollup on child", child.identifier);
                        // 1.2.2.1.1
                        let childRollupResult: boolean = this.rb_1_4_2(child, rule.action);
                        console.log("result: ", childRollupResult);
                        // 1.2.2.1.2
                        if (childRollupResult === true) {
                            // 1.2.2.1.2.1 - 1.2.2.1.2.3.2.1
                            rollupAnswers.push(this.rb_1_4_1(child, rule));
                        }
                    }
                }

                // 1.2.3
                let statusChanged: boolean = false;
                // 1.2.4
                if (rollupAnswers.length > 0) {
                    // 1.2.5
                    if (rule.childActivitySet == "all") {
                        console.log("all check");
                        // 1.2.5.1
                        statusChanged = true;
                        for (j = 0; j < rollupAnswers.length; j++) {
                            console.log("rollup answers j", j, rollupAnswers[j]);
                            if (rollupAnswers[j] !== true) {
                                statusChanged = false;
                                break;
                            }
                        }
                        console.log("all check passed", statusChanged);
                    }
                    // 1.2.6
                    else if (rule.childActivitySet == "any") {
                        // 1.2.6.1
                        for (j = 0; j < rollupAnswers.length; j++) {
                            if (rollupAnswers[j] === true) {
                                statusChanged = true;
                                break;
                            }
                        }
                    }
                    // 1.2.7
                    else if (rule.childActivitySet == "none") {
                        // 1.2.7.1
                        statusChanged = true;
                        for (j = 0; j < rollupAnswers.length; j++) {
                            if (rollupAnswers[j] !== false) {
                                statusChanged = false;
                                break;
                            }
                        }
                    }
                    // 1.2.8
                    else if (rule.childActivitySet == "atLeastCount") {
                        // 1.2.8.1
                        let rollupCount: number = 0;
                        for (j = 0; j < rollupAnswers.length; j++) {
                            if (rollupAnswers[j] === true) {
                                rollupCount += 1;
                            }
                        }
                        if (rollupCount >= rule.minimumCount) {
                            statusChanged = true;
                        }
                    }
                    // 1.2.9
                    else if (rule.childActivitySet == "atLeastPercent") {
                        // 1.2.9.1
                        let rollupCount: number = 0;
                        for (j = 0; j < rollupAnswers.length; j++) {
                            if (rollupAnswers[j] === true) {
                                rollupCount += 1;
                            }
                        }
                        if ((rollupCount / rollupAnswers.length) >= rule.minimumPercent) {
                            statusChanged = true;
                        }
                    }
                }

                // 1.2.10
                if (statusChanged) {
                    // 1.2.10.1
                    return true;
                }
            }
        }
        return false;
    }

    protected globalIdFromLocalObjective(localObj: Objective): string {
        if (localObj) {
            if (localObj.mapInfos.length > 0) {
                return localObj.mapInfos[0].targetObjectiveId;
            }
        }
        return "";
    }

    protected globalIdFromPrimaryObjective(activity: ScoNavItem2004): string {
        return this.globalIdFromLocalObjective(activity.sequence.primaryObjective);
    }

    // Evaluate Rollup Conditions Subprocess [RB.1.4.1]
    rb_1_4_1(activity: ScoNavItem2004, rule: RollupRule): boolean {
        // returning null for "unknown".
        let i: number = 0;

        // 1
        let rollupResults: boolean[] = [];
        // 2
        for (let i: number = 0; i < rule.conditions.length; i++) {
            let result: boolean = null;
            console.log(rule.conditions[i]);
            // 2.1

            // TODO Since we're in rollup, this should be checking the activity level, right?
            // Verify this.
            // Really it should be checking Objective Status Whatever, but we
            // don't maintain separate states for that at this point.
            if (rule.conditions[i].condition == "satisfied") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     if (this.scosData.globalObjectives[globalObjectiveId].successStatus == "passed") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let status: string = this.scosData.scos[activity.identifier].get("cmi.success_status") as string;
                console.log("rb_1_4_1 status", status);
                if (status == "passed") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            else if (rule.conditions[i].condition == "objectiveStatusKnown") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     let status: string = this.scosData.globalObjectives[globalObjectiveId].successStatus;
                //     if (status == "passed" || status == "failed") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let status: string = this.scosData.scos[activity.identifier].get("cmi.success_status") as string;
                if (status == "passed" || status == "failed") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            else if (rule.conditions[i].condition == "objectiveMeasureKnown") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     let measure: string = this.scosData.globalObjectives[globalObjectiveId].scaled;
                //     if (measure != "") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let measure: string = this.scosData.scos[activity.identifier].get("cmi.score.scaled") as string;
                if (measure != "") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            else if (rule.conditions[i].condition == "completed") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                //     if (status == "completed") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let status: string = this.scosData.scos[activity.identifier].get("cmi.completion_status") as string;
                if (status == "completed") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            else if (rule.conditions[i].condition == "activityProgressKnown") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                //     if (status == "completed" || status == "incomplete") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let status: string = this.scosData.scos[activity.identifier].get("cmi.completion_status") as string;
                if (status == "completed" || status == "incomplete") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            else if (rule.conditions[i].condition == "attempted") {
                // let globalObjectiveId: string = this.globalIdFromPrimaryObjective(activity);
                // if (globalObjectiveId) {
                //     let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                //     if (status == "completed" || status == "incomplete") {
                //         result = true;
                //     }
                //     else {
                //         result = false;
                //     }
                // }

                let status: string = this.scosData.scos[activity.identifier].get("cmi.completion_status") as string;
                if (status == "completed" || status == "incomplete") {
                    result = true;
                }
                else {
                    result = false;
                }
            }
            // TODO The SN and the CAM book disagree on the existence of this.
            else if (rule.conditions[i].condition == "attemptLimitExceeded") {
                // TODO
            }
            // TODO The SN and the CAM book disagree on the existence of this.
            else if (rule.conditions[i].condition == "timeLimitExceeded") {
                // TODO
            }
            // TODO The SN and the CAM book disagree on the existence of this.
            else if (rule.conditions[i].condition == "outsideAvailableTimeRange") {
                // TODO
            }
            // TODO The SN and the CAM book disagree on the existence of this.
            else if (rule.conditions[i].condition == "never") {
                result = false;
            }
            // TODO The SN and the CAM book don't disagree on the existence of this,
            // but if never maybe exists, does this?
            else if (rule.conditions[i].condition == "always") {
                result = true;
            }

            // 2.2
            if (rule.conditions[i].operator == "not") {
                // 2.2.1
                if (result === true) {
                    result = false;
                }
                else if (result === false) {
                    result = true;
                }
            }

            // 2.3
            rollupResults.push(result);
        }

        // 3
        if (rollupResults.length == 0) {
            // 3.1
            return null;
        }
        // 4
        if (rule.conditionCombination == "all") {
            for (i = 0; i < rollupResults.length; i++) {
                if (rollupResults[i] !== true) {
                    return false;
                }
            }
            return true;
        }
        else if (rule.conditionCombination == "any") {
            for (i = 0; i < rollupResults.length; i++) {
                if (rollupResults[i] === true) {
                    return true;
                }
            }
            return false;
        }
    }

    // Check Child for Rollup Subprocess [RB.1.4.2]
    rb_1_4_2(activity: ScoNavItem2004, rollupAction: string): boolean {
        // 1
        let included: boolean = false;
        // 2
        if (rollupAction == RollupActions.Satisfied || rollupAction == RollupActions.NotSatisfied) {
            // 2.1
            if (activity.sequence.rollup.rollupObjectiveSatisfied) {
                // 2.1.1
                included = true;
                // 2.1.2
                if ((rollupAction == RollupActions.Satisfied && activity.sequence.rollupConsiderations.requiredForSatisfied == "ifNotSuspended") ||
                    (rollupAction == RollupActions.NotSatisfied && activity.sequence.rollupConsiderations.requiredForNotSatisfied == "ifNotSuspended")) {
                    // 2.1.2.1
                    // TODO Exclude suspended activities 2.1.2.1(.1)
                }
                // 2.1.3
                else {
                    // 2.1.3.1
                    if ((rollupAction == RollupActions.Satisfied && activity.sequence.rollupConsiderations.requiredForSatisfied == "ifAttempted") ||
                        (rollupAction == RollupActions.NotSatisfied && activity.sequence.rollupConsiderations.requiredForNotSatisfied == "ifAttempted")) {
                        // 2.1.3.1.1
                        // TODO Exclude suspended activities 2.1.3.1.1(.1)
                    }
                    // 2.1.3.2
                    else {
                        // 2.1.3.2.1
                        if ((rollupAction == RollupActions.Satisfied && activity.sequence.rollupConsiderations.requiredForSatisfied == "ifNotSkipped") ||
                            (rollupAction == RollupActions.NotSatisfied && activity.sequence.rollupConsiderations.requiredForNotSatisfied == "ifNotSkipped")) {
                            // 2.1.3.2.1.1 - 2.1.3.2.1.2
                            if (this.up_2(activity, ["skipped"]) != "unknown") {
                                // 2.1.3.2.1.2.1
                                included = false;
                            }
                        }

                    }
                }
            }
        }
        // 3
        else if (rollupAction == RollupActions.Completed || rollupAction == RollupActions.Incomplete) {
            // 3.1
            if (activity.sequence.rollup.rollupProgressCompletion) {
                // 3.1.1
                included = true;
                // 3.1.2
                if ((rollupAction == RollupActions.Completed && activity.sequence.rollupConsiderations.requiredForCompleted == "ifNotSuspended") ||
                    (rollupAction == RollupActions.Incomplete && activity.sequence.rollupConsiderations.requiredForIncomplete == "ifNotSuspended")) {
                    // 3.1.2.1
                    // TODO Exclude suspended activities 3.1.2.1(.1)
                }
                // 3.1.3
                else {
                    // 3.1.3.1
                    if ((rollupAction == RollupActions.Completed && activity.sequence.rollupConsiderations.requiredForCompleted == "ifAttempted") ||
                        (rollupAction == RollupActions.Incomplete && activity.sequence.rollupConsiderations.requiredForIncomplete == "ifAttempted")) {
                        // 3.1.3.1.1
                        // TODO Exclude suspended activities 2.1.3.1.1(.1)
                    }
                    // 3.1.3.2
                    else {
                        // 3.1.3.2.1
                        if ((rollupAction == RollupActions.Completed && activity.sequence.rollupConsiderations.requiredForCompleted == "ifNotSkipped") ||
                            (rollupAction == RollupActions.Incomplete && activity.sequence.rollupConsiderations.requiredForIncomplete == "ifNotSkipped")) {
                            // 3.1.3.2.1.1 - 3.1.3.2.1.2
                            if (this.up_2(activity, ["skipped"]) != "unknown") {
                                // 3.1.3.2.1.2.1
                                included = false;
                            }
                        }
                    }
                }
            }
        }
        return included;
    }

    // Overall Rollup Process [RB.1.5]
    rb_1_5(activity: ScoNavItem2004): void {
        console.log("rb_1_5");
        // 1
        let activityList: ScoNavItem2004[] = activity.getLineage() as ScoNavItem2004[];
        activityList.reverse()
        // 2
        if (activityList.length == 0) {
            return;
        }
        // 3
        for (let i: number = 0; i < activityList.length; i++) {
            // 3.1
            if (!activityList[i].isLeaf) {
                // 3.1.1
                this.rb_1_1a(activityList[i]);
                // 3.1.2
                this.rb_1_1b(activityList[i]);
            }
            // 3.2
            this.rb_1_2a(activityList[i]);
            this.rb_1_2b(activityList[i]);
            // 3.3
            this.rb_1_3a(activityList[i]);
            this.rb_1_3b(activityList[i]);
        }
    }

    // Select Children Process [SR.1]
    // Randomize Children Process [SR.2]

    // Flow Tree Traversal Subprocess [SB.2.1]
    sb_2_1(activity: ScoNavItem2004, direction: Direction,
        previousDirection: Direction, considerChildren: boolean): TraversalReturn {
        let reversedDirection: boolean = false;
        if (previousDirection == Direction.Backward // TODO && MORE TESTS HERE?
        ) {
            // TODO WUT DO WE DO HERE WUT (2.*)
        }

        if (direction == Direction.Forward) {
            if (activity.identifier == this.manifest.activityOrdered[this.manifest.activityOrdered.length - 1].identifier ||
                (!considerChildren && activity.identifier == this.manifest.itemTree.identifier)
            ) {
                this.up_3(activity);
                // TODO return correctly 3.1.2
                return null;
            }

            if (activity.isLeaf || !considerChildren) {
                let kids: ScoNavItem2004[] = activity.parent.availableChildren() as ScoNavItem2004[];
                if (activity.identifier == kids[kids.length - 1].identifier) {
                    return this.sb_2_1(activity.parent as ScoNavItem2004, direction, Direction.None, considerChildren);
                }
                else {
                    for (let i: number = 0; i < kids.length; i++) {
                        if (kids[i].identifier == activity.identifier) {
                            return new TraversalReturn(kids[i + 1], direction);
                        }
                    }
                }
            }
            else {
                let kids: ScoNavItem2004[] = activity.availableChildren() as ScoNavItem2004[];
                if (kids.length > 0) {
                    return new TraversalReturn(kids[0] as ScoNavItem2004, direction);
                }
                return new TraversalReturn(null, direction, false, "SB.2.1-2");
            }
        }
        else if (direction == Direction.Backward) {
            // TODO: 4.*
        }

        return null;
        // TODO
    }

    // Flow Activity Traversal Subprocess [SB.2.2]
    sb_2_2(activity: ScoNavItem2004, direction: Direction, previousDirection: Direction): FlowReturn {
        let tr: TraversalReturn;
        console.log("sb_2_2", activity);
        if (!((activity.parent as ScoNavItem2004).sequence.controlMode.flow)) {
            return new FlowReturn(activity, false, true, "SB.2.2-1");
        }
        let skipResult: string = this.up_2(activity, ["skipped"]);
        if (skipResult != null) {
            tr = this.sb_2_1(activity, direction, previousDirection, false);
            if (tr.activity == null) {
                return new FlowReturn(activity, false, tr.endSequencingSession, tr.exception);
            }

            if (previousDirection == Direction.Backward && tr.direction == Direction.Backward) {
                tr = this.sb_2_1(tr.activity, direction, Direction.None, true);
            }
            else {
                tr = this.sb_2_1(tr.activity, direction, previousDirection, true);
            }
            return new FlowReturn(tr.activity, tr.activity == null ? true : false, tr.endSequencingSession, tr.exception);
        }

        if (this.up_5(activity)) {
            return new FlowReturn(activity, false, false, "SB.2.2-2");
        }

        if (!activity.isLeaf) {
            tr = this.sb_2_1(tr.activity, direction, Direction.None, true);
            if (tr.activity == null) {
                return new FlowReturn(activity, false, tr.endSequencingSession, tr.exception);
            }

            if (direction == Direction.Backward && tr.direction == Direction.Forward) {
                tr = this.sb_2_1(tr.activity, Direction.Forward, Direction.Backward, true);
            }
            else {
                tr = this.sb_2_1(tr.activity, direction, Direction.None, true);
            }
            return new FlowReturn(tr.activity, tr.activity == null ? true : false, tr.endSequencingSession, tr.exception);
        }

        return new FlowReturn(activity, true);
    }

    // Flow Subprocess [SB.2.3]
    sb_2_3(activity: ScoNavItem2004, direction: Direction, considerChildren: boolean): FlowReturn {
        console.log("sb_2_3", activity, direction, considerChildren);
        let tr: TraversalReturn = this.sb_2_1(activity, direction, Direction.None, considerChildren);
        console.log("sb_2_3 tr", tr);
        if (!tr.activity) {
            return new FlowReturn(activity, false, tr.endSequencingSession, tr.exception);
        }
        else {
            return this.sb_2_2(tr.activity, direction, Direction.None);
        }
    }

    // Choice Activity Traversal Subprocess [SB.2.4]

    // Start Sequencing Request Process [SB.2.5]
    sb_2_5(): RequestFlow {
        if (this.currentActivityId) {
            return new RequestFlow(null, null, null, null, null, null, false, "SB.2.5-1");
        }
        if (this.manifest.activityOrdered.length == 1) {
            return new RequestFlow(null, null, null, null, new Request(RequestCategory.Delivery, this.manifest.activityOrdered[0].identifier), null, false, "");
        }

        let fr: FlowReturn = this.sb_2_3(this.manifest.activityOrdered[0] as ScoNavItem2004, Direction.Forward, true);
        if (fr.exception) {
            return new RequestFlow(null, null, null, null, null, null, fr.endSequencingSession, fr.exception);
        }
        return new RequestFlow(null, null, null, null, new Request(RequestCategory.Delivery, fr.activity.identifier));
    }

    // Resume All Sequencing Request Process [SB.2.6]

    // Continue Sequencing Request Process [SB.2.7]
    sb_2_7(): RequestFlow {
        if (!this.currentActivityId) {
            return new RequestFlow(null, null, null, null, null, null, false, "SB.2.7-1");
        }
        if (this.manifest.activityOrdered.length > 0 &&
            this.manifest.activityOrdered[0].identifier == this.currentActivityId) {
            if (!((this.manifest.activityOrdered[0].parent as ScoNavItem2004).sequence.controlMode.flow)) {
                return new RequestFlow(null, null, null, null, null, null, false, "SB.2.7-2");
            }
        }

        let fr: FlowReturn = this.sb_2_3(this.manifest.scosByIdentifiers[this.currentActivityId] as ScoNavItem2004, Direction.Forward, false);
        if (fr.exception) {
            return new RequestFlow(null, null, null, null, null, null, fr.endSequencingSession, fr.exception);
        }
        return new RequestFlow(null, null, null, null, new Request(RequestCategory.Delivery, fr.activity.identifier));
    }

    // Previous Sequencing Request Process [SB.2.8]
    // Choice Sequencing Request Process [SB.2.9]
    // Choice Flow Subprocess [SB.2.9.1]
    // Choice Flow Tree Traversal Subprocess [SB.2.9.2]
    // Retry Sequencing Request Process [SB.2.10]

    // Exit Sequencing Request Process [SB.2.11]
    sb_2_11(): RequestFlow {
        // 1
        if (!this.currentActivityId) {
            // 1.1
            return new RequestFlow(null, null, null, null, null, null, false, "SB.2.11-1");
        }
        // 2
        if (this.currentActivityIsActive) {
            // 2.1
            return new RequestFlow(null, null, null, null, null, null, false, "SB.2.11-2");
        }
        // 3
        if (this.currentActivityId == this.manifest.itemTree.identifier) {
            // 3.1
            return new RequestFlow(null, null, null, null, null, null, true);
        }
        // 4
        return new RequestFlow(null, null, null, null, null, null, false);
    }

    // Sequencing Request Process [SB.2.12]
    sb_2_12(seqRequest: Request): RequestFlow {
        let rf: RequestFlow;
        if (seqRequest.act == SeqActions.Start) {
            rf = this.sb_2_5();

            if (rf.exception) {
                return new RequestFlow("not_valid", null, null, null, null, null, false, rf.exception);
            }
            return new RequestFlow("valid", null, null, null, rf.deliveryReq, null, rf.endSequencingSession, "");
        }

        // TODO 2

        // 3
        if (seqRequest.act == SeqActions.Exit) {
            // 3.1
            rf = this.sb_2_11();
            // 3.2
            if (rf.exception) {
                // 3.2.1
                return new RequestFlow("not_valid", null, null, null, null, null, false, rf.exception);
            }
            // 3.3
            else {
                // 3.3.1
                return new RequestFlow("valid", null, null, null, rf.deliveryReq, null, rf.endSequencingSession, "");
            }
        }

        // TODO 4

        // 5
        if (seqRequest.act == SeqActions.Continue) {
            rf = this.sb_2_7();

            if (rf.exception) {
                return new RequestFlow("not_valid", null, null, null, null, null, false, rf.exception);
            }
            return new RequestFlow("valid", null, null, null, rf.deliveryReq, null, rf.endSequencingSession, "");
        }

        return null;
        // TODO Step 6 and beyond.
    }

    // Jump Sequencing Request Process [SB.2.13]


    // Delivery Request Process [DB.1.1]
    db_1_1(req: Request): RequestFlow {
        console.log("db_1_1 req.act", req.act);
        console.log("db_1_1 activity", this.manifest.scosByIdentifiers);
        let activity: ScoNavItem2004 = this.manifest.scosByIdentifiers[req.act] as ScoNavItem2004;
        if (!activity.isLeaf) {
            return new RequestFlow("not_valid", null, null, null, null, null, false, "DB.1.1-1");
        }
        let scos: ScoNavItem2004[] = activity.getLineage() as ScoNavItem2004[];
        if (scos.length == 0) {
            return new RequestFlow("not_valid", null, null, null, null, null, false, "DB.1.1-2");
        }
        for (let i: number = 0; i < scos.length; i++) {
            if (this.up_5(scos[i])) {
                return new RequestFlow("not_valid", null, null, null, null, null, false, "DB.1.1-3");
            }
        }
        return new RequestFlow("valid", null, null, null);
    }

    // Content Delivery Environment Process [DB.2]
    db_2(req: Request): string {
        // TODO: Should this check the tree instead?
        if (req.act == this.currentActivityId) {

        }

        // TODO Is this the right place to init local objectives?
        this.initLocalObjectives(req.act);

        // TODO
        return req.act;
    }

    initLocalObjectives(activityId: string): void {
        let activity: ScoNavItem2004 = this.manifest.scosByIdentifiers[activityId] as ScoNavItem2004;
        if (activity.sequence.primaryObjective) {
            // Set up the primary objective.
            if (!this.scosData.scos[activityId].get("cmi.objectives.0.id")) {
                this.scosData.scos[activityId].set("cmi.objectives.0.id", activity.sequence.primaryObjective.objectiveId);
                // TODO: Init the statuses too.
            }

            // Set up any secondary objectives.
            for (let i: number = 0; i < activity.sequence.objectives.length; i++) {
                if (!this.scosData.scos[activityId].get("cmi.objectives." + (i + 1) + ".id")) {
                    this.scosData.scos[activityId].set("cmi.objectives." + (i + 1) + ".id", activity.sequence.objectives[i].objectiveId);
                }
                // TODO: Init the statuses too.
            }
        }
    }

    // Clear Suspended Activity Subprocess [DB.2.1]

    // Limit Conditions Check Process [UP.1]
    up_1(activity: ScoNavItem2004): boolean {
        console.log("up_1");
        if (!activity.sequence.deliveryControls.tracked) {
            return false;
        }
        return false;
        // TODO Most everything.  False is a good default because it will at least
        // allow everything through.
    }

    // Sequencing Rules Check Process [UP.2]
    up_2(activity: ScoNavItem2004, actions: string[]): string {
        let rules: SequenceRule[][] = [activity.sequence.preConditionRules,
            activity.sequence.postConditionRules,
            activity.sequence.exitConditionRules
        ];
        for (let rType: number = 0; rType < rules.length; rType++) {
            for (let i: number = 0; i < rules[rType].length; i++) {
                for (let actionIdx: number = 0; actionIdx < actions.length; actionIdx++) {
                    if (rules[rType][i].action == actions[actionIdx]) {
                        if (this.up_2_1(activity, rules[rType][i]) == "true") {
                            return rules[rType][i].action;
                        }
                    }
                }
            }
        }
        return null;
    }

    protected globalObjectiveIdFromLocalObjectiveId(activity: ScoNavItem2004, objectiveId: string): string {
        if (objectiveId == "") {
            if (activity.sequence.primaryObjective) {
                return this.globalIdFromLocalObjective(activity.sequence.primaryObjective);
            }
            return "";
        }
        for (let i: number = 0; i < activity.sequence.objectives.length; i++) {
            if (activity.sequence.objectives[i].objectiveId == objectiveId) {
                return this.globalIdFromLocalObjective(activity.sequence.objectives[i]);
            }
        }
        return "";
    }

    // Sequencing Rule Check Subprocess [UP.2.1]
    up_2_1(activity: ScoNavItem2004, rule: SequenceRule): string {
        console.log("up_2_1", activity, rule);
        let i: number;
        var results: boolean[] = [];
        let scoData: Sco2004Data = this.scosData.scos[activity.identifier] as Sco2004Data;
        for (i = 0; i < rule.conditions.length; i++) {
            let result: boolean = null;

            if (rule.conditions[i].condition == "satisfied") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let status: string = this.scosData.globalObjectives[globalObjectiveId].successStatus;
                    if (status == "passed") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "objectiveStatusKnown") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let status: string = this.scosData.globalObjectives[globalObjectiveId].successStatus;
                    if (status == "passed" || status == "failed") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "objectiveMeasureKnown") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let measure: string = this.scosData.globalObjectives[globalObjectiveId].scaled;
                    if (measure != "") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "objectiveMeasureGreaterThan") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let measure: string = this.scosData.globalObjectives[globalObjectiveId].scaled;
                    if (measure != "") {
                        let measureVal: number = parseFloat(measure as string);
                        if (measureVal > rule.conditions[i].measureThreshold) {
                            result = true;
                        }
                        else {
                            result = false;
                        }
                    }
                }
            }
            else if (rule.conditions[i].condition == "objectiveMeasureLessThan") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let measure: string = this.scosData.globalObjectives[globalObjectiveId].scaled;
                    if (measure != "") {
                        let measureVal: number = parseFloat(measure as string);
                        if (measureVal < rule.conditions[i].measureThreshold) {
                            result = true;
                        }
                        else {
                            result = false;
                        }
                    }
                }
            }
            else if (rule.conditions[i].condition == "completed") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                    if (status == "completed") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "activityProgressKnown") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                    if (status == "completed" || status == "incomplete") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "attempted") {
                let globalObjectiveId: string = this.globalObjectiveIdFromLocalObjectiveId(activity, rule.conditions[i].referencedObjective);
                if (globalObjectiveId != "") {
                    let status: string = this.scosData.globalObjectives[globalObjectiveId].completionStatus;
                    if (status == "completed" || status == "incomplete") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                }
            }
            else if (rule.conditions[i].condition == "attemptLimitExceeded") {
                // TODO
            }
            else if (rule.conditions[i].condition == "timeLimitExceeded") {
                // TODO
            }
            else if (rule.conditions[i].condition == "outsideAvailableTimeRange") {
                // TODO
            }
            else if (rule.conditions[i].condition == "always") {
                result = true;
            }

            // TODO: Const this.
            if (rule.conditions[i].operator == "not") {
                if (result === true) {
                    result = false;
                }
                else if (result === false) {
                    result = true;
                }
            }
            results.push(result);
        }
        if (results.length > 0) {
            if (rule.conditionCombination == "any") {
                for (i = 0; i < results.length; i++) {
                    if (results[i] === true) {
                        return "true";
                    }
                }
                return "false";
            }
            else if (rule.conditionCombination == "all") {
                for (i = 0; i < results.length; i++) {
                    if (results[i] !== true) {
                        return "false";
                    }
                }
                return "true";
            }
        }
        return "unknown";
    }

    // Terminate Descendent Attempts Process [UP.3]
    up_3(activity: ScoNavItem2004): void {
        console.log("NOT IMPLEMENTED UP_3");
        alert("NOT IMPLEMENTED UP_3");
        return null;
        // TODO
    }

    // End Attempt Process [UP.4]
    up_4(activity: ScoNavItem2004): void {
        // 1
        if (activity.isLeaf) {
            // 1.1
            if (activity.sequence.deliveryControls.tracked) {
                // 1.1.1
                if (this.scosData.scos[activity.identifier].get("cmi.exit") != "suspend") {
                    // 1.1.1.1
                    if (!activity.sequence.deliveryControls.completionSetByContent) {
                        // 1.1.1.1.1.1 - 1.1.1.1.1.2
                        this.scosData.scos[activity.identifier].set("cmi.completion_status", "completed")
                    }

                    // 1.1.1.2
                    if (!activity.sequence.deliveryControls.objectiveSetByContent) {
                        // ADL Note: Although this element is defined in the IMS SS Sequencing Definition Model, it cannot be directly set or altered. The XML element <primaryObjective> in a SCORM Content Package (see the SCORM CAM Book, Section 5.1.7.1: <primaryObjective> Element) is used to indicate the single objective that contributes to rollup. For an activity, only the objective defined as the <primaryObjective> will contribute to rollup (have Objective Contributes to Rollup equal to True).
                        // 1.1.1.2.1.1
                        if (activity.sequence.primaryObjective) {
                            // 1.1.1.2.1.1.1
                            if (this.scosData.scos[activity.identifier].get("cmi.objectives.0.success_status") == "unknown") {
                                // 1.1.1.2.1.1.1.1 - 1.1.1.2.1.1.1.2
                                this.scosData.scos[activity.identifier].set("cmi.objectives.0.success_status", "passed");
                            }
                        }
                    }
                }
            }
        }
        else {
            // TODO Step 2
            console.log("NOT IMPLEMENTED UP_4");
            alert("NOT IMPLEMENTED UP_4");
        }

        this.currentActivityIsActive = false;
        this.rb_1_5(activity);
    }

    // Check Activity Process [UP.5]
    up_5(activity: ScoNavItem2004): boolean {
        let ruleCheck = this.up_2(activity, [RuleActions.Disabled]);
        console.log("up_5, up2 ruleCheck = ", ruleCheck);
        if (ruleCheck != null) {
            return true;
        }
        return this.up_1(activity);
    }

}
