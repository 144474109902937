export class ScormBaseDataModel {
    protected validElements:any;
    protected validRegexElements:any

    matchElement(element:string):any {
        if (this.validElements[element]) {
            return this.validElements[element];
        }
        let regexKeys = Object.keys(this.validRegexElements);
        for (var i=0; i < regexKeys.length; i++) {
            if (element.match(new RegExp("^" + regexKeys[i] + "$"))) {
                return this.validRegexElements[regexKeys[i]];
            }
        }
        return null;
    }

    validateDecimal(value:string, min:number, max:number):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).

        // let res = value.match(/^-?([0-9]{0,3})(\.[0-9]{1,2})?$/);
        // if (!res || !res.length) {
        //     return false;
        // }

        let v:number = parseInt(value, 10);
        if (isNaN(v)) {
            return false;
        }

        if (typeof min !== undefined) {
            if (v < min) {
                return false;
            }
        }
        if (typeof max !== undefined) {
            if (v > max) {
                return false;
            }
        }
        return true;
    }

    validateFeedback(value:string):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).
        let res = value.match(/^.{0,255}/);
        if (res && res.length) {
            return true;
        }
        return false;
    }

    validateIdentifier(value:string, useLooseValidation:boolean=false):boolean {
        if (useLooseValidation) {
            let res = value.match(/^.+$/);
            if (res && res.length) {
                return true;
            }
        }
        else {
            let res = value.match(/^\w{1,255}$/);
            if (res && res.length) {
                return true;
            }
        }
        return false;
    }

    validateIntegerSigned(value:string, min:number, max:number):boolean {
        let v:number = parseInt(value, 10);
        if (isNaN(v)) {
            return false;
        }
        if (v <= -32768 || v >= 32768) {
            return false;
        }
        if (typeof min !== undefined) {
            if (v < min) {
                return false;
            }
        }
        if (typeof max !== undefined) {
            if (v > max) {
                return false;
            }
        }
        return true;
    }

    validateIntegerUnsigned(value:string, min:number, max:number):boolean {
        let v:number = parseInt(value, 10);
        if (isNaN(v)) {
            return false;
        }
        if (v <= 0 || v >= 65536) {
            return false;
        }
        if (typeof min !== undefined) {
            if (v < min) {
                return false;
            }
        }
        if (typeof max !== undefined) {
            if (v > max) {
                return false;
            }
        }
        return true;
    }

    validateOneOf(oneOf:string[], value:string):boolean {
        let i:number;
        for (i=0; i < oneOf.length; i++) {
            if (oneOf[i] === value) {
                return true;
            }
        }
        return false;
    }

    validateString(value:string, length:number):boolean {
        return value.length <= length;
    }


}
