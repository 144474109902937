export class DomRender {
    static hasClass(ele: any, cls: any) {
        return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    }

    static addClass(ele: any, cls: any) {
        if (!this.hasClass(ele, cls)) ele.className += " " + cls;
    }

    static removeClass(ele: any, cls: any) {
        if (this.hasClass(ele, cls)) {
            var reg: any = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            ele.className = ele.className.replace(reg, ' ');
        }
    }

}