import {SSLAConfiguration} from "./config";
import {CourseTypeDetector} from "./detector";

export abstract class SSLAPlugin {
    public config: SSLAConfiguration = null;
    public ctd: CourseTypeDetector = null;
    public checked: boolean = false;
    public contents: any;
    abstract finish(): void;
    abstract navigate(type: string): void;
    abstract navigateByIdentifier(id: string): void;
    abstract getMainAsset(callback: Function): void;
    abstract start(): void;
}