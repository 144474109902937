import {SSLAConfiguration} from "./config";
import {Manifest} from "./manifest";
import {ScoNavItem} from "./manifest";
import {Resource} from "./manifest";
import {SSLAApiEvents} from "./events";
import {DomRender} from "./render";


export class TableOfContents {
    protected config: SSLAConfiguration;
    protected events: SSLAApiEvents;
    public currentScoId: string;

    constructor(config: SSLAConfiguration, events: SSLAApiEvents) {
        this.config = config;
        this.events = events;
        this.events.statusChange.add(this.statusChanged, this);
    }

    render(resourcesByIref: { [ref: string]: Resource; }, itemTree: ScoNavItem) {
        return this._render(resourcesByIref, itemTree);
    }

    updateCurrentActivity(id: string) {
        let i: number;
        let activityId: string = 'toc-' + id;
        // @ts-ignore
        let curList: HTMLElement[] = getElementsByClassName("current", "", document.getElementById("toc"));

        for (i = 0; i < curList.length; i++) {
            DomRender.removeClass(curList[i], "current");
            DomRender.removeClass(curList[i], "current-reenterable");
        }

        var el: HTMLElement = document.getElementById(activityId);
        DomRender.addClass(el, "current");
        if (!this.config.reenterActivity()) {
            DomRender.addClass(el, "current-reenterable");
        }
    }

    updateActivityStatus(id: string, statusArray: string[]) {
        var activityId: string = 'toc-' + id,
            newClass: string = "";

        if (statusArray.length === 0) {
            // An empty lesson status string on statusChange occurs bcs. we're updating the navigation before the data is loaded
            // This only happens when completion is also empty or does not exist
            return;
        }

        var status: string = "";

        if (this.config.statusVisualOverride() == "custom") {
           status = this.config.statusVisualOverrideFn()(statusArray);
        }
        else if (this.config.statusVisualOverride() == "default") {
           status = this.defaultVisualOverride(statusArray);
        }
        else {
            console.log("Error: Invalid statusVisualOverride value.");
        }

        switch (status) {
            case "completed":
            case "browsed":
                newClass = "completed";
                break;
            case "incomplete":
                newClass = "incomplete";
                break;
            case "unknown":
                newClass = "unknown";
                break;
            case "passed":
                newClass = "passed";
                break;
            case "failed":
                newClass = "failed";
                break;
            case "not attempted":
            case undefined:
                newClass = "not_attempted";
                break;
        }
        if (newClass && document.getElementById(activityId)) {
            let el:Element = document.getElementById(activityId);
            DomRender.removeClass(el, "completed");
            DomRender.removeClass(el, "browsed");
            DomRender.removeClass(el, "incomplete");
            DomRender.removeClass(el, "unknown");
            DomRender.removeClass(el, "passed");
            DomRender.removeClass(el, "failed");
            DomRender.removeClass(el, "not_attempted");
            DomRender.addClass(el, newClass);
        }
    }

    defaultVisualOverride(statusArray: string[]) {
        //Default priority (greatest to least): failed, passed, completed, browsed, incomplete, not attempted
        if (statusArray.indexOf("failed") > -1) {
            return "failed";
        }
        else if (statusArray.indexOf("passed") > -1) {
            return "passed";
        }
        else if (statusArray.indexOf("completed") > -1) {
            return "completed";
        }
        else if (statusArray.indexOf("browsed") > -1) {
            return "browsed";
        }
        else if (statusArray.indexOf("incomplete") > -1) {
            return "incomplete";
        }
        else if (statusArray.indexOf("not attempted") > -1 || statusArray.indexOf("not_attempted") > -1) {
            return "not_attempted";
        }

        return status;
    }

    updateSelectability(item: ScoNavItem) {
        // TODO Restore this in a way that doesn't break the entire subtree under it.
        let el: HTMLElement = document.getElementById("toc-" + item.identifier);
        if (item.isSelectable) {
            DomRender.removeClass(el, "disable");
        }
        else {
            DomRender.addClass(el, "disable");
        }
    }

    protected statusChanged(statusArray: string[]) {
        this.updateActivityStatus(this.currentScoId, statusArray);
        this.updateCurrentActivity(this.currentScoId);
    }

    protected _render(resourcesByIref: { [ref: string]: Resource; }, item: ScoNavItem) {
        var s: string = '<ul>', i: number, href: string = '', klass: string = '', doClick: string = "", canNavigate: boolean = false;
        if (item.hasOwnProperty('identifierRef')) {
            if (resourcesByIref.hasOwnProperty(item.identifierRef)) {
                href = resourcesByIref[item.identifierRef].href;
                canNavigate = true;
            }
        }
        if (item.hasOwnProperty('identifier')) {
            klass = item.identifier;
        }
        s += '<li id="toc-' + klass + '">';
        if (href && canNavigate) {
            doClick = ' onclick="ssla.navigateByIdentifier(\'' + item.identifier + '\'); return false;"';
            s += '<a href="#" ' + doClick + '>' + item.title + '</a>';
        }
        else {
            s += '<div style="font-weight: bold">' + item.title + '</div>';
        }
        for (i = 0; i < item.children.length; i++) {
            s += this._render(resourcesByIref, item.children[i]);
        }
        s += '</li>';
        s += '</ul>';
        return s;
    }
}
