import {Scorm12DataModel} from "./dm12";
import * as Sco from "../sco";
import ScoVar = Sco.ScoVar;
import {SSLAApiEvents} from "../events";
import {SSLAConfiguration} from "../config";
import * as mathier from "../mathier";

const API_FALSE = "false";
const API_TRUE = "true";


export class Sco12Data extends Sco.ScoData {
    interactionCount: number = 0;
    objectiveCount: number = 0;
    interactionObjectiveCount: { [interactionIndex: string]: number; } = {};
    correctResponsesCount: { [interactionIndex: string]: number; } = {};

    fillDefaults() {
        this.data["cmi.core.lesson_status"] = new ScoVar("not attempted");
        this.data["cmi.suspend_data"] = new ScoVar("");
        this.data["cmi.core.lesson_location"] = new ScoVar("");
        this.data["cmi.core.session_time"] = new ScoVar("0000:00:00");
        this.data["cmi.core.total_time"] = new ScoVar("0000:00:00");
        // This is to initialize the data model element for the first attempt only, after the first attempt it will no longer be ab-initio
        this.data["cmi.core.entry"] = new ScoVar("ab-initio");
        this.data["cmi.core.score.raw"] = new ScoVar("");
        this.data["cmi.core.score.min"] = new ScoVar("");
        this.data["cmi.core.score.max"] = new ScoVar("");
        this.data["cmi.comments"] = new ScoVar("");
        this.data["cmi.comments_from_lms"] = new ScoVar("No comment");
        this.data["cmi.student_preference.audio"] = new ScoVar("");
        this.data["cmi.student_preference.language"] = new ScoVar("");
        this.data["cmi.student_preference.speed"] = new ScoVar("");
        this.data["cmi.student_preference.text"] = new ScoVar("");

        this.data["cmi.core.student_name"] = new ScoVar("");
        this.data["cmi.core.student_id"] = new ScoVar("");
        this.data["cmi.launch_data"] = new ScoVar("");
        this.data["cmi.student_data.mastery_score"] = new ScoVar("");
        this.data["cmi.student_data.max_time_allowed"] = new ScoVar("");
        this.data["cmi.student_data.time_limit_action"] = new ScoVar("");
        this.data["cmi.core.credit"] = new ScoVar("credit");
        this.data["cmi.core.lesson_mode"] = new ScoVar("normal");
    }

    canImportKey(key: string): boolean {
        if (key == "cmi.core.session_time") {
            return false;
        }
        return true;
    }

    getNormalizedScore(): string {
        let rawS: string = <string>this.data["cmi.core.score.raw"].get();
        if (rawS === "") {
            return "";
        }

        let minS: string = <string>this.data["cmi.core.score.min"].get();
        let maxS: string = <string>this.data["cmi.core.score.max"].get();

        let min: number = 0;
        let max: number = 100;
        let raw: number;
        raw = Number(rawS);

        if (minS != "") {
            min = Number(minS);
        }
        if (maxS != "") {
            max = Number(maxS);
        }
        return "" + mathier.round((raw / (max - min)) * 100., 3);
    }

    getScore(): string {
        return <string>this.data["cmi.core.score.raw"].get();
    }

    getSessionTime(): string {
        return <string>this.data["cmi.core.session_time"].get();
    }

    getStatus(): string {
        return <string>this.data["cmi.core.lesson_status"].get();
    }

    getTotalTime(): string {
        return <string>this.data["cmi.core.total_time"].get();
    }

    populateMaxes(): void {
        let i: number,
            dme: string;
        this.interactionCount = this.getMaxIndex("cmi.interactions.") + 1;
        this.updateInteractionCountDme();
        this.objectiveCount = this.getMaxIndex("cmi.objectives.") + 1;
        this.updateObjectiveCountDme();
        for (i = 0; i < this.interactionCount; i++) {
            dme = "cmi.interactions." + i + ".objectives.";
            this.interactionObjectiveCount[i] = this.getMaxIndex(dme) + 1;
            this.updateInteractionObjectiveCountDme(i);
        }
        for (i = 0; i < this.interactionCount; i++) {
            dme = "cmi.interactions." + i + ".correct_responses.";
            this.correctResponsesCount[i] = this.getMaxIndex(dme) + 1;
            this.updateCorrectResponsesCountDme(i);
        }
    }

    set(dme: string, value: string) {
        super.set(dme, value);
        let dmeParts: string[] = dme.split(".");
        if (dmeParts[1] === "interactions") {
            this.interactionCount = Math.max(Number(dmeParts[2]) + 1, this.interactionCount);
            this.updateInteractionCountDme();
            if (dmeParts[3] === "objectives") {
                this.interactionObjectiveCount[dmeParts[2]] = Math.max(Number(dmeParts[4]) + 1, this.interactionObjectiveCount[dmeParts[2]]);
                this.updateInteractionObjectiveCountDme(Number(dmeParts[2]));
            }
            else {
                // Make sure that the counts are always updated if a new item has been added.
                this.updateInteractionObjectiveCountDme(Number(dmeParts[2]));
            }

            if (dmeParts[3] === "correct_responses") {
                this.correctResponsesCount[dmeParts[2]] = Math.max(Number(dmeParts[4]) + 1, this.correctResponsesCount[dmeParts[2]]);
                this.updateCorrectResponsesCountDme(Number(dmeParts[2]));
            }
            else {
                // Make sure that the counts are always updated if a new item has been added.
                this.updateCorrectResponsesCountDme(Number(dmeParts[2]));
            }
        }
        else if (dmeParts[1] === "objectives") {
            this.objectiveCount = Math.max(Number(dmeParts[2]) + 1, this.objectiveCount);
            this.updateObjectiveCountDme();
        }
    }

    protected updateInteractionCountDme() {
        this.data["cmi.interactions._count"] = new ScoVar(this.interactionCount);
    }

    protected updateObjectiveCountDme() {
        this.data["cmi.objectives._count"] = new ScoVar(this.objectiveCount);
    }

    protected updateInteractionObjectiveCountDme(index: number) {
        if (!this.interactionObjectiveCount[index]) {
            this.interactionObjectiveCount[index] = 0;
        }
        this.data["cmi.interactions." + index + ".objectives._count"] = new ScoVar(this.interactionObjectiveCount[index]);
    }

    protected updateCorrectResponsesCountDme(index: number) {
        if (!this.correctResponsesCount[index]) {
            this.correctResponsesCount[index] = 0;
        }
        this.data["cmi.interactions." + index + ".correct_responses._count"] = new ScoVar(this.correctResponsesCount[index]);
    }
}


export class AllScos12Data extends Sco.AllScosData {
    addTimes(one: string, two: string): string {
        return Scorm12TimeManager.addTime(one, two);
    }

    makeNewScoData(): Sco.ScoData {
        return new Sco12Data();
    }
}


class Scorm12TimeManager {
    protected static toDuration(value: string): number {
        let res = value.match(/^([0-9]{1,4}):([0-9]{1,4}):([0-9]{1,4})(\.[0-9]+)?$/);
        let x = (parseInt(res[1]) * 3600) + (parseInt(res[2]) * 60) + parseInt(res[3]);
        if (res.length > 4 && res[4]) {
            x += parseFloat(res[4]);
        }
        return x;
    }

    public static addTime(one: string, two: string): string {
        if (!one) {
            return "";
        }
        if (!two) {
            two = "0000:00:00";
        }
        let sum = this.toDuration(one) + this.toDuration(two);
        let hours = Math.floor(sum / 3600);
        sum = sum - (hours * 3600);
        let minutes = Math.floor(sum / 60);
        sum = sum - (minutes * 60);
        let seconds = Math.floor(sum);
        let ms = sum - seconds;
        let timeString = this.padTime(hours.toString(), 2) + ":" +
            this.padTime(minutes.toString(), 2) + ":" +
            this.padTime(seconds.toString(), 2);
        if (ms) {
            let msBit = ms.toFixed(2);
            if (msBit.substr(0, 1) == "0") {
                msBit = msBit.substr(1);
            }
            if (msBit.substr(msBit.length - 1) == "0") {
                msBit = msBit.substr(0, msBit.length - 1);
            }
            timeString += msBit;
        }
        return timeString;
    }

    private static padTime(str: string, padValue: number) {
        let zeroes: string = "0000";
        if (str === "") {
            return zeroes.substr(0, padValue);
        }

        if (str.length < padValue) {
            let num: number = padValue - str.length;
            str = zeroes.substr(0, num) + str;
            return str;
        }

        return str;
    }
}


export class Scorm12Api {
    protected config: SSLAConfiguration;
    protected dataModel: Scorm12DataModel;
    public sco: Sco12Data;
    public scoName: string;
    protected error: string = "0";
    protected events: SSLAApiEvents = null;
    protected saveCallbacks: any[] = [];

    constructor(scoName: string, scoData: Sco12Data, events: SSLAApiEvents, config: SSLAConfiguration) {
        this.scoName = scoName;
        this.sco = scoData;
        this.dataModel = new Scorm12DataModel();
        this.events = events;
        this.config = config;

        // Initialize data model properties.
        this.sco.start = false;
        this.sco.end = false;
    }

    private storeTime(session_time: string) {
        if (!session_time) {
            return;
        }
        let timeString: string = Scorm12TimeManager.addTime(session_time, <string>this.sco.get("cmi.core.total_time"));
        this.sco.set("cmi.core.total_time", timeString);
    }

    public addSaveCallback(callbackFn: any, scope: any) {
        this.saveCallbacks.push([callbackFn, scope]);
    }

    protected callSave(eventType: string, becauseFinish: boolean) {
        let i: number;
        for (i = 0; i < this.saveCallbacks.length; i++) {
            this.saveCallbacks[i][0].call(this.saveCallbacks[i][1], "", {
                eventType: eventType,
                scoName: this.scoName,
                sco: this.sco,
                forceSync: becauseFinish
            });
        }
    }

    protected initialize(empty: string): string {
        if (empty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        // Don't call initialize twice.
        if (this.sco.start) {
            this.error = "101";
            return API_FALSE;
        }
        // Don't call initialize after finish.
        if (this.sco.end) {
            this.error = "101";
            return API_FALSE;
        }

        this.sco.start = true;
        this.error = "0";

        if (this.config.startIncomplete() && this.sco.get("cmi.core.lesson_status") == "not attempted") {
            this.sco.set("cmi.core.lesson_status", "incomplete");
        }

        // TODO: Support config.setInitializeToLMS.
        // TODO: Offer immediate save?
        return API_TRUE;
    }

    protected finish(requiredEmpty: string): string {
        if (requiredEmpty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        if (!this.sco.start) {
            this.error = "301";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "301";
            return API_FALSE;
        }

        if (<string>this.sco.get("cmi.core.lesson_status") == "not attempted") {
            if (this.config.statusAllowCompletionOnUnsetFinish()) {
                this.sco.set("cmi.core.lesson_status", "completed");
                this.events.statusChange.dispatch(["completed"]);
            }
        }

        if (this.config.masteryScoreMode() == "always" ||
            (this.config.masteryScoreMode() == "after_completion" && this.sco.get("cmi.core.lesson_status") == "completed")) {
            // Update course status if a mastery score exists and the configuration option allows for it.
            let masteryScore: string = <string>this.sco.get("cmi.student_data.mastery_score");
            if (masteryScore !== "") {
                let rawScore: string = <string>this.sco.get("cmi.core.score.raw");
                if (rawScore !== "") {
                    if (parseInt(rawScore, 10) >= parseInt(masteryScore, 10)) {
                        this.sco.set("cmi.core.lesson_status", "passed");
                        this.events.statusChange.dispatch(["passed"]);
                    }
                    else {
                        this.sco.set("cmi.core.lesson_status", "failed");
                        this.events.statusChange.dispatch(["failed"]);
                    }
                }
            }
        }

        let sessionTime: string = <string>this.sco.get("cmi.core.session_time");
        this.storeTime(sessionTime);

        // Update entry status based on current exit.
        if (this.sco.get("cmi.core.exit") === "suspend") {
            this.sco.set("cmi.core.entry", "resume");
        }
        else {
            this.sco.set("cmi.core.entry", "");
        }

        // TODO: Support config.setFinishToLMS.
        this.sco.end = true;
        this.error = "0";
        this.callSave("finish", true);
        return API_TRUE;
    }

    protected getValue(dme: string): string | number {
        if (!this.sco.start) {
            this.error = "301";
            return "";
        }
        if (this.sco.end) {
            this.error = "301";
            return "";
        }
        if (dme === undefined || dme === null) {
            this.error = "201";
            return API_FALSE;
        }
        dme = String(dme);

        // Ensure the element is in the SCORM data model.
        let dm = this.dataModel.matchElement(dme);
        if (!dm) {
            this.error = "201";
            return "";
        }
        // Ensure the element is not write-only.
        if (dm.d === "W") {
            this.error = "404";
            return "";
        }

        this.error = "0";
        if (dm.t === "Fixed") {
            return dm.v;
        }
        return this.sco.get(dme);
    }

    protected setValue(dme: string, value: string): string {
        let actuallySet: boolean = true;

        if (!this.sco.start) {
            this.error = "301";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "301";
            return API_FALSE;
        }
        if (dme === undefined || dme === null) {
            this.error = "201";
            return API_FALSE;
        }
        dme = String(dme);
        if (value === undefined || value === null) {
            value = "";
        }
        value = String(value);

        // Ensure the element is in the SCORM data model.
        let dm = this.dataModel.matchElement(dme);
        if (!dm) {
            this.error = "201";
            return API_FALSE;
        }
        // Ensure the element is not read-only.
        if (dm.d === "R") {
            this.error = "403";
            return API_FALSE;
        }

        // cmi.comments has to be concatenated to the existing value.
        if (dme === "cmi.comments") {
            value = this.sco.get("cmi.comments") + value;
        }

        let validResult: boolean;
        // Coerce into a string.
        let v: string = "" + value;
        if (dm.t === "CMIDecimal") {
            validResult = this.dataModel.validateDecimal(v, dm.min, dm.max);
        }
        else if (dm.t === "CMIFeedback") {
            validResult = this.dataModel.validateFeedback(v);
        }
        else if (dm.t === "CMIIdentifier") {
            validResult = this.dataModel.validateIdentifier(v, this.config.looseIdentifiers());
        }
        else if (dm.t === "CMIInteger") {
            validResult = this.dataModel.validateIntegerUnsigned(v, dm.min, dm.max);
        }
        else if (dm.t === "CMISInteger") {
            validResult = this.dataModel.validateIntegerSigned(v, dm.min, dm.max);
        }
        else if (dm.t === "OneOf") {
            validResult = this.dataModel.validateOneOf(dm.c, v);
        }
        else if (dm.t === "CMIResult") {
            validResult = this.dataModel.validateResult(v);
        }
        else if (dm.t === "CMIString") {
            validResult = this.dataModel.validateString(v, dm.l);
        }
        else if (dm.t === "CMITime") {
            validResult = this.dataModel.validateTime(v);
        }
        else if (dm.t === "CMITimespan") {
            validResult = this.dataModel.validateTimespan(v);
        }
        else {
            console.log("Unrecognized data validation type.", dm.t);
            this.error = "101";
            return API_FALSE;
        }
        if (!validResult) {
            this.error = "405";
            return API_FALSE;
        }

        // Check the array types to make sure they don't overflow bounds.
        let dmeParts: string[] = dme.split(".");
        if (dmeParts[1] === "interactions") {
            if (Number(dmeParts[2]) > this.sco.interactionCount) {
                this.error = "201";
                return API_FALSE;
            }
            if (dmeParts[3] === "objectives") {
                if (Number(dmeParts[4]) > this.sco.interactionObjectiveCount[dmeParts[2]]) {
                    this.error = "201";
                    return API_FALSE;
                }
            }
            if (dmeParts[3] === "correct_responses") {
                if (Number(dmeParts[4]) > this.sco.correctResponsesCount[dmeParts[2]]) {
                    this.error = "201";
                    return API_FALSE;
                }
            }
        }
        if (dmeParts[1] === "objectives") {
            if (Number(dmeParts[2]) > this.sco.objectiveCount) {
                this.error = "201";
                return API_FALSE;
            }
        }

        // Custom score handling logic.
        if (dme == "cmi.core.score.raw") {
            let rawScore: string = <string>this.sco.get("cmi.core.score.raw");
            let rawStatus: string = <string>this.sco.get("cmi.core.lesson_status");

            if (
                rawScore !== "" &&
                (
                    (rawStatus == "completed" && !this.config.scoreAllowChangeAfterCompleted()) ||
                    (rawStatus == "passed" && !this.config.scoreAllowChangeAfterPassed()) ||
                    (rawStatus == "failed" && !this.config.scoreAllowChangeAfterFailed())
                )
            ) {
                // Don't set the score if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
            else if (!this.config.scoreAllowReduce()) {
                if (rawScore !== "" && Number(rawScore) > Number(value)) {
                    // Don't set the score if it would be reduced.
                    actuallySet = false;
                }
            }
        }

        // Custom status handling logic.
        if (dme == "cmi.core.lesson_status") {
            let rawStatus: string = <string>this.sco.get("cmi.core.lesson_status");
            if (
                (rawStatus == "completed" && !this.config.statusAllowChangeAfterCompleted()) ||
                (rawStatus == "passed" && !this.config.statusAllowChangeAfterPassed()) ||
                (rawStatus == "failed" && !this.config.statusAllowChangeAfterFailed())
            ) {
                // Don't set the status if a terminal status has occurred and the paired configuration option is set.
                actuallySet = false;
            }
        }

        // TODO: All the rest of the set validation.

        this.error = "0";
        if (actuallySet) {
            this.sco.set(dme, v);
            if (dme === "cmi.core.lesson_status") {
                this.events.statusChange.dispatch([v]);
            }
            if (dme == "cmi.core.score.raw" || dme == "cmi.core.score.min" || dme == "cmi.core.score.max") {
                this.events.scoreChange.dispatch();
            }

            if (this.config.saveDataOnSetValue()) {
                this.callSave("setValue", false);
            }
        }
        return API_TRUE;
    }

    protected commit(requiredEmpty: string): string {
        if (requiredEmpty !== "") {
            this.error = "201";
            return API_FALSE;
        }
        if (!this.sco.start) {
            this.error = "301";
            return API_FALSE;
        }
        if (this.sco.end) {
            this.error = "301";
            return API_FALSE;
        }

        this.error = "0";
        if (this.config.saveDataOnCommit()) {
            this.callSave("commit", false);
        }
        return API_TRUE;
    }

    protected getLastError() {
        return this.error;
    }

    protected getErrorString(errorCode: string): string {
        let errors: { [error: string]: string; } = {
            "0": "No error",
            "101": "General Exception",
            "201": "Invalid argument error",
            "202": "Element cannot have children",
            "203": "Element not an array.",
            "301": "Not initialized",
            "401": "Not implemented error",
            "402": "Invalid set value, element is a keyword",
            "403": "Element is read only",
            "404": "Element is write only",
            "405": "Incorrect Data Type"
        };
        if (errors[errorCode]) {
            return errors[errorCode];
        }
        return "";
    }

    protected getDiagnostic(errorCode: string): string {
        return this.error;
    }

    public LMSInitialize(empty: string): string {
        let ret: string;
        this.events.preInitialize.dispatch("LMSInitialize", [empty]);
        ret = this.initialize(empty);
        this.events.postInitialize.dispatch("LMSInitialize", [empty], ret, this.error);
        return ret;
    }

    public LMSFinish(empty: string): string {
        let ret: string;
        this.events.preFinish.dispatch("LMSFinish", [empty]);
        ret = this.finish(empty);
        this.events.postFinish.dispatch("LMSFinish", [empty], ret, this.error);
        return ret;
    }

    public LMSGetValue(dme: string): string | number {
        let ret: string | number;
        this.events.preGetValue.dispatch("LMSGetValue", [dme]);
        ret = this.getValue(dme);
        this.events.postGetValue.dispatch("LMSGetValue", [dme], ret, this.error);
        return ret;
    }

    public LMSSetValue(dme: string, value: string): string {
        let ret: string;
        this.events.preSetValue.dispatch("LMSSetValue", [dme, value]);
        ret = this.setValue(dme, value);
        this.events.postSetValue.dispatch("LMSSetValue", [dme, value], ret, this.error);
        return ret;
    }

    public LMSCommit(requiredEmpty: string): string {
        let ret: string;
        this.events.preCommit.dispatch("LMSCommit", [requiredEmpty]);
        ret = this.commit(requiredEmpty);
        this.events.postCommit.dispatch("LMSCommit", [requiredEmpty], ret, this.error);
        return ret;
    }

    public LMSGetLastError(): string {
        let ret: string;
        this.events.preGetLastError.dispatch("LMSGetLastError", []);
        ret = this.getLastError();
        this.events.postGetLastError.dispatch("LMSGetLastError", [], ret, this.error);
        return ret;
    }

    public LMSGetErrorString(errorCode: string): string {
        let ret: string;
        this.events.preGetErrorString.dispatch("LMSGetErrorString", [errorCode]);
        ret = this.getErrorString(errorCode);
        this.events.postGetLastError.dispatch("LMSGetErrorString", [errorCode], ret, this.error);
        return ret;
    }

    public LMSGetDiagnostic(errorCode: string): string {
        let ret: string;
        this.events.preGetDiagnostic.dispatch("LMSGetDiagnostic", [errorCode]);
        ret = this.getDiagnostic(errorCode);
        this.events.postGetDiagnostic.dispatch("LMSGetDiagnostic", [errorCode], ret, this.error);
        return ret;
    }
}
