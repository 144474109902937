import {SSLAConfiguration} from "./config";
import * as Path from "./path";
import {SSLAPlugin} from "./plugin";

declare var reqwest: any;

/**
 * Determine what type of course this is based on what files are available.
 */
export class CourseTypeDetector {
    callbackOnDecision: Function;
    callbackAttempted: boolean = false;
    availablePlugins:{[id: string]: SSLAPlugin};
    isSCORM: boolean = false;
    scormContents: any;

    attemptPlugin(availablePlugins:{[id: string]: SSLAPlugin}): void {
        let myFunc =  this.callbackOnDecision;
        for (let p in availablePlugins) {
            let callback = function callbackOwner() { return myFunc(p); };
            availablePlugins[p].getMainAsset(callback);
            this.finishIfAllChecked();
        }
    }

    protected finishIfAllChecked(): void {
        if (this.callbackAttempted) {
            return;
        }

        // TODO Do we need a priority chain?
        let allChecked = true;

        for (let p in this.availablePlugins) {
            allChecked = allChecked ? this.availablePlugins[p].checked : allChecked;
        }

        if (allChecked) {
            this.callbackOnDecision("");
        }
    }

    identify(config: SSLAConfiguration, availablePlugins:{[id: string]: SSLAPlugin}, callbackOnDecision: Function): void {
        this.callbackOnDecision = callbackOnDecision;
        this.availablePlugins = availablePlugins;

        this.attemptPlugin(availablePlugins);
    }
}
