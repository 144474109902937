import * as Scorm2004Api from "./api2004";
import {ScoNavItem, Manifest, ManifestVersionChecker} from "../manifest";
export {UrlVarService} from "../url";
import {ScormPluginBase} from "../scorm/plugin";
import {Manifest2004} from "./manifest2004";
import {Request, NavActions, RequestCategory, SequencingProcess2004} from "./sn";
import {AllScos2004Data} from "./api2004";
import * as Path from "../path";

declare var reqwest: any;


export class Scorm2004Plugin extends ScormPluginBase {
    public API_1484_11: Scorm2004Api.Scorm2004Api = null;
    public sequencing: SequencingProcess2004 = null;

    createManifestClass(): Manifest {
        let man: Manifest;
        if (this.mvc.lastError != "") {
            return null;
        }

        if (this.mvc.schemaVersionMain == "2004") {
            man = new Manifest2004();
        }
        else {
            return null;
        }
        man.schemaVersionMain = this.mvc.schemaVersionMain;
        man.schemaVersionSub = this.mvc.schemaVersionSub;
        man.rawManifest = this.mvc.rawManifest;
        return man;
    }

    initializeApi(scoNav: ScoNavItem): void {
        if (this.manifest.schemaVersionMain === "2004") {
            let i = this.scosData.scos[scoNav.identifier];
            i.set("cmi.learner_id", this.config.studentId());
            i.set("cmi.learner_name", this.config.studentName());
            i.set("cmi.launch_data", scoNav.dataFromLms);
            i.set("cmi.max_time_allowed", scoNav.maxTimeAllowed);
            i.set("cmi.time_limit_action", scoNav.timeLimitAction);
            i.set("cmi.credit", this.config.getCredit());
            i.set("cmi.mode", this.config.getMode());

            this.API_1484_11 = new Scorm2004Api.Scorm2004Api(scoNav.identifier, (<Scorm2004Api.Sco2004Data>this.scosData.scos[scoNav.identifier]), this.events, this.config);
            this.API_1484_11.addSaveCallback(this.storage.save, this.storage);
            (<any>window).API_1484_11 = this.API_1484_11;
        }
        else {
            alert("Manifest schema version unrecognized: " + this.manifest.schemaVersionMain);
        }
    }

    endPreviousSco(): void {
        if (this.currentSco) {
            this.scosData.scos[this.currentSco.identifier].stopViewing();
            this.API_1484_11.Terminate("");
        }
    }

    initializeScosData(): void {
        let i: number;
        // Now that we know the version, build the data structure that uses it.
        this.scosData = new Scorm2004Api.AllScos2004Data();
        for (i = 0; i < this.manifest.scoPreOrderTraversal.length; i++) {
            this.scosData.scos[this.manifest.scoPreOrderTraversal[i].identifier] = new Scorm2004Api.Sco2004Data();
        }
    }

    initializeSequencing(): void {
        // TODO Determine sequencing mode by something more clever in the manifest as a default approach.
        this.sequencing = new SequencingProcess2004(this.manifest as Manifest2004, this.scosData as AllScos2004Data, this.events);

        // When attempting to return to last SCO on launch,
        // verify that the current SCO ID still exists in the version of the course that's trying to be loaded.
        // If it doesn't, we must fall through to a normal default launch.
        if (this.config.returnToLastActivityOnLaunch() && this.scosData.currentScoId && this.manifest.scosByIdentifiers[this.scosData.currentScoId]) {
            this.loadScoByIdentifier(this.scosData.currentScoId);
        }
        else if (this.config.autoLaunchFirstSco()) {
            this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Start)));
        }
    }

    getMainAsset(callbackOnDecision: Function) {
        let me: Scorm2004Plugin = this;
        reqwest({
            url: Path.join([this.config.courseDirectory(), this.config.scormManifestFilename()])
            , type: 'xml'
        })
            .then(function (resp: any) {
                me.contents = resp;
                me.ctd.callbackAttempted = true;
                callbackOnDecision();
            })
            .always(function (resp: any) {
                me.checked = true;
            });
    }

    navigate(type: string): void {
        if (type === "next") {
            this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Continue)));
        }
        else if (type === "previous") {
            // TODO FIX THIS
            // this.loadScoByIdentifier(this.sequencing.do(new Request(RequestCategory.Navigation, NavActions.Previous)));
        }
        else if (type === "repeat") {
            this.loadScoByIndex(this.currentScoIndex);
        }
    }
}

