import {ScormBaseDataModel} from "../dm";

export class Scorm2004DataModel extends ScormBaseDataModel {
    // Element definition: {d: read/write, t: data type, v: hardcoded value, c: list of choices, l: length, min: min, max: max}
    protected successStatusChoices:string[] = ["passed", "failed", "unknown"];
    protected completionStatusChoices:string[] = ["not attempted", "completed", "incomplete", "unknown"];
    protected navRequestChoices:string[] = ["", "continue", "previous", "choice", "jump", "exit", "exitAll", "abandon", "abandonAll", "suspendAll"];
    protected validElements:any = {
        "cmi._version": {d: "R", t: "Fixed", v: "2004 4th Edition"},
        "cmi.completion_status": {d: "RW", t: "OneOf", c: this.completionStatusChoices},
        "cmi.completion_threshold": {d: "RW", t: "CMIDecimal", min: 0, max: 1},
        "cmi.credit": {d: "R", t: "OneOf", c: ["credit", "no-credit"]},
        "cmi.entry": {d: "R", t: "OneOf", c: ["", "ab-initio", "resume"]},
        "cmi.exit": {d: "W", t: "OneOf", c: ["", "time-out", "logout", "suspend", "normal"]},
        "cmi.interactions._children": {d: "R", t: "Fixed", v: "id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description"},
        "cmi.interactions._count": {d: "R", t: "CMIInteger"},
        "cmi.objectives._children": {d: "R", t: "Fixed", v: "id,score,success_status,completion_status,description,progress_measure"},
        "cmi.objectives._count": {d: "R", t: "CMIInteger"},
        "cmi.launch_data": {d: "R", t: "CMIString", l: 4000},
        "cmi.learner_id": {d: "R", t: "CMIString", l: 4000},
        "cmi.learner_name": {d: "R", t: "CMIString", l: 250},
        "cmi.learner_preference._children": {d: "R", t: "Fixed", v: "audio_level,language,delivery_speed,audio_captioning"},
        "cmi.learner_preference.audio_level": {d: "RW", t: "CMIDecimal"},
        "cmi.learner_preference.language": {d: "RW", t: "CMIString", l: 250},
        "cmi.learner_preference.delivery_speed": {d: "RW", t: "CMIDecimal", min: 0},
        "cmi.learner_preference.audio_captioning": {d: "RW", t: "OneOf", c: ["-1", "0", "1"]},
        "cmi.location": {d: "RW", t: "CMIString", l: 1000},
        "cmi.max_time_allowed": {d: "R", t: "CMITimespan"},
        "cmi.mode": {d: "R", t: "OneOf", c: ["browse", "normal", "review"]},
        "cmi.progress_measure": {d: "RW", t: "CMIDecimal", min: 0, max: 1},
        "cmi.scaled_passing_score": {d: "RW", t: "CMIDecimal", min: -1, max: 1},
        "cmi.score._children": {d: "R", t: "Fixed", v: "scaled,raw,min,max"},
        "cmi.score.scaled": {d: "RW", t: "CMIDecimal", min: -1, max: 1},
        "cmi.score.max": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.score.min": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.score.raw": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi.session_time": {d: "W", t: "CMITimespan"},
        "cmi.success_status": {d: "RW", t: "OneOf", c: this.successStatusChoices},
        "cmi.suspend_data": {d: "RW", t: "CMIString", l: 64000},
        "cmi.time_limit_action": {d: "R", t: "OneOf", c: ["exit,message", "exit,no message", "continue,message", "continue,no message"]},
        "cmi.total_time": {d: "R", t: "CMITimespan"},
        "cmi._children": {d: "R", t: "Fixed", v: "_version,completion_status,completion_threshold,credit,entry,exit,interactions,objectives,launch_data,learner_id,learner_name,learner_preference,location,max_time_allowed,mode,progress_meausre,scaled_passing_score,score,session_time,success_status,suspend_data,time_limit_action,total_time"},

        "cmi.comments_from_learner._children": {d: "R", t: "Fixed", v: "comment,location.timestamp"},
        "cmi.comments_from_learner._count": {d: "R", t: "CMIInteger"},
        "cmi.comments_from_lms._children": {d: "R", t: "Fixed", v: "comment,location.timestamp"},
        "cmi.comments_from_lms._count": {d: "R", t: "CMIInteger"},

        // TODO: These aren't right, but we don't support sequencing so this is as good as it gets.
        "adl.nav.request": {d: "RW", t: "OneOf", c: this.navRequestChoices},
        "adl.nav.request_valid.continue": {d: "R", t: "Fixed", v: "true"},
        "adl.nav.request_valid.previous": {d: "R", t: "Fixed", v: "true"},
    };
    protected validRegexElements:any = {
        "cmi\\.comments_from_learner.\\d+\\.comment": {d: "RW", t: "CMIString", l: 4000},
        "cmi\\.comments_from_learner.\\d+\\.location": {d: "RW", t: "CMIString", l: 250},
        "cmi\\.comments_from_learner.\\d+\\.timestamp": {d: "RW", t: "CMITime2004"},
        "cmi\\.comments_from_lms.\\d+\\.comment": {d: "R", t: "CMIString", l: 4000},
        "cmi\\.comments_from_lms.\\d+\\.location": {d: "R", t: "CMIString", l: 250},
        "cmi\\.comments_from_lms.\\d+\\.timestamp": {d: "R", t: "CMITime2004"},

        "cmi\\.interactions.\\d+\\.id": {d: "W", t: "CMIIdentifier"},
        "cmi\\.interactions.\\d+\\.type": {d: "W", t: "OneOf", c: ["true-false", "choice", "fill-in", "long-fill-in", "matching", "performance", "sequencing", "likert", "numeric", "other"]},
        "cmi\\.interactions.\\d+\\.timestamp": {d: "W", t: "CMITime2004"},
        "cmi\\.interactions.\\d+\\.weighting": {d: "W", t: "CMIDecimal"},
        "cmi\\.interactions.\\d+\\.learner_response": {d: "W", t: "CMIFeedback"},
        "cmi\\.interactions.\\d+\\.result": {d: "W", t: "CMIResult"},
        "cmi\\.interactions.\\d+\\.latency": {d: "W", t: "CMITimespan"},
        "cmi\\.interactions.\\d+\\.description": {d: "W", t: "CMIString", l: 250},
        "cmi\\.interactions.\\d+\\.objectives\\._count": {d: "R", t: "CMIInteger"},
        "cmi\\.interactions.\\d+\\.objectives\\._children": {d: "R", t: "Fixed", v: "id"},
        "cmi\\.interactions.\\d+\\.objectives.\\d+\\.id": {d: "W", t: "CMIIdentifier"},
        "cmi\\.interactions.\\d+\\.correct_responses\\._count": {d: "R", t: "CMIInteger"},
        "cmi\\.interactions.\\d+\\.correct_responses\\._children": {d: "R", t: "Fixed", v: "pattern"},
        "cmi\\.interactions.\\d+\\.correct_responses.\\d+\\.pattern": {d: "W", t: "CMIFeedback"},
        "cmi\\.objectives.\\d+\\.id": {d: "RW", t: "CMIIdentifier"},
        "cmi\\.objectives.\\d+\\.score\\._children": {d: "R", t: "Fixed", v: "scaled,raw,min,max"},
        "cmi\\.objectives.\\d+\\.score\\.scaled": {d: "RW", t: "CMIDecimal", min: -1, max: 1},
        "cmi\\.objectives.\\d+\\.score\\.raw": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.score\\.max": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.score\\.min": {d: "RW", t: "CMIDecimal", min: 0, max: 100},
        "cmi\\.objectives.\\d+\\.success_status": {d: "RW", t: "OneOf", c: this.successStatusChoices},
        "cmi\\.objectives.\\d+\\.completion_status": {d: "RW", t: "OneOf", c: this.completionStatusChoices},
        "cmi\\.objectives.\\d+\\.progress_measure": {d: "RW", t: "CMIDecimal", min: 0, max: 1},
        "cmi\\.objectives.\\d+\\.description": {d: "RW", t: "CMIString", l: 250},

        // TODO: These aren't right, but we don't support sequencing so this is as good as it gets.
        "adl.nav.request_valid.choice\\..+": {d: "R", t: "Fixed", v: "true"},
    };

    validateIdentifier(value:string, useLooseValidation:boolean=false):boolean {
        if (useLooseValidation) {
            let res = value.match(/^.+$/);
            if (res && res.length) {
                return true;
            }
        }
        else {
            // TODO Make a stricter validator according to RFC 2396.  URIs can take a very wide character set.
            let res = value.match(/^\S{1,4000}$/);
            if (res && res.length) {
                return true;
            }
        }
        return false;
    }

    validateResult(value:string):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).
        let res = value.match(/^correct$|^incorrect$|^unanticipated$|^neutral$|^([0-9]{0,3})?(\.[0-9]{1,7})?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }

    validateTime(value:string):boolean {
        // See REQ_88.1 in SCORM 2004 4th Edition RTE.
        // This does not validate strictly enough, the timezone designator is too loose, and all the
        // date/time components should be checked for range.
        let res = value.match(/^[0-9]{4}(-[0-9]{2}(-[0-9]{2}(T[0-9]{2}(:[0-9]{2}(:[0-9]{2}(\.[0-9].*)?)?)?)?)?)?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }

    validateTimespan(value:string):boolean {
        // A number that may have a decimal point. If not preceded by minus sign, the
        // number is presumed positive. Examples: (2, 2.2, and -2.2).
        let res = value.match(/^P(?=\w*\d)(?:\d+Y|Y)?(?:\d+M|M)?(?:\d+D|D)?(?:T(?:\d+H|H)?(?:\d+M|M)?(?:\d+(?:.\d{1,2})?S|S)?)?$/);
        if (res && res.length) {
            return true;
        }
        return false;
    }
}
