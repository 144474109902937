import {SSLAConfiguration} from "./config";
import {CourseTypeDetector} from "./detector";
import {popupManually} from "./open";
import {SSLAPlugin} from "./plugin";
import {VERSION} from "./version";


export class SSLA {
    public activePlugin: SSLAPlugin;
    public config: SSLAConfiguration = null;
    public ctd: CourseTypeDetector = null;
    public plugins: { [id: string]: SSLAPlugin } = {};
    // Calling the plugins out by name that we "know" so we have an easy way to hook things onto them.
    public scorm: SSLAPlugin;
    public version: string = VERSION;

    // This makes the popupManually function available publicly to simplify access to popup blockers.
    public popupManually: any = popupManually;

    constructor() {
        this.config = new SSLAConfiguration();
        this.ctd = new CourseTypeDetector();
    }

    public exit(): void {
        switch (this.config.exitAction()) {
            case "none":
                // Intentionally do nothing.
                break;
            case "close":
                try {
                    window.close();
                }
                catch (e) {
                }
                ;
                try {
                    top.window.close();
                } catch (e) {
                }
                ;
                break;
            case "referrer":
                window.location.href = document.referrer;
                break;
            case "url":
                window.location.href = this.config.exitUrl();
                break;
            case "custom":
                if (this.config.exitFn()) {
                    this.config.exitFn()();
                }
                else {
                    console.log("No function specified for exit type custom for exitFn.");
                }
                break;
            default:
                console.log("Exit behavior configuration option invalid:", this.config.exitAction());
        }
    }

    finish(): void {
        if (this.activePlugin) {
            this.activePlugin.finish();
        }
    }

    protected initCourseType(courseType: string): void {
        if (courseType === "scorm") {
            if (!this.plugins["scorm"]) {
                let err = "No SCORM plugin installed.  Please contact administrative support.";
                console.log(err);
                alert(err);
            }
            else {
                this.activePlugin = this.plugins["scorm"];
            }
        }
        else if (courseType === "xapi") {
            if (!this.plugins["xapi"]) {
                let err = "No XAPI plugin installed.  Please contact administrative support.";
                console.log(err);
                alert(err);
            }
            else {
                this.activePlugin = this.plugins["xapi"];
            }
        }
        else if (courseType === "aicc") {
            if (!this.plugins["aicc"]) {
                let err = "No AICC plugin installed.  Please contact administrative support.";
                console.log(err);
                alert(err);
            }
            else {
                this.activePlugin = this.plugins["aicc"];
            }
        }
        else if (courseType === "cmi5") {
            if (!this.plugins["cmi5"]) {
                let err = "No cmi5 plugin installed.  Please contact administrative support.";
                console.log(err);
                alert(err);
            }
            else {
                this.activePlugin = this.plugins["cmi5"];
            }
        }
        else {
            // TODO Make this more inline?
            // TODO Have a configuration option to play courses we don't know the type of?
            // TODO Can we print the missing manifest filename if we only have one plugin type?
            let err = "Could not find any manifest file for supported course types in the specified folder.  Please check the directory containing your course and try again.\n" +
                "Course types supported by this build: " + Object.keys(this.plugins).join(",") + "\n"
            console.log(err);
            alert(err);
        }

        if (this.activePlugin) {
            this.activePlugin.start();
        }
    }

    navigate(type: string): void {
        if (this.activePlugin) {
            if (type === "exit") {
                this.exit();
            }
            else {
                this.activePlugin.navigate(type);
            }
        }
    }

    navigateByIdentifier(id: string) {
        if (this.activePlugin) {
            this.activePlugin.navigateByIdentifier(id);
        }
    }

    registerPlugin(name: string, plugin: SSLAPlugin): void {
        this.plugins[name] = plugin;
        plugin.config = this.config;
        plugin.ctd = this.ctd;
        if (name == "scorm") {
            this.scorm = plugin;
        }
    }

    start(): void {
        if (this.config.learningSpecification() == "") {
            // TODO We should probably polyfill bind in.  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
            this.ctd.identify(this.config, this.plugins, this.initCourseType.bind(this));
        }
        else {
            let plugin = this.plugins[this.config.learningSpecification()];
            if (!plugin) {
                let err = "Plugin mode could not be found: " + this.config.learningSpecification();
                console.log(err);
                alert(err);
                return;
            }
            this.activePlugin = plugin;
            plugin.start();
        }
    }

}
