import {AllScosData} from "./sco";
import {SSLAConfiguration} from "./config";
import {Manifest} from "./manifest";

export function rollupAllScoStatuses(allScos:AllScosData, config:SSLAConfiguration, manifest:Manifest):string {
    if (config.statusRollupAction() === "custom") {
        return config.statusRollupCustomFunction()(allScos, config);
    }

    let rollupStatus:string = "";
    let scoStatus:string = "";
    let k:string;
    if (config.statusRollupAction() == "last") {
        let id:string = manifest.navigableScoPreOrderTraversal[manifest.navigableScoPreOrderTraversal.length - 1].identifier;
        return addScoToStatus(rollupStatus, allScos.scos[id].getStatus());
    }
    if (config.statusRollupAction() == "first") {
        let id:string = manifest.navigableScoPreOrderTraversal[0].identifier;
        return addScoToStatus(rollupStatus, allScos.scos[id].getStatus());
    }
    for (k in allScos.scos) {
        if (allScos.scos.hasOwnProperty(k)) {
            scoStatus = allScos.scos[k].getStatus();
            rollupStatus = addScoToStatus(rollupStatus, scoStatus);
        }
    }

    return rollupStatus;
}

function addScoToStatus(rollupStatus: string, scoStatus: string): string {
    if (scoStatus !== "") {
        switch (rollupStatus) {
            case "":
                rollupStatus = scoStatus;
                break;
            case "not attempted":
                switch (scoStatus) {
                    case "not attempted":
                        break;
                    case "incomplete":
                        rollupStatus = "incomplete";
                        break;
                    case "failed":
                        rollupStatus = "failed";
                        break;
                    case "completed":
                    case "passed":
                        rollupStatus = "incomplete";
                        break;
                }
                break;
            case "incomplete":
                switch (scoStatus) {
                    case "not attempted":
                    case "incomplete":
                        break;
                    case "failed":
                        rollupStatus = scoStatus;
                        break;
                    case "completed":
                    case "passed":
                        break;
                }
                break;
            case "failed":
                // Cannot break out of failed state.
                break;
            case "completed":
                switch (scoStatus) {
                    case "not attempted":
                    case "incomplete":
                        rollupStatus = "incomplete";
                        break;
                    case "failed":
                        rollupStatus = scoStatus;
                        break;
                    case "completed":
                        break;
                    case "passed":
                        // Passed trumps completed.
                        rollupStatus = scoStatus;
                }
                break;
            case "passed":
                switch (scoStatus) {
                    case "not attempted":
                    case "incomplete":
                        rollupStatus = "incomplete";
                        break;
                    case "failed":
                        rollupStatus = scoStatus;
                        break;
                    case "completed":
                        break;
                    case "passed":
                        break;
                }
                break;
        }
    }
    return rollupStatus;
}