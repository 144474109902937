import {SSLA} from "./app";
import {VERSION} from "./version";
import {ScormAllVersionsPlugin} from "./scormallversions/plugin";

export function start() {
    ssla.start();
}

export function navigate(type: string) {
    ssla.navigate(type);
}

export function navigateByIdentifier(id: string) {
    ssla.navigateByIdentifier(id);
}

export function finish() {
    ssla.finish();
}

export let ssla = new SSLA();
let scormPlugin = new ScormAllVersionsPlugin();
ssla.registerPlugin("scorm", scormPlugin);
export let version = VERSION;