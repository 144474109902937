import * as Sco from "./sco";
import { SyntaxError, parse } from './aiccscript';

export class AiccScript {
    protected tree:any = null;

    parse(s:string):boolean {
        try {
            this.tree = parse(s, {});
        } catch (e) {
            console.log("exception", e);
            return false;
        }
        return true;
    }

    protected lookupContext(context:Sco.AllScosData, key:string):string {
        if (context.scos[key]) {
            return context.scos[key].getStatus();
        }
        alert("Context unavailable for SCO: " + key);
        return "";
    }

    evaluate(currentNode:any, context:Sco.AllScosData):boolean {
        if (currentNode == null) {
            currentNode = this.tree;
        }
        if (Object.prototype.toString.call(currentNode) == '[object String]') {
            var status = this.lookupContext(context, currentNode);
            if (status == "passed" || status == "completed") {
                return true;
            }
            return false;
        }
        else if (currentNode.op == "&") {
            return this.evaluate(currentNode.left, context) && this.evaluate(currentNode.right, context);
        }
        else if (currentNode.op == "|") {
            return this.evaluate(currentNode.left, context) || this.evaluate(currentNode.right, context);
        }
        else if (currentNode.op == "=") {
            return this.lookupContext(context, currentNode.left) == currentNode.right;
        }
        else if (currentNode.op == "<>") {
            return this.lookupContext(context, currentNode.left) != currentNode.right;
        }
        else if (currentNode.op == "~") {
            return !this.evaluate(currentNode.left, context);
        }
        else if (currentNode.op == "set") {
            var counter = 0;
            for (let i:number=0; i < currentNode.set.length; i++) {
                if (this.evaluate(currentNode.set[i], context)) {
                    counter += 1;
                }
            }
            if (currentNode.times) {
                return counter >= currentNode.times;
            }
            return counter == currentNode.set.length;
        }
        alert("Unrecognized operator: " + currentNode.op);
        return false;
    }

}
