import * as Path from "./path";
import {UrlVarService} from "./url";


interface IConfigurationIndex {
    [key: string]: any
}

interface IConfiguration {
    aiccAuFilename(): string;
    aiccCourseFilename(): string;
    aiccDescriptorFilename(): string;
    aiccEndpoint(): string;
    aiccSessionId(courseId: string, systemId: string, studentId: string): string;
    aiccStructureFilename(): string;
    autoLaunchFirstSco(): boolean;
    closePopupSingleScoBehavior(): string;
    closePopupMultiScoBehavior(): string;
    closePopupSingleScoCustomFunction(): any;
    closePopupMultiScoCustomFunction(): any;
    cmi5ManifestFilename(): string;
    courseBaseHtmlPath(): string;
    courseDirectory(): string;
    courseId(): string;
    exitAction(): string;
    exitFn(): Function;
    exitUrl(): string;
    getCredit(): string;
    getDataAjaxMethod(): string;
    getDataHeaders(): any;
    getDataUrl(): string;
    getMode(): string;
    layoutUpdater(): Function;
    learningSpecification(): string;
    looseIdentifiers(): boolean;
    masteryScoreMode(): string;
    multiScoView(): string;
    navigationType(): string;
    openContentIn(): string;
    popupMainContentMessageAfterOpen(): string;
    popupMainContentMessageFailed(): string;
    popupWindowParams(): string;
    reenterActivity(): boolean;
    returnToLastActivityOnLaunch(): boolean;
    saveDataOnCommit(): boolean;
    saveDataOnSetValue(): boolean;
    scoreAllowChangeAfterCompleted(): boolean;
    scoreAllowChangeAfterFailed(): boolean;
    scoreAllowChangeAfterPassed(): boolean;
    scoreAllowReduce(): boolean;
    scormManifestFilename(): string;
    scormManifestRawXml(): string;
    scormManifestUseRawXml(): boolean;
    scoreRollupAction(): string;
    scoreRollupCustomFunction(): any;
    scoreRollupIncludeScosWithNoScore(): boolean;
    startIncomplete(): boolean;
    setDataAjaxMethod(): string;
    setDataHeaders(): any;
    setDataUrl(): string;
    singleScoView(): string;
    statusAllowChangeAfterCompleted(): boolean;
    statusAllowChangeAfterFailed(): boolean;
    statusAllowChangeAfterPassed(): boolean;
    statusAllowCompletionOnUnsetFinish(): boolean;
    statusRollupAction(): any;
    statusRollupCustomFunction(): any;
    statusVisualOverride(): string;
    statusVisualOverrideFn(): Function;
    storageAdapter(): any;
    storageAllowCORS(): boolean;
    storageUseWithCredentials(): boolean;
    studentId(): string;
    studentName(): string;
    xapiEndpoint(): string;
    xapiManifestFilename(): string;
    xapiRegistration(): string;
}


class BasicConfiguration implements IConfiguration, IConfigurationIndex {
    aiccAuFilename(): string {
        return "course.au";
    }

    aiccCourseFilename(): string {
        return "course.crs";
    }

    aiccDescriptorFilename(): string {
        return "course.des";
    }

    aiccEndpoint(): string {
        return "";
    }

    aiccSessionId(courseId: string, systemId: string, studentId: string): string {
        return "SESSION_ID_HERE";
    }

    aiccStructureFilename(): string {
        return "course.cst";
    }

    autoLaunchFirstSco(): boolean {
        return true;
    }

    closePopupSingleScoBehavior(): string {
        return "exit";
    }

    closePopupMultiScoBehavior(): string {
        return "";
    }

    closePopupSingleScoCustomFunction(): any {
        return null;
    }

    closePopupMultiScoCustomFunction(): any {
        return null;
    }

    cmi5ManifestFilename(): string {
        return "cmi5.xml";
    }

    courseBaseHtmlPath(): string {
        return "";
    }

    courseDirectory(): string {
        if (UrlVarService.get("courseDirectory").charAt(0) === "/") {
            return UrlVarService.get("courseDirectory");
        }
        return Path.join([this.courseBaseHtmlPath(), UrlVarService.get("courseDirectory")]);
    }

    courseId(): string {
        return UrlVarService.get("courseId");
    }

    /**
     * Valid options:
     * "none": Do nothing.  Useful for external management of unload behavior.
     * "close": Attempt to close the current window.
     * "referrer": Redirect to the referrer URL.
     * "url": Redirect to a URL.
     * "custom": Call a custom exit function, defined by exitFn().
     * @returns {string}
     */
    exitAction(): string {
        return "referrer";
    }

    exitFn(): Function {
        return null;
    }

    exitUrl(): string {
        return "blank_unloading.htm";
    }

    getCredit(): string {
        return UrlVarService.get("credit");
    }

    getDataAjaxMethod(): string {
        return "POST";
    }

    getDataHeaders(): any {
        return {};
    }

    getDataUrl(): string {
        return "/trackAllInOne/get";
    }

    getMode(): string {
        return UrlVarService.get("mode");
    }

    /**
     * Provide a custom function to perform layout updates based on the system state, including information about the
     * course itself (single SCO vs. multi SCO, for example).
     * Expected function signature is single argument that can take an SSLA object.
     * @returns {null}
     */
    layoutUpdater(): Function {
        return null;
    }

    learningSpecification(): string {
        return "";
    }

    looseIdentifiers(): boolean {
        return false;
    }


    /**
     * Valid options:
     * "none" or "": ignore the mastery score altogether
     * "always": apply mastery score on finish if it exists, in all cases where a cmi.core.score.raw is set to an actual value. (edited)
     * "after_completion" (default): apply mastery score on finish only if cmi.core.lesson_status is “completed” (or the equivalent version of this in SCORM 2004)
     */
    masteryScoreMode(): string {
        return "after_completion";
    }

    /**
     * Valid options:
     *   "": Leave it however it's currently displaying.
     *   "HIDE_ALL": Hide all non-course view components.
     *   "HIDE_NAV_BUTTONS": Hide the navigation menu.
     *   "HIDE_TREE": Hide the navigation tree.
     * @returns {string}
     */
    multiScoView(): string {
        return "";
    }

    navigationType(): string {
        return "";
    }

    openContentIn(): string {
        return "inline";
    }

    popupMainContentMessageAfterOpen(): string {
        return 'Your course has been opened in a popup.  When you have completed your course, please make another selection from the tree, press exit or close this window.';
    }

    popupMainContentMessageFailed(): string {
        return 'Your course window did not pop open correctly.<br>  <a onclick="parent.ssla.ssla.popupManually()" href="#">Click here to open the course.</a>';
    }

    popupWindowParams(): string {
        return "status=1,toolbar=1,scrollbars=1,resizable=1,alwaysRaised=1";
    }

    /**
     * Allow re-entrance to an activity after it has been initialized and before it has been terminated.
     * @returns {boolean}
     */
    reenterActivity(): boolean {
        return true;
    }

    returnToLastActivityOnLaunch(): boolean {
        return false;
    }

    saveDataOnCommit(): boolean {
        return true;
    }

    saveDataOnSetValue(): boolean {
        return false;
    }

    scoreAllowChangeAfterCompleted(): boolean {
        return true;
    }

    scoreAllowChangeAfterFailed(): boolean {
        return true;
    }

    scoreAllowChangeAfterPassed(): boolean {
        return true;
    }

    scoreAllowReduce(): boolean {
        return true;
    }

    scormManifestFilename(): string {
        return "imsmanifest.xml";
    }

    scormManifestRawXml(): string {
        return "";
    }

    /**
     * If true, use scormManifestRawXml for the manifest XML body.
     * If false, load the XML from the file referenced by scormManifestFilename.
     * @returns {boolean}
     */
    scormManifestUseRawXml(): boolean {
        return false;
    }

    scoreRollupAction(): string {
        return "";
    }

    scoreRollupCustomFunction(): any {
        return null;
    }

    scoreRollupIncludeScosWithNoScore(): boolean {
        return false;
    }

    setDataAjaxMethod(): string {
        return "POST";
    }

    setDataHeaders(): any {
        return {};
    }

    setDataUrl(): string {
        return "/trackAllInOne/set";
    }

    /**
     * Valid options:
     *   "": Leave it however it's currently displaying.
     *   "HIDE_ALL": Hide all non-course view components.
     *   "HIDE_NAV_BUTTONS": Hide the navigation menu.
     *   "HIDE_TREE": Hide the navigation tree.
     * @returns {string}
     */
    singleScoView(): string {
        return "";
    }

    /**
     * If true, default a lesson to "incomplete" instead of "not attempted".
     * @returns {boolean}
     */
    startIncomplete(): boolean {
        return false;
    }

    statusAllowChangeAfterCompleted(): boolean {
        return true;
    }

    statusAllowChangeAfterFailed(): boolean {
        return true;
    }

    statusAllowChangeAfterPassed(): boolean {
        return true;
    }

    statusAllowCompletionOnUnsetFinish(): boolean {
        return true;
    }

    statusRollupAction(): string {
        return "";
    }

    statusRollupCustomFunction(): any {
        return null;
    }

    /**
     * Valid options:
     * "default": Use the built in defaults.
     * "custom": Call a custom function, defined by statusVisualOverrideFn().
     * @returns {string}
     */
    statusVisualOverride(): string {
        return "default";
    }

    /**
     * Expects a string array of current status values
     * @returns {null}
     */
    statusVisualOverrideFn(): Function {
        return null;
    }

    storageAdapter(): any {
        return null;
    }

    storageAllowCORS(): boolean {
        return true;
    }

    storageUseWithCredentials(): boolean {
        return true;
    }

    studentId(): string {
        return UrlVarService.get("studentId");
    }

    studentName(): string {
        return UrlVarService.get("studentName");
    }

    xapiEndpoint(): string {
        return "";
    }

    xapiManifestFilename(): string {
        return "tincan.xml";
    }

    /**
     * If set, adds a registration parameter to the XAPI launch URL with whatever value is provided in the config.
     * @returns {string}
     */
    xapiRegistration(): string {
        return "";
    }
}


type ConfigCallable = (() => boolean) | (() => string);
interface SSLAWindow extends Window {
    sslaConfig: { [key: string]: ConfigCallable | string | boolean };
}
declare var window: SSLAWindow;


export class SSLAConfiguration implements IConfiguration {
    protected internalConfig: IConfigurationIndex;

    constructor() {
        this.internalConfig = new BasicConfiguration();
    }

    protected isFunction(obj: any): obj is ConfigCallable {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }

    protected fromExt(fn: string, args: any[]=null): any {
        if (typeof window.sslaConfig !== "undefined" && typeof window.sslaConfig[fn] !== "undefined") {
            // Is it a function or a value?
            if (this.isFunction(window.sslaConfig[fn])) {
                // This is necessary for IE7 as part of the bind polyfill, do not delete.
                if (!args) {
                    args = [];
                }
                return (<any>window.sslaConfig)[fn](...args);
            }
            return window.sslaConfig[fn];
        }
        // This is necessary for IE7 as part of the bind polyfill, do not delete.
        if (!args) {
            args = [];
        }
        return this.internalConfig[fn](...args);
    }

    aiccAuFilename(): string {
        return this.fromExt("aiccAuFilename");
    }

    aiccCourseFilename(): string {
        return this.fromExt("aiccCourseFilename");
    }

    aiccDescriptorFilename(): string {
        return this.fromExt("aiccDescriptorFilename");
    }

    aiccEndpoint(): string {
        return this.fromExt("aiccEndpoint");
    }

    aiccSessionId(courseId: string, systemId: string, studentId: string): string {
        return this.fromExt("aiccSessionId", [courseId, systemId, studentId]);
    }

    aiccStructureFilename(): string {
        return this.fromExt("aiccStructureFilename");
    }

    autoLaunchFirstSco(): boolean {
        return this.fromExt("autoLaunchFirstSco");
    }

    closePopupSingleScoBehavior(): string {
        return this.fromExt("closePopupSingleScoBehavior");
    }

    closePopupMultiScoBehavior(): string {
        return this.fromExt("closePopupMultiScoBehavior");
    }

    closePopupSingleScoCustomFunction(): any {
        return this.fromExt("closePopupSingleScoCustomFunction");
    }

    closePopupMultiScoCustomFunction(): any {
        return this.fromExt("closePopupMultiScoCustomFunction");
    }

    cmi5ManifestFilename(): string {
        return this.fromExt("cmi5ManifestFilename");
    }

    courseBaseHtmlPath(): string {
        return this.fromExt("courseBaseHtmlPath");
    }

    courseDirectory(): string {
        return this.fromExt("courseDirectory");
    }

    courseId(): string {
        return this.fromExt("courseId");
    }

    exitAction(): string {
        return this.fromExt("exitAction");
    }

    exitFn(): Function {
        return this.fromExt("exitFn");
    }

    exitUrl(): string {
        return this.fromExt("exitUrl");
    }

    getCredit(): string {
        return this.fromExt("getCredit");
    }

    getDataAjaxMethod(): string {
        return this.fromExt("getDataAjaxMethod");
    }

    getDataHeaders(): any {
        return this.fromExt("getDataHeaders");
    }

    getDataUrl(): string {
        return this.fromExt("getDataUrl");
    }

    getMode(): string {
        return this.fromExt("getMode");
    }

    layoutUpdater(): Function {
        return this.fromExt("layoutUpdater");
    }

    learningSpecification(): string {
        return this.fromExt("learningSpecification");
    }

    looseIdentifiers(): boolean {
        return this.fromExt("looseIdentifiers");
    }

    masteryScoreMode(): string {
        return this.fromExt("masteryScoreMode");
    }

    multiScoView(): string {
        return this.fromExt("multiScoView");
    }

    navigationType(): string {
        return this.fromExt("navigationType");
    }

    openContentIn(): string {
        return this.fromExt("openContentIn");
    }

    popupMainContentMessageAfterOpen(): string {
        return this.fromExt("popupMainContentMessageAfterOpen");
    }

    popupMainContentMessageFailed(): string {
        return this.fromExt("popupMainContentMessageFailed");
    }

    popupWindowParams(): string {
        return this.fromExt("popupWindowParams");
    }

    reenterActivity(): boolean {
        return this.fromExt("reenterActivity");
    }

    returnToLastActivityOnLaunch(): boolean {
        return this.fromExt("returnToLastActivityOnLaunch");
    }

    saveDataOnCommit(): boolean {
        return this.fromExt("saveDataOnCommit");
    }

    saveDataOnSetValue(): boolean {
        return this.fromExt("saveDataOnSetValue");
    }

    scoreAllowChangeAfterCompleted(): boolean {
        return this.fromExt("scoreAllowChangeAfterCompleted");
    }

    scoreAllowChangeAfterFailed(): boolean {
        return this.fromExt("scoreAllowChangeAfterFailed");
    }

    scoreAllowChangeAfterPassed(): boolean {
        return this.fromExt("scoreAllowChangeAfterPassed");
    }

    scoreAllowReduce(): boolean {
        return this.fromExt("scoreAllowReduce");
    }

    scormManifestFilename(): string {
        return this.fromExt("scormManifestFilename");
    }

    scormManifestRawXml(): string {
        return this.fromExt("scormManifestRawXml");
    }

    scormManifestUseRawXml(): boolean {
        return this.fromExt("scormManifestUseRawXml");
    }

    scoreRollupAction(): string {
        return this.fromExt("scoreRollupAction");
    }

    scoreRollupCustomFunction(): any {
        return this.fromExt("scoreRollupCustomFunction");
    }

    scoreRollupIncludeScosWithNoScore(): boolean {
        return this.fromExt("scoreRollupIncludeScosWithNoScore");
    }

    setDataAjaxMethod(): string {
        return this.fromExt("setDataAjaxMethod");
    }

    setDataHeaders(): any {
        return this.fromExt("setDataHeaders");
    }

    setDataUrl(): string {
        return this.fromExt("setDataUrl");
    }

    singleScoView(): string {
        return this.fromExt("singleScoView");
    }

    startIncomplete(): boolean {
        return this.fromExt("startIncomplete");
    }

    statusAllowChangeAfterCompleted(): boolean {
        return this.fromExt("statusAllowChangeAfterCompleted");
    }

    statusAllowChangeAfterFailed(): boolean {
        return this.fromExt("statusAllowChangeAfterFailed");
    }

    statusAllowChangeAfterPassed(): boolean {
        return this.fromExt("statusAllowChangeAfterPassed");
    }

    statusAllowCompletionOnUnsetFinish(): boolean {
        return this.fromExt("statusAllowCompletionOnUnsetFinish");
    }

    statusRollupAction(): string {
        return this.fromExt("statusRollupAction");
    }

    statusRollupCustomFunction(): any {
        return this.fromExt("statusRollupCustomFunction");
    }

    statusVisualOverride(): string {
        return this.fromExt("statusVisualOverride");
    }

    statusVisualOverrideFn(): Function {
        return this.fromExt("statusVisualOverrideFn");
    }

    storageAdapter(): any {
        return this.fromExt("storageAdapter");
    }

    storageAllowCORS(): boolean {
        return this.fromExt("storageAllowCORS");
    }

    storageUseWithCredentials(): boolean {
        return this.fromExt("storageUseWithCredentials");
    }

    studentId(): string {
        return this.fromExt("studentId");
    }

    studentName(): string {
        return this.fromExt("studentName");
    }

    xapiEndpoint(): string {
        return this.fromExt("xapiEndpoint");
    }

    xapiManifestFilename(): string {
        return this.fromExt("xapiManifestFilename");
    }

    xapiRegistration(): string {
        return this.fromExt("xapiRegistration");
    }
}
