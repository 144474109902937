import {AiccScript} from "../aiccscript_parser";
import {Manifest, ScoNavItem} from "../manifest";
import {property} from "../xml";


export class ScoNavItem12 extends ScoNavItem {
    aiccScriptParser: AiccScript = null;
}


export class Manifest12 extends Manifest {
    _buildScoTree(itemNode: any): ScoNavItem {
        let currentItem: ScoNavItem12 = new ScoNavItem12(),
            i: number;
        currentItem.identifier = property(itemNode, "identifier", false, true);
        if (currentItem.identifier) {
            this.scosByIdentifiers[currentItem.identifier] = currentItem;
        }
        currentItem.identifierRef = property(itemNode, "identifierref", false, true);
        if (currentItem.identifierRef) {
            this.scosByIdentifierRefs[currentItem.identifierRef] = currentItem;
        }
        currentItem.title = property(itemNode, "title", false, true);
        currentItem.isVisible = property(itemNode, "isvisible", false, true) !== "false";
        currentItem.parameters = property(itemNode, "parameters", false, true);
        currentItem.dataFromLms = property(itemNode, "adlcp:datafromlms", false, true);
        currentItem.masteryScore = property(itemNode, "adlcp:masteryscore", false, true);
        currentItem.timeLimitAction = property(itemNode, "adlcp:timelimitaction", false, true);
        currentItem.maxTimeAllowed = property(itemNode, "adlcp:maxtimeallowed", false, true);
        currentItem.prerequisites = property(itemNode, "adlcp:prerequisites", false, true);

        if (itemNode.kids["adlcp:prerequisites"] &&
            itemNode.kids["adlcp:prerequisites"].length > 0 &&
            itemNode.kids["adlcp:prerequisites"][0].attrs["type"] &&
            itemNode.kids["adlcp:prerequisites"][0].attrs["type"] === "aicc_script"
        ) {
            // If the prerequisites type is "aicc_script", additionally process it.
            let asp: AiccScript = new AiccScript();
            let parsed = asp.parse(currentItem.prerequisites);
            if (parsed) {
                currentItem.aiccScriptParser = asp;
            }
        }

        this.activityOrdered.push(currentItem);
        if (this.isSco(currentItem)) {
            this.scoPreOrderTraversal.push(currentItem);
            // Visibility is not inherited from parent nodes.
            // https://www.imsglobal.org/content/packaging/cpv1p1p3/imscp_bestv1p1p3.html#1518816
            if (currentItem.isVisible) {
                this.navigableScoPreOrderTraversal.push(currentItem);
            }
            if (!this.firstScoId) {
                this.firstScoId = currentItem.identifier;
            }
        }

        if (itemNode.kids["item"]) {
            for (i = 0; i < itemNode.kids["item"].length; i++) {
                let c: ScoNavItem = this._buildScoTree(itemNode.kids["item"][i]);
                c.parent = currentItem;
                currentItem.children.push(c);
            }
        }
        else {
            currentItem.isLeaf = true;
        }
        return currentItem;
    }

    protected clearManifestAiccScriptParsers() {
        let scos: ScoNavItem12[] = this.navigableScoPreOrderTraversal as ScoNavItem12[];
        for (let i: number = 0; i < scos.length; i++) {
            let scoNav: ScoNavItem12 = scos[i];
            scoNav.aiccScriptParser = null;
        }
    }

    setupNavigationType(navigationType: string): void {
        let scos: ScoNavItem12[] = this.navigableScoPreOrderTraversal as ScoNavItem12[];
        if (navigationType == "") {
            // Do nothing, it's valid and doesn't override the manifest's behavior.
        }
        else {
            this.clearManifestAiccScriptParsers();
            if (navigationType == "lockstep") {
                for (let i: number = 1; i < scos.length; i++) {
                    let scoNav: ScoNavItem12 = scos[i];
                    let asp: AiccScript = new AiccScript();
                    asp.parse(scos[i - 1].identifier);
                    scoNav.aiccScriptParser = asp;
                }
            }
            else if (navigationType == "examaftercontent") {
                let scoNav: ScoNavItem12 = scos[scos.length - 1];
                let idents: string[] = [];
                for (let i: number = 0; i < scos.length - 1; i++) {
                    idents.push(scos[i].identifier);
                }
                let asp: AiccScript = new AiccScript();
                asp.parse(idents.join("&"));
                scoNav.aiccScriptParser = asp;
            }
            else if (navigationType == "onlyonce") {
                for (let i: number = 0; i < scos.length; i++) {
                    let scoNav: ScoNavItem12 = scos[i];
                    let asp: AiccScript = new AiccScript();
                    asp.parse(scoNav.identifier + "=n");
                    scoNav.aiccScriptParser = asp;
                }
            }
            else {
                alert("Unrecognized nagivationType: " + navigationType);
            }
        }
    }

}
